import { default as _91_46_46_46url_93gr5LKyURnYMeta } from "/app/pages/partners/[...city]/[...url].vue?macro=true";
import { default as internet_45mobile0cZSVhhkzoMeta } from "/app/pages/partners/[...city]/internet-mobile.vue?macro=true";
import { default as internet_45tv_45mobileLbMwdqlZ6gMeta } from "/app/pages/partners/[...city]/internet-tv-mobile.vue?macro=true";
import { default as internet_45tvpdJzWKgLHGMeta } from "/app/pages/partners/[...city]/internet-tv.vue?macro=true";
import { default as internetIgZUYc1f15Meta } from "/app/pages/partners/[...city]/internet.vue?macro=true";
import { default as indexdjcndUiAbNMeta } from "/app/pages/partners/[...city]/not-available/index.vue?macro=true";
import { default as quiz_45completeeInG37jFv0Meta } from "/app/pages/partners/[...city]/quiz-complete.vue?macro=true";
import { default as indexaUWqrjchRJMeta } from "/app/pages/partners/[...city]/quiz/index.vue?macro=true";
import { default as _91id_93SuuYDRlQ8PMeta } from "/app/pages/partners/[...city]/tariffs/[id].vue?macro=true";
import { default as indexPLF0oLNWuDMeta } from "/app/pages/partners/checkout/index.vue?macro=true";
import { default as index1dGT6uxKkAMeta } from "/app/pages/partners/complete/index.vue?macro=true";
import { default as indexUONPfk4cmoMeta } from "/app/pages/partners/contacts/index.vue?macro=true";
import { default as indexouM6qhBI4iMeta } from "/app/pages/partners/health/index.vue?macro=true";
import { default as indexLG3Wtz8U6fMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as indexVMNxN3O0g3Meta } from "/app/pages/partners/legal/[...query]/index.vue?macro=true";
import { default as indexLj5dzkUtd3Meta } from "/app/pages/partners/legal/index.vue?macro=true";
import { default as indexXq6v482WkBMeta } from "/app/pages/partners/locations/index.vue?macro=true";
import { default as thanks_45for_45choiceqvf5LBi20cMeta } from "/app/pages/partners/thanks-for-choice.vue?macro=true";
export default [
  {
    name: "city-url",
    path: "/:city(.*)*/:url(.*)*",
    component: () => import("/app/pages/partners/[...city]/[...url].vue")
  },
  {
    name: "city-internet-mobile",
    path: "/:city(.*)*/internet-mobile",
    component: () => import("/app/pages/partners/[...city]/internet-mobile.vue")
  },
  {
    name: "city-internet-tv-mobile",
    path: "/:city(.*)*/internet-tv-mobile",
    component: () => import("/app/pages/partners/[...city]/internet-tv-mobile.vue")
  },
  {
    name: "city-internet-tv",
    path: "/:city(.*)*/internet-tv",
    component: () => import("/app/pages/partners/[...city]/internet-tv.vue")
  },
  {
    name: "city-internet",
    path: "/:city(.*)*/internet",
    component: () => import("/app/pages/partners/[...city]/internet.vue")
  },
  {
    name: "city-not-available",
    path: "/:city(.*)*/not-available",
    component: () => import("/app/pages/partners/[...city]/not-available/index.vue")
  },
  {
    name: "city-quiz-complete",
    path: "/:city(.*)*/quiz-complete",
    meta: quiz_45completeeInG37jFv0Meta || {},
    component: () => import("/app/pages/partners/[...city]/quiz-complete.vue")
  },
  {
    name: "city-quiz",
    path: "/:city(.*)*/quiz",
    meta: indexaUWqrjchRJMeta || {},
    component: () => import("/app/pages/partners/[...city]/quiz/index.vue")
  },
  {
    name: "city-tariffs-id",
    path: "/:city(.*)*/tariffs/:id()",
    component: () => import("/app/pages/partners/[...city]/tariffs/[id].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexPLF0oLNWuDMeta || {},
    component: () => import("/app/pages/partners/checkout/index.vue")
  },
  {
    name: "complete",
    path: "/complete",
    meta: index1dGT6uxKkAMeta || {},
    component: () => import("/app/pages/partners/complete/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/partners/contacts/index.vue")
  },
  {
    name: "health",
    path: "/health",
    meta: indexouM6qhBI4iMeta || {},
    component: () => import("/app/pages/partners/health/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/partners/index.vue")
  },
  {
    name: "legal-query",
    path: "/legal/:query(.*)*",
    component: () => import("/app/pages/partners/legal/[...query]/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/app/pages/partners/legal/index.vue")
  },
  {
    name: "locations",
    path: "/locations",
    component: () => import("/app/pages/partners/locations/index.vue")
  },
  {
    name: "thanks-for-choice",
    path: "/thanks-for-choice",
    meta: thanks_45for_45choiceqvf5LBi20cMeta || {},
    component: () => import("/app/pages/partners/thanks-for-choice.vue")
  }
]