<script setup lang="ts">

</script>

<template>
  <header class="h-header white--bg">
    <div class="container">
      <NuxtLink
        class="logo"
        to="/"
      >
        <img
          src="~/assets/images/logo.svg"
          alt="logo"
        >
        Домашний интернет
      </NuxtLink>
    </div>
  </header>
</template>

<style scoped lang="scss">
.h-header {
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
