import { useMainStore } from '~/store/main/main.store'

export default defineNuxtPlugin({
  name: 'getCallCenterStatus',
  parallel: true,
  async setup() {
    const mainStore = useMainStore()
    const provider = computed(() => mainStore.getDomainConfig)
    if (provider.value) {
      mainStore.getCallCenterStatus()
      console.log('getCallCenterStatus')
    }
  },
})
