import axios from 'axios'
// import { getToken, Token } from './token'

export const mainInstance = axios.create()
export const baseOptions = {
  headers: {
    common: {}
  }
}
// export function setAuthHeader (token: Token) {
//   mainInstance.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`
// }

export function clearAuthHeader () {
  mainInstance.defaults.headers.common.Authorization = undefined
}

// const token = getToken()

// if (token) {
//   setAuthHeader(token)
// }
