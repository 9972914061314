export enum SizeEnum {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum ColorEnum {
  DEFAULT = "default",
  CUSTOM = "custom",
  GRADIENT = "gradient",
  WHITE = "white",
  PRIMARY = "primary",
  "PRIMARY-LIGHT" = "primary-light",
  ADDITIONAL = "additional",
  "ADDITIONAL-LIGHT" = "additional-light",
  "WHITE-PRIMARY" = "white-primary",
  "SEMI-PRIMARY" = "semi-primary",
  "WHITE-ADDITIONAL" = "white-additional",
  "WHITE-SECONDARY" = "white-secondary",
  "WHITE-GRAY" = "white-gray",
  ERROR = "error",
}

export const sizeValidator = (v: string) => v.toUpperCase() in SizeEnum;
