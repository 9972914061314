import { defineStore } from 'pinia'
import type { AddressChain } from '~/api'
import { useCities } from '~/store/cites/cities.store'

export const useTariffs = defineStore('tariffs', {
  state: () => ({
    recentSearch: [] as any[],
    notifArr: [],
    selectedRouter: [],
    selectedTvBox: [],
    quizTariffsInput: {},
    orderedTariff: undefined as string | undefined,
    orderId: undefined as number | undefined,
    passThroughProviderId: null as string | null,
    interactionId: undefined as number | undefined,
    clientPhone: undefined as string | undefined,
    clientName: undefined as string | undefined,
    payments: undefined as string[] | undefined,
    timeToCall: undefined as string | undefined,
    linkList: [
      {
        name: 'Интернет',
        url: 'internet',
        id: 1,
        setsId: ['1'],
      },
      {
        name: 'Интернет + ТВ',
        url: 'internet-tv',
        id: 11,
        setsId: ['1', '2'],
      },
      {
        name: 'Интернет + Моб. связь',
        url: 'internet-mobile',
        id: 14,
        setsId: ['1', '4'],
      },
      {
        name: 'Интернет + ТВ + Моб. связь',
        url: 'internet-tv-mobile',
        id: 13,
        setsId: ['1', '2', '4'],
      },
    ],
    smartFilters: [
      {
        smart_filter: {
          slug: 'internet-tv',
          name: 'Интернет + ТВ',
          category: 'SERVICE',
          filters: {
            service: [1, 2],
            for_mono_tv: false,
          },
          description: '',
        },
        service_group: {
          id: 11,
          service_types: [
            {
              id: 1,
              name: 'Интернет',
              slug: 'internet',
            },
            {
              id: 2,
              name: 'ТВ',
              slug: 'tv',
            },
          ],
          name: 'Интернет + ТВ',
          slug: 'internet-tv',
          on_index_page: true,
          on_tariffs_page: true,
          index_page: false,
        },
        index_page: false,
        on_index_page: true,
        on_tariffs_page: true,
        in_header: '',
        in_tabs: '',
        in_footer: '',
        display_row: 1,
      },
      {
        smart_filter: {
          slug: 'internet',
          name: 'Только интернет',
          category: 'SERVICE',
          filters: {
            service: [1],
            for_mono_tv: false,
          },
          description: 'Только интернет и ничего лишнего',
        },
        service_group: {
          id: 1,
          service_types: [
            {
              id: 1,
              name: 'Интернет',
              slug: 'internet',
            },
          ],
          name: 'Только интернет',
          slug: 'internet',
          on_index_page: true,
          on_tariffs_page: true,
          index_page: true,
        },
        index_page: true,
        on_index_page: true,
        on_tariffs_page: true,
        in_header: '',
        in_tabs: '',
        in_footer: '',
        display_row: 1,
      },
      {
        smart_filter: {
          slug: 'internet-tv-mobile',
          name: 'Интернет + ТВ + Моб. связь',
          category: 'SERVICE',
          filters: {
            service: [1, 2, 4],
            for_mono_tv: false,
          },
          description: '',
        },
        service_group: {
          id: 13,
          service_types: [
            {
              id: 1,
              name: 'Интернет',
              slug: 'internet',
            },
            {
              id: 2,
              name: 'ТВ',
              slug: 'tv',
            },
            {
              id: 4,
              name: 'Моб. связь',
              slug: 'mobile',
            },
          ],
          name: 'Интернет + ТВ + Моб. связь',
          slug: 'internet-tv-mobile',
          on_index_page: true,
          on_tariffs_page: true,
          index_page: false,
        },
        index_page: false,
        on_index_page: true,
        on_tariffs_page: true,
        in_header: '',
        in_tabs: '',
        in_footer: '',
        display_row: 1,
      },
      {
        smart_filter: {
          slug: 'subscriptions',
          name: 'С видеосервисами',
          category: 'SPECIAL',
          filters: {
            with_any_subscription: true,
            for_mono_tv: true,
          },
          description: '',
        },
        index_page: false,
        on_index_page: true,
        on_tariffs_page: true,
        in_header: '',
        in_tabs: '',
        in_footer: '',
        display_row: 1,
      },
    ],
    compareList: {},
  }),
  getters: {
    getPassThroughProviderId: state => state.passThroughProviderId,
    getRecentSearch: state => state.recentSearch,
    getNotifArr: state => state.notifArr,
    getSelectedRouter: state => state.selectedRouter,
    getSelectedTvBox: state => state.selectedTvBox,
    getQuizTariffsInput: state => state.quizTariffsInput,
    getOrderId: state => state.orderId,
    getInteractionId: state => state.interactionId,
    getOrderedTariff: state => state.orderedTariff,
    getClientPhone: state => state.clientPhone,
    getClientName: state => state.clientName,
    getPayments: state => state.payments,
    getTimeToCall: state => state.timeToCall,
    getSmartFilters: state => state.smartFilters,
    getLinkList: state => state.linkList,
    getCompareList: state => state.compareList,
  },
  actions: {
    setCompare(id: string | number) {
      const cityFias = useCities().getCity.fias_id
      const compare = useCookie('compare') as any
      compare.value = compare.value || {}
      const arr
        = compare.value && compare.value[cityFias] ? compare.value[cityFias] : []

      if (arr && !arr.includes(id)) {
        arr.push(id)
      }
      else if (arr && arr.includes(id)) {
        const index = arr.indexOf(id)
        arr.splice(index, 1)
      }
      compare.value[cityFias] = arr
      const compareCoockie = useCookie('compare')
      compareCoockie.value = compare.value

      this.compareList = compare.value
    },
  },
})
