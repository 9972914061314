import mitt from 'mitt'

export default defineNuxtPlugin({
  name: 'eventBus',
  parallel: true,
  setup() {
    const emitter = mitt()

    return {
      provide: {
        event: emitter.emit, // Will emit an event
        listen: emitter.on, // Will register a listener for an event
        unlisten: emitter.off, // Will unregister a listener for an event
      },
    }
  },
})
