import { useCities } from '~/store/cites/cities.store'
import { getAddresLocation } from '~/composible/getAddresLoc'

export default defineNuxtPlugin({
  name: 'getCity',
  parallel: true,
  async setup() {
    const cityStore = useCities()
    const { getAddresLoc } = getAddresLocation()
    if (cityStore.softNoindex === null) {
      getAddresLoc({})
    }
  },
})
