import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'

export default defineNuxtPlugin({
  name: 'getCity',
  parallel: true,
  async setup() {
    const $route = useRoute()
    const cityStore = useCities()
    const mainStore = useMainStore()
    const needGetCity = computed(() => cityStore.getNeedGetCity)
    const currentCity = computed(() => cityStore.getCity)
    const requestParams = computed(() => cityStore.getRequestParams)
    const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
    const domain = computed(() => mainStore.getDomain)

    const newHeaders = {}
    const exclude = ['cookie', 'X-Utm-State', 'X-Forwarded-Host', 'ip']
    Object.entries(mainStore.commonHeaders).filter(el => !exclude.includes(el[0])).forEach(el => newHeaders[el[0]] = el[1])
    mainStore.commonHeaders = newHeaders

    const checkCity = async () => {
      const queries = new URLSearchParams($route.fullPath.split('?')[1])
      for (const query of queries.entries()) {
        if (query[0] === 'yandex_geo')
          requestParams.value.yandex_geo = +query[1] || undefined
        if (query[0] === 'google_geo')
          requestParams.value.google_geo = +query[1] || undefined
      }
      requestParams.value.client_ip = undefined
      let responce
      try {
        responce = await $fetch(`https://${domain.value}/api/locations/address-objects/chain-by-geo/`, {
          method: 'GET',
          headers: {
            ...getCommonHeaders.value,
          },
          params: {
            with_available_smart_filters: false,
            morph: true,
            site_id: import.meta.env.VITE_SITE_ID,
            ...requestParams.value,
          },
        })
      }
      catch (e) { console.error(e) }

      const { city } = cityStore.getAddressObjectsFromResponse(responce)
      cityStore.$patch({
        addressAncestors: responce,
      })
    }

    if (needGetCity.value || !currentCity.value?.fias_id) {
      await checkCity()
    }
  },
})
