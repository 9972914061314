<template>
  <div
    class="leave-review"
    data-test="side-leave-review-form"
  >
    <img
      class="leave-review__logo"
      loading="lazy"
      src="@/assets/images/logo_black.svg"
      alt="logo"
    >
    <p class="leave-review__title headline-6">
      Больше отзывов - точнее рейтинг
    </p>
    <p class="leave-review__subtitle subhead-3">
      Рейтинг рассчитывается на основе оценок в отзывах наших пользователей о провайдерах
    </p>

    <d-btn
      color="primary"
      class="leave-review__btn"
      @click="leaveReview"
    >
      <!--
      @click.stop="leaveReview" -->
      Оставить отзыв
    </d-btn>
  </div>
</template>

<script lang="ts">
import { useCities } from '~/store/cites/cities.store'

export default defineComponent({
  props: {
    fiasID: {
      type: String,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const $router = useRouter()
    const cityStore = useCities()
    const currentCity = computed(() => cityStore.getCity)

    const leaveReview = () => {
      $router.push(`/${currentCity.value?.url}/reviews/add/`)
    }

    return { leaveReview }
  },
})
</script>

<style scoped lang="scss">
.leave-review {
  border-radius: 16px;
  border: 1px solid color(gray-darker);
  background: color(blue);
  padding: 16px;
  margin-bottom: 64px;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  img {
    width: 100px;
    margin-bottom: 24px;
  }
  &__title {
    margin-bottom: 8px;
  }
  &__subtitle {
    margin-bottom: 24px;
  }
  &__btn {
    font-size: fontSize(paragraph);
    padding: 10px;
    height: 44px;
    width: 100%;
    max-width: 310px;
  }
}
</style>
