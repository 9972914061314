import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup() {
    if (!import.meta.dev) {
      const router = useRouter()
      Sentry.init({
        // app: [vueApp],
        dsn: `https://f126cc0124ce4da9bce42728f2ce23e0@${import.meta.env.VITE_SENTRY_DOMAIN ? import.meta.env.VITE_SENTRY_DOMAIN : 'o452324.ingest.sentry.io'}/6462344`,
        environment: import.meta.env.VITE_BRANCH || 'development',
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        // replaysSessionSampleRate: 0.01,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        // replaysOnErrorSampleRate: 0.1,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          // Sentry.replayIntegration({
          //   maskAllText: false,
          //   blockAllMedia: false,
          //   maskAllInputs: false,
          // }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.03,
        tracePropagationTargets: ['localhost', /^https:\/\/dominternet\.ru\/api/],
        beforeSend(event, hint) {
          // Check if it is an exception, and if so, log it.
          if (event.exception?.values?.[0]?.value === 'Cannot read properties of undefined (reading \'default\')') {
            reloadNuxtApp()
          }
          if (event.exception) {
            console.error(
              `[Exeption handled by Sentry]: (${hint.originalException})`,
              { event, hint },
            )
            const listExceptions = [
              'Cannot read properties of undefined (reading \'focus\')',
              'InvalidStateError: Failed to get ServiceWorkerRegistration objects: The document is in an invalid state.',
              'Cannot read properties of null (reading \'offsetWidth\')',
              'null is not an object (evaluating \'e.domainName\')',
            ]
            if (
              event.exception?.values?.[0]?.value
              && listExceptions.includes(event.exception.values[0].value)
            ) {
              console.log('skip error')
              return
            }
          }
          return event
        },
      })
      return {
        provide: {
          sentryCaptureMessage: Sentry.captureMessage,
        },
      }
    }
    else {
      const captureMessage = (err) => {
        console.log(err)
      }

      return {
        provide: {
          sentryCaptureMessage: captureMessage,
        },
      }
    }
  },
})
