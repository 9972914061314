/* tslint:disable */
/* eslint-disable */
/**
 * DominternetAPI
 * Для тестирования:<br>     Москва: <b>0c5b2444-70a0-4932-980c-b4dc0d3f02b5</b><br>     Санкт-Петербург: <b>c2deb16a-0330-4f05-821f-1d09c93331e6</b><br>     Ростов-на-Дону: <b>c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5</b><br>     Оренбург: <b>dce97bff-deb2-4fd9-9aec-4f4327bbf89b</b><br>     Москва, ш Волоколамское, 7: <b>1089944</b><br>     Оренбург, пр-кт Гагарина, 29/2: <b>889861</b><br> 
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AddressCertainty = {
    CERTAIN: 'CERTAIN',
    MAYBE: 'MAYBE',
    FALLBACK: 'FALLBACK'
} as const;

export type AddressCertainty = typeof AddressCertainty[keyof typeof AddressCertainty];


/**
 * 
 * @export
 * @interface AddressChain
 */
export interface AddressChain {
    /**
     * 
     * @type {Array<BaseAddressObject>}
     * @memberof AddressChain
     */
    'address_objects': Array<BaseAddressObject>;
    /**
     * 
     * @type {House}
     * @memberof AddressChain
     */
    'house'?: House | null;
    /**
     * 
     * @type {number}
     * @memberof AddressChain
     */
    'redirect_status_code'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddressChain
     */
    'site_city_fias_id'?: string | null;
    /**
     * 
     * @type {AddressCertainty}
     * @memberof AddressChain
     */
    'certainty'?: AddressCertainty | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddressChain
     */
    'indexed_topologies'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface AddressChildrenQuery
 */
export interface AddressChildrenQuery {
    /**
     * 
     * @type {string}
     * @memberof AddressChildrenQuery
     */
    'level'?: AddressChildrenQueryLevelEnum;
    /**
     * 
     * @type {CityTypeChoices}
     * @memberof AddressChildrenQuery
     */
    'city_type'?: CityTypeChoices | null;
    /**
     * 
     * @type {string}
     * @memberof AddressChildrenQuery
     */
    'fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressChildrenQuery
     */
    'mix_in_for_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressChildrenQuery
     */
    'first_letters'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressChildrenQuery
     */
    'min_providers'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AddressChildrenQuery
     */
    'providers'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddressChildrenQuery
     */
    'provider_slugs'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressChildrenQuery
     */
    'only_with_houses'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddressChildrenQuery
     */
    'only_house_types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressChildrenQuery
     */
    'in_sitemap'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressChildrenQuery
     */
    'optimized'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressChildrenQuery
     */
    'with_region_fias_ids'?: boolean | null;
    /**
     * 
     * @type {AddressChildrenSortChoices}
     * @memberof AddressChildrenQuery
     */
    'sort'?: AddressChildrenSortChoices | null;
    /**
     * 
     * @type {string}
     * @memberof AddressChildrenQuery
     */
    'query'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressChildrenQuery
     */
    'provodnoy_subdomain'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressChildrenQuery
     */
    'only_provodnoy_subdomains'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AddressChildrenQuery
     */
    'site_id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressChildrenQuery
     */
    'join_region_cities'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AddressChildrenQuery
     */
    'limit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddressChildrenQuery
     */
    'offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressChildrenQuery
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressChildrenQuery
     */
    'provodnoy'?: boolean | null;
}

export const AddressChildrenQueryLevelEnum = {
    REGION: 'region',
    AREA: 'area',
    CITY: 'city',
    STREET: 'street'
} as const;

export type AddressChildrenQueryLevelEnum = typeof AddressChildrenQueryLevelEnum[keyof typeof AddressChildrenQueryLevelEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const AddressChildrenSortChoices = {
    NAME: 'NAME',
    POPULATION: 'POPULATION',
    POPULATION_IN_SITEMAP: 'POPULATION_IN_SITEMAP',
    MATCH: 'MATCH',
    ID: 'ID'
} as const;

export type AddressChildrenSortChoices = typeof AddressChildrenSortChoices[keyof typeof AddressChildrenSortChoices];


/**
 * 
 * @export
 * @interface AddressObjectWithParents
 */
export interface AddressObjectWithParents {
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'fias_id': string;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'short_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'url': string | null;
    /**
     * 
     * @type {FiasLevelChoices}
     * @memberof AddressObjectWithParents
     */
    'level': FiasLevelChoices | null;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'name_loc': string | null;
    /**
     * Корневой урл для всех дочерних адресных объектов.
     * @type {boolean}
     * @memberof AddressObjectWithParents
     */
    'url_root'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressObjectWithParents
     */
    'name_unique'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressObjectWithParents
     */
    'in_sitemap'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'autogenerated_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'full_name'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AddressObjectWithParents
     */
    'morph_name'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AddressObjectWithParents
     */
    'morph_short_name'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {BaseAddressObject}
     * @memberof AddressObjectWithParents
     */
    'capital'?: BaseAddressObject | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddressObjectWithParents
     */
    'available_smart_filters'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'provodnoy_subdomain'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'parents_tooltip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'city_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressObjectWithParents
     */
    'region_fias_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddressObjectWithParents
     */
    'provider_slugs'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface AllHousesBody
 */
export interface AllHousesBody {
    /**
     * 
     * @type {string}
     * @memberof AllHousesBody
     */
    'fias_id'?: string | null;
    /**
     * 
     * @type {Array<AllHousesFieldsChoices>}
     * @memberof AllHousesBody
     */
    'only_fields'?: Array<AllHousesFieldsChoices> | null;
    /**
     * 
     * @type {number}
     * @memberof AllHousesBody
     */
    'min_providers'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllHousesBody
     */
    'in_sitemap'?: boolean | null;
    /**
     * Сортировка
     * @type {OrderChoices}
     * @memberof AllHousesBody
     */
    'sort'?: OrderChoices;
    /**
     * Размер страницы
     * @type {number}
     * @memberof AllHousesBody
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AllHousesBody
     */
    'cursor'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AllHousesFieldsChoices = {
    URL: 'url',
    FIAS_ID: 'fias_id',
    NUMBER: 'number',
    PARENT: 'parent',
    IN_SITEMAP: 'in_sitemap'
} as const;

export type AllHousesFieldsChoices = typeof AllHousesFieldsChoices[keyof typeof AllHousesFieldsChoices];


/**
 * 
 * @export
 * @interface AllHousesResult
 */
export interface AllHousesResult {
    /**
     * 
     * @type {string}
     * @memberof AllHousesResult
     */
    'cursor'?: string | null;
    /**
     * Кол-во найденных всего домов
     * @type {number}
     * @memberof AllHousesResult
     */
    'count': number;
    /**
     * 
     * @type {Array<House>}
     * @memberof AllHousesResult
     */
    'houses'?: Array<House> | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof AllHousesResult
     */
    'fields'?: Array<object> | null;
}
/**
 * 
 * @export
 * @interface AuthByPhoneResp
 */
export interface AuthByPhoneResp {
    /**
     * 
     * @type {boolean}
     * @memberof AuthByPhoneResp
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthByPhoneResp
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof AuthByPhoneResp
     */
    'number': string | null;
    /**
     * 
     * @type {AuthByPhoneStrategy}
     * @memberof AuthByPhoneResp
     */
    'strategy': AuthByPhoneStrategy;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AuthByPhoneStrategy = {
    SMS: 'SMS',
    FLASH_CALL: 'FLASH_CALL'
} as const;

export type AuthByPhoneStrategy = typeof AuthByPhoneStrategy[keyof typeof AuthByPhoneStrategy];


/**
 * 
 * @export
 * @interface AuthByUidInput
 */
export interface AuthByUidInput {
    /**
     * 
     * @type {string}
     * @memberof AuthByUidInput
     */
    'uid': string;
}
/**
 * 
 * @export
 * @interface AuthByYandexToken
 */
export interface AuthByYandexToken {
    /**
     * 
     * @type {string}
     * @memberof AuthByYandexToken
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof AuthByYandexToken
     */
    'expires_in': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AvailabilityChoices = {
    UNKNOWN: 'unknown',
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable'
} as const;

export type AvailabilityChoices = typeof AvailabilityChoices[keyof typeof AvailabilityChoices];


/**
 * 
 * @export
 * @interface BBox
 */
export interface BBox {
    /**
     * 
     * @type {LbLat}
     * @memberof BBox
     */
    'lb_lat': LbLat;
    /**
     * 
     * @type {LbLon}
     * @memberof BBox
     */
    'lb_lon': LbLon;
    /**
     * 
     * @type {RtLat}
     * @memberof BBox
     */
    'rt_lat': RtLat;
    /**
     * 
     * @type {RtLon}
     * @memberof BBox
     */
    'rt_lon': RtLon;
}
/**
 * 
 * @export
 * @interface BaseAddressObject
 */
export interface BaseAddressObject {
    /**
     * 
     * @type {string}
     * @memberof BaseAddressObject
     */
    'fias_id': string;
    /**
     * 
     * @type {string}
     * @memberof BaseAddressObject
     */
    'short_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseAddressObject
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseAddressObject
     */
    'url': string | null;
    /**
     * 
     * @type {FiasLevelChoices}
     * @memberof BaseAddressObject
     */
    'level': FiasLevelChoices | null;
    /**
     * 
     * @type {string}
     * @memberof BaseAddressObject
     */
    'name_loc': string | null;
    /**
     * Корневой урл для всех дочерних адресных объектов.
     * @type {boolean}
     * @memberof BaseAddressObject
     */
    'url_root'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseAddressObject
     */
    'name_unique'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseAddressObject
     */
    'in_sitemap'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BaseAddressObject
     */
    'autogenerated_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseAddressObject
     */
    'full_name'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BaseAddressObject
     */
    'morph_name'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BaseAddressObject
     */
    'morph_short_name'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {BaseAddressObject}
     * @memberof BaseAddressObject
     */
    'capital'?: BaseAddressObject | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseAddressObject
     */
    'available_smart_filters'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BaseAddressObject
     */
    'provodnoy_subdomain'?: string | null;
}


/**
 * 
 * @export
 * @interface BaseProviderContact
 */
export interface BaseProviderContact {
    /**
     * 
     * @type {number}
     * @memberof BaseProviderContact
     */
    'phone': number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProviderContact
     */
    'display_phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProviderContact
     */
    'description': string | null;
}
/**
 * 
 * @export
 * @interface BlogAuthor
 */
export interface BlogAuthor {
    /**
     * 
     * @type {number}
     * @memberof BlogAuthor
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BlogAuthor
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAuthor
     */
    'short_description': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAuthor
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAuthor
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof BlogAuthor
     */
    'posts_count'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BlogDeviceChoices = {
    DESKTOP: 'DESKTOP',
    TABLET: 'TABLET',
    MOBILE: 'MOBILE'
} as const;

export type BlogDeviceChoices = typeof BlogDeviceChoices[keyof typeof BlogDeviceChoices];


/**
 * 
 * @export
 * @interface BlogMainPage
 */
export interface BlogMainPage {
    /**
     * 
     * @type {BlogPostPreview}
     * @memberof BlogMainPage
     */
    'main_post': BlogPostPreview | null;
    /**
     * 
     * @type {Array<BlogPostPreview>}
     * @memberof BlogMainPage
     */
    'popular': Array<BlogPostPreview>;
    /**
     * 
     * @type {Array<BlogPostPreview>}
     * @memberof BlogMainPage
     */
    'selection': Array<BlogPostPreview>;
    /**
     * 
     * @type {Array<BlogPostPreview>}
     * @memberof BlogMainPage
     */
    'first_page': Array<BlogPostPreview>;
    /**
     * 
     * @type {number}
     * @memberof BlogMainPage
     */
    'total_count': number;
}
/**
 * 
 * @export
 * @interface BlogMainPageInput
 */
export interface BlogMainPageInput {
    /**
     * 
     * @type {number}
     * @memberof BlogMainPageInput
     */
    'site_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlogMainPageInput
     */
    'popular_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof BlogMainPageInput
     */
    'selection_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof BlogMainPageInput
     */
    'first_page_limit'?: number;
}
/**
 * 
 * @export
 * @interface BlogPost
 */
export interface BlogPost {
    /**
     * 
     * @type {number}
     * @memberof BlogPost
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'preview_image': string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'preview_image_alt': string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'preview_image_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'created': string;
    /**
     * In order of importance
     * @type {Array<BlogTag>}
     * @memberof BlogPost
     */
    'tags': Array<BlogTag>;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'short_text': string | null;
    /**
     * 
     * @type {BlogAuthor}
     * @memberof BlogPost
     */
    'author': BlogAuthor | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'image_alt': string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'image_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'time_to_read': string | null;
    /**
     * 
     * @type {Array<BlogPostAnchor>}
     * @memberof BlogPost
     */
    'anchors': Array<BlogPostAnchor>;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'html': string;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'seo_title': string;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'seo_description': string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    'seo_keywords': string | null;
    /**
     * 3 самых схожих по тэгам, отсортированные по популярности
     * @type {Array<BlogPostPreview>}
     * @memberof BlogPost
     */
    'similar_posts': Array<BlogPostPreview>;
    /**
     * 
     * @type {Array<BlogPostInteractiveBlock>}
     * @memberof BlogPost
     */
    'interactive_blocks'?: Array<BlogPostInteractiveBlock> | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof BlogPost
     */
    'tariffs_list'?: Array<any> | null;
}
/**
 * 
 * @export
 * @interface BlogPostAnchor
 */
export interface BlogPostAnchor {
    /**
     * 
     * @type {string}
     * @memberof BlogPostAnchor
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BlogPostAnchor
     */
    'anchor': string;
    /**
     * 
     * @type {string}
     * @memberof BlogPostAnchor
     */
    'html_tag': string;
}
/**
 * 
 * @export
 * @interface BlogPostInteractiveBlock
 */
export interface BlogPostInteractiveBlock {
    /**
     * 
     * @type {string}
     * @memberof BlogPostInteractiveBlock
     */
    'key': string;
    /**
     * 
     * @type {InteractiveBlockType}
     * @memberof BlogPostInteractiveBlock
     */
    'type'?: InteractiveBlockType | null;
    /**
     * 
     * @type {object}
     * @memberof BlogPostInteractiveBlock
     */
    'config'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof BlogPostInteractiveBlock
     */
    'display_config'?: object | null;
}


/**
 * 
 * @export
 * @interface BlogPostPreview
 */
export interface BlogPostPreview {
    /**
     * 
     * @type {number}
     * @memberof BlogPostPreview
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BlogPostPreview
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BlogPostPreview
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof BlogPostPreview
     */
    'preview_image': string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostPreview
     */
    'preview_image_alt': string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostPreview
     */
    'preview_image_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostPreview
     */
    'created': string;
    /**
     * In order of importance
     * @type {Array<BlogTag>}
     * @memberof BlogPostPreview
     */
    'tags': Array<BlogTag>;
    /**
     * 
     * @type {string}
     * @memberof BlogPostPreview
     */
    'short_text': string | null;
    /**
     * 
     * @type {BlogAuthor}
     * @memberof BlogPostPreview
     */
    'author': BlogAuthor | null;
}
/**
 * 
 * @export
 * @interface BlogPostPreviewPaginated
 */
export interface BlogPostPreviewPaginated {
    /**
     * 
     * @type {number}
     * @memberof BlogPostPreviewPaginated
     */
    'count': number;
    /**
     * 
     * @type {Array<BlogPostPreview>}
     * @memberof BlogPostPreviewPaginated
     */
    'results': Array<BlogPostPreview>;
}
/**
 * 
 * @export
 * @interface BlogTag
 */
export interface BlogTag {
    /**
     * 
     * @type {number}
     * @memberof BlogTag
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BlogTag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogTag
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof BlogTag
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof BlogTag
     */
    'no_index': boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogTag
     */
    'icon': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlogTag
     */
    'show_post_date': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CallCenterAvailability = {
    AVAILABLE: 'AVAILABLE',
    BUSY: 'BUSY',
    VERY_BUSY: 'VERY_BUSY',
    CLOSED: 'CLOSED'
} as const;

export type CallCenterAvailability = typeof CallCenterAvailability[keyof typeof CallCenterAvailability];


/**
 * 
 * @export
 * @interface CallCenterStatus
 */
export interface CallCenterStatus {
    /**
     * Current call center load
     * @type {number}
     * @memberof CallCenterStatus
     */
    'queue': number;
    /**
     * 
     * @type {CallsOpenSchedule}
     * @memberof CallCenterStatus
     */
    'schedule': CallsOpenSchedule;
    /**
     * 
     * @type {number}
     * @memberof CallCenterStatus
     */
    'waiting_time'?: number | null;
}
/**
 * 
 * @export
 * @interface CallTracking
 */
export interface CallTracking {
    /**
     * 
     * @type {number}
     * @memberof CallTracking
     */
    'tracking_id': number;
    /**
     * 
     * @type {string}
     * @memberof CallTracking
     */
    'number_display': string;
    /**
     * 
     * @type {string}
     * @memberof CallTracking
     */
    'call_number': string;
    /**
     * 
     * @type {number}
     * @memberof CallTracking
     */
    'interval': number;
}
/**
 * 
 * @export
 * @interface CallTrackingAddress
 */
export interface CallTrackingAddress {
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'auto_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'full_address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'city_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'street_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'house'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CallTrackingAddress
     */
    'house_id'?: number | null;
    /**
     * 
     * @type {DadataHouseInfo}
     * @memberof CallTrackingAddress
     */
    'house_dadata'?: DadataHouseInfo | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'flat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'entrance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'floor'?: string | null;
    /**
     * 
     * @type {ConnectionHouseType}
     * @memberof CallTrackingAddress
     */
    'house_type'?: ConnectionHouseType | null;
    /**
     * User informed that he will tell info later
     * @type {boolean}
     * @memberof CallTrackingAddress
     */
    'rejected'?: boolean;
    /**
     * 
     * @type {AddressChain}
     * @memberof CallTrackingAddress
     */
    'address_chain'?: AddressChain | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'address_chain_display'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingAddress
     */
    'user_address_display'?: string | null;
}


/**
 * 
 * @export
 * @interface CallTrackingRequest
 */
export interface CallTrackingRequest {
    /**
     * 
     * @type {string}
     * @memberof CallTrackingRequest
     */
    'google_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingRequest
     */
    'yandex_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingRequest
     */
    'page_url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CallTrackingRequest
     */
    'user_activity'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CallTrackingRequest
     */
    'provider_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingRequest
     */
    'fias_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CallTrackingRequest
     */
    'tracking_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CallTrackingRequest
     */
    'partner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingRequest
     */
    'user_ip'?: string | null;
    /**
     * 
     * @type {SiteType}
     * @memberof CallTrackingRequest
     */
    'site_type'?: SiteType | null;
    /**
     * 
     * @type {ConnectionHouseType}
     * @memberof CallTrackingRequest
     */
    'house_type'?: ConnectionHouseType | null;
    /**
     * 
     * @type {boolean}
     * @memberof CallTrackingRequest
     */
    'new_place'?: boolean | null;
    /**
     * 
     * @type {}
     * @memberof CallTrackingRequest
     */
    'utm'?:  | null;
    /**
     * 
     * @type {CallTrackingAddress}
     * @memberof CallTrackingRequest
     */
    'address'?: CallTrackingAddress | null;
    /**
     * 
     * @type {CallTrackingTariff}
     * @memberof CallTrackingRequest
     */
    'tariff'?: CallTrackingTariff | null;
    /**
     * 
     * @type {object}
     * @memberof CallTrackingRequest
     */
    'extra'?: object | null;
}


/**
 * 
 * @export
 * @interface CallTrackingTariff
 */
export interface CallTrackingTariff {
    /**
     * 
     * @type {number}
     * @memberof CallTrackingTariff
     */
    'provider_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CallTrackingTariff
     */
    'tariff_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CallTrackingTariff
     */
    'tariff_name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CallTrackingTariff
     */
    'on_address'?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CallTrackingTariff
     */
    'comparison_tariff_ids'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CallTrackingTariff
     */
    'opened_tariff_ids'?: Array<number> | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof CallTrackingTariff
     */
    'device_use'?: Array<object> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CallTrackingTariff
     */
    'device_payment_ids'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CallTrackingTariff
     */
    'dont_need_router'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CallTrackingTariff
     */
    'dont_need_tv_box'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CallTrackingTariff
     */
    'dont_need_modem'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CallTrackingTariff
     */
    'fake_mobile'?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CallTrackingTariff
     */
    'services'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CallTrackingTariff
     */
    'techs'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof CallTrackingTariff
     */
    'provider_id2'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof CallTrackingTariff
     */
    'tariff'?: object | null;
}
/**
 * 
 * @export
 * @interface CallsAvailabilityStatus
 */
export interface CallsAvailabilityStatus {
    /**
     * 
     * @type {CallCenterAvailability}
     * @memberof CallsAvailabilityStatus
     */
    'availability': CallCenterAvailability;
    /**
     * 
     * @type {CallCenterStatus}
     * @memberof CallsAvailabilityStatus
     */
    'status': CallCenterStatus;
}


/**
 * 
 * @export
 * @interface CallsOpenSchedule
 */
export interface CallsOpenSchedule {
    /**
     * Call center start time in UTC
     * @type {string}
     * @memberof CallsOpenSchedule
     */
    'start': string;
    /**
     * Call center end time in UTC
     * @type {string}
     * @memberof CallsOpenSchedule
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface CallsPhoneNumber
 */
export interface CallsPhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof CallsPhoneNumber
     */
    'display_number': string;
    /**
     * 
     * @type {string}
     * @memberof CallsPhoneNumber
     */
    'call_number': string;
}
/**
 * 
 * @export
 * @interface CallsPhoneNumbers
 */
export interface CallsPhoneNumbers {
    /**
     * 
     * @type {CallsPhoneNumber}
     * @memberof CallsPhoneNumbers
     */
    'connect'?: CallsPhoneNumber | null;
    /**
     * 
     * @type {CallsPhoneNumber}
     * @memberof CallsPhoneNumbers
     */
    'support'?: CallsPhoneNumber | null;
    /**
     * Numbers that client should expect phone calls from
     * @type {Array<CallsPhoneNumber>}
     * @memberof CallsPhoneNumbers
     */
    'outgoing'?: Array<CallsPhoneNumber>;
    /**
     * 
     * @type {Array<CallsPhoneNumber>}
     * @memberof CallsPhoneNumbers
     */
    'other'?: Array<CallsPhoneNumber> | null;
    /**
     * 
     * @type {CallCenterAvailability}
     * @memberof CallsPhoneNumbers
     */
    'availability': CallCenterAvailability;
}


/**
 * 
 * @export
 * @interface CallsTimeSlot
 */
export interface CallsTimeSlot {
    /**
     * 
     * @type {string}
     * @memberof CallsTimeSlot
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CallsTimeSlot
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface CallsTimeSlots
 */
export interface CallsTimeSlots {
    /**
     * 
     * @type {boolean}
     * @memberof CallsTimeSlots
     */
    'display_right_away': boolean;
    /**
     * 
     * @type {CallsTimeSlot}
     * @memberof CallsTimeSlots
     */
    'default_slot': CallsTimeSlot | null;
    /**
     * 
     * @type {number}
     * @memberof CallsTimeSlots
     */
    'waiting_time': number | null;
    /**
     * 
     * @type {Array<ClassDayTimeSlots>}
     * @memberof CallsTimeSlots
     */
    'slots': Array<ClassDayTimeSlots>;
}
/**
 * 
 * @export
 * @interface CareerForm
 */
export interface CareerForm {
    /**
     * 
     * @type {string}
     * @memberof CareerForm
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof CareerForm
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof CareerForm
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerForm
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CareerForm
     */
    'about'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof CareerForm
     */
    'additional'?: object | null;
}
/**
 * 
 * @export
 * @interface CheckAuthByPhoneBody
 */
export interface CheckAuthByPhoneBody {
    /**
     * 
     * @type {string}
     * @memberof CheckAuthByPhoneBody
     */
    'number': string;
    /**
     * 
     * @type {AuthByPhoneStrategy}
     * @memberof CheckAuthByPhoneBody
     */
    'strategy'?: AuthByPhoneStrategy;
    /**
     * 
     * @type {number}
     * @memberof CheckAuthByPhoneBody
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof CheckAuthByPhoneBody
     */
    'name': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CityTypeChoices = {
    ALL: 'all',
    CITY: 'city',
    SETTLEMENT: 'settlement'
} as const;

export type CityTypeChoices = typeof CityTypeChoices[keyof typeof CityTypeChoices];


/**
 * 
 * @export
 * @interface ClassDayTimeSlots
 */
export interface ClassDayTimeSlots {
    /**
     * 
     * @type {string}
     * @memberof ClassDayTimeSlots
     */
    'human_day': string;
    /**
     * 
     * @type {string}
     * @memberof ClassDayTimeSlots
     */
    'day': string;
    /**
     * 
     * @type {Array<CallsTimeSlot>}
     * @memberof ClassDayTimeSlots
     */
    'slots': Array<CallsTimeSlot>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConnectionHouseType = {
    FLAT: 'FLAT',
    HOUSE: 'HOUSE',
    OFFICE: 'OFFICE'
} as const;

export type ConnectionHouseType = typeof ConnectionHouseType[keyof typeof ConnectionHouseType];


/**
 * 
 * @export
 * @interface ContentPage
 */
export interface ContentPage {
    /**
     * 
     * @type {string}
     * @memberof ContentPage
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPage
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPage
     */
    'h1': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPage
     */
    'h2': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPage
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPage
     */
    'main_content': string;
    /**
     * 
     * @type {ContentPageBlocks}
     * @memberof ContentPage
     */
    'blocks': ContentPageBlocks;
    /**
     * 
     * @type {object}
     * @memberof ContentPage
     */
    'extra': object;
    /**
     * 
     * @type {boolean}
     * @memberof ContentPage
     */
    'index'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContentPage
     */
    'indexed_topologies'?: Array<string> | null;
    /**
     * 
     * @type {Array<ContentPageInterlinking>}
     * @memberof ContentPage
     */
    'interlinking'?: Array<ContentPageInterlinking> | null;
    /**
     * 
     * @type {ContentPageVersion}
     * @memberof ContentPage
     */
    'version'?: ContentPageVersion | null;
}
/**
 * 
 * @export
 * @interface ContentPageAdditionalService
 */
export interface ContentPageAdditionalService {
    /**
     * 
     * @type {string}
     * @memberof ContentPageAdditionalService
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageAdditionalService
     */
    'icon': string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentPageAdditionalService
     */
    'short_caption': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageAdditionalService
     */
    'popup_caption': string;
}
/**
 * 
 * @export
 * @interface ContentPageArticle
 */
export interface ContentPageArticle {
    /**
     * 
     * @type {string}
     * @memberof ContentPageArticle
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageArticle
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageArticle
     */
    'title_image': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageArticle
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageArticle
     */
    'target': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageArticle
     */
    'date': string | null;
    /**
     * 
     * @type {object}
     * @memberof ContentPageArticle
     */
    'config': object | null;
}
/**
 * 
 * @export
 * @interface ContentPageBenefit
 */
export interface ContentPageBenefit {
    /**
     * 
     * @type {string}
     * @memberof ContentPageBenefit
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageBenefit
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageBenefit
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageBenefit
     */
    'icon_img': string;
    /**
     * 
     * @type {TargetChoices}
     * @memberof ContentPageBenefit
     */
    'target': TargetChoices | null;
}


/**
 * 
 * @export
 * @interface ContentPageBlocks
 */
export interface ContentPageBlocks {
    /**
     * 
     * @type {Array<ContentPageBenefit>}
     * @memberof ContentPageBlocks
     */
    'benefits': Array<ContentPageBenefit>;
    /**
     * 
     * @type {Array<ContentPageAdditionalService>}
     * @memberof ContentPageBlocks
     */
    'additional_services': Array<ContentPageAdditionalService>;
    /**
     * 
     * @type {Array<ContentPageFAQ>}
     * @memberof ContentPageBlocks
     */
    'faqs': Array<ContentPageFAQ>;
    /**
     * 
     * @type {Array<ContentPagePromotion>}
     * @memberof ContentPageBlocks
     */
    'promotions': Array<ContentPagePromotion>;
    /**
     * 
     * @type {Array<ContentPageArticle>}
     * @memberof ContentPageBlocks
     */
    'articles': Array<ContentPageArticle>;
}
/**
 * 
 * @export
 * @interface ContentPageFAQ
 */
export interface ContentPageFAQ {
    /**
     * 
     * @type {string}
     * @memberof ContentPageFAQ
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageFAQ
     */
    'answer': string;
}
/**
 * 
 * @export
 * @interface ContentPageInterlinking
 */
export interface ContentPageInterlinking {
    /**
     * 
     * @type {string}
     * @memberof ContentPageInterlinking
     */
    'to_page': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageInterlinking
     */
    'title': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ContentPageInterlinking
     */
    'anchors': { [key: string]: string; };
    /**
     * 
     * @type {Array<AddressObjectWithParents>}
     * @memberof ContentPageInterlinking
     */
    'addresses': Array<AddressObjectWithParents>;
}
/**
 * 
 * @export
 * @interface ContentPagePromotion
 */
export interface ContentPagePromotion {
    /**
     * 
     * @type {string}
     * @memberof ContentPagePromotion
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPagePromotion
     */
    'title_image': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPagePromotion
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPagePromotion
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPagePromotion
     */
    'button': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPagePromotion
     */
    'image': string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentPagePromotion
     */
    'background_color': string;
    /**
     * 
     * @type {string}
     * @memberof ContentPagePromotion
     */
    'extra_css': string;
    /**
     * 
     * @type {PromotionBlockChoices}
     * @memberof ContentPagePromotion
     */
    'block': PromotionBlockChoices;
    /**
     * 
     * @type {object}
     * @memberof ContentPagePromotion
     */
    'config': object | null;
}


/**
 * 
 * @export
 * @interface ContentPageVersion
 */
export interface ContentPageVersion {
    /**
     * 
     * @type {string}
     * @memberof ContentPageVersion
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentPageVersion
     */
    'site_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentPageVersion
     */
    'inner_redirect'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentPageVersion
     */
    'external_redirect'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContentPageVersion
     */
    'features'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CurrentUserInfoResp
 */
export interface CurrentUserInfoResp {
    /**
     * 
     * @type {string}
     * @memberof CurrentUserInfoResp
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserInfoResp
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserInfoResp
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserInfoResp
     */
    'verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserInfoResp
     */
    'uid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentUserInfoResp
     */
    'site_user_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserInfoResp
     */
    'avatar_url'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentUserInfoResp
     */
    'interaction_orders_count'?: number;
    /**
     * 
     * @type {object}
     * @memberof CurrentUserInfoResp
     */
    'extra'?: object | null;
}
/**
 * 
 * @export
 * @interface DadataHouseInfo
 */
export interface DadataHouseInfo {
    /**
     * 
     * @type {string}
     * @memberof DadataHouseInfo
     */
    'house_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DadataHouseInfo
     */
    'house'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DadataHouseInfo
     */
    'block_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DadataHouseInfo
     */
    'block'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DadataHouseInfo
     */
    'fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DadataHouseInfo
     */
    'parent_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DadataHouseInfo
     */
    'city_fias_id'?: string | null;
}
/**
 * 
 * @export
 * @interface ExceptionResponse
 */
export interface ExceptionResponse {
    /**
     * 
     * @type {string}
     * @memberof ExceptionResponse
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ExceptionResponse
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionResponse
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface ExceptionValidation
 */
export interface ExceptionValidation {
    /**
     * 
     * @type {string}
     * @memberof ExceptionValidation
     */
    'message': string;
    /**
     * 
     * @type {object}
     * @memberof ExceptionValidation
     */
    'detail': object;
    /**
     * 
     * @type {Array<object>}
     * @memberof ExceptionValidation
     */
    'errors': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof ExceptionValidation
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface FiasFilters
 */
export interface FiasFilters {
    /**
     * Text search by name
     * @type {string}
     * @memberof FiasFilters
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof FiasFilters
     */
    'fias_id': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiasFilters
     */
    'provider_ids'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof FiasFilters
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FiasFilters
     */
    'offset'?: number;
    /**
     * Всегда выдавать тултипы, даже если родитель совпадает
     * @type {boolean}
     * @memberof FiasFilters
     */
    'tooltips_always'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FiasFilters
     */
    'site_id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiasFilters
     */
    'provodnoy'?: boolean | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FiasLevelChoices = {
    REGION: 'region',
    AREA: 'area',
    CITY: 'city',
    STREET: 'street'
} as const;

export type FiasLevelChoices = typeof FiasLevelChoices[keyof typeof FiasLevelChoices];


/**
 * 
 * @export
 * @interface FiasRootFilters
 */
export interface FiasRootFilters {
    /**
     * Text search by name
     * @type {string}
     * @memberof FiasRootFilters
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof FiasRootFilters
     */
    'fias_id'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiasRootFilters
     */
    'provider_ids'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof FiasRootFilters
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FiasRootFilters
     */
    'offset'?: number;
    /**
     * Всегда выдавать тултипы, даже если родитель совпадает
     * @type {boolean}
     * @memberof FiasRootFilters
     */
    'tooltips_always'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FiasRootFilters
     */
    'site_id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiasRootFilters
     */
    'provodnoy'?: boolean | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FunnelChoices = {
    LANDING: 'landing',
    TARIFF: 'tariff',
    TARIFF_LIST: 'tariff_list',
    OTHER: 'other'
} as const;

export type FunnelChoices = typeof FunnelChoices[keyof typeof FunnelChoices];


/**
 * 
 * @export
 * @interface GeoChainQuery
 */
export interface GeoChainQuery {
    /**
     * 
     * @type {string}
     * @memberof GeoChainQuery
     */
    'url_geo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeoChainQuery
     */
    'provider'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeoChainQuery
     */
    'yandex_geo'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeoChainQuery
     */
    'google_geo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GeoChainQuery
     */
    'client_ip'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GeoChainQuery
     */
    'is_bot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeoChainQuery
     */
    'include_self'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeoChainQuery
     */
    'morph'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GeoChainQuery
     */
    'site_id'?: number | null;
    /**
     * Предпочитать site_id
     * @type {boolean}
     * @memberof GeoChainQuery
     * @deprecated
     */
    'provodnoy'?: boolean;
}
/**
 * 
 * @export
 * @interface House
 */
export interface House {
    /**
     * 
     * @type {number}
     * @memberof House
     */
    'id': number | null;
    /**
     * Full house number
     * @type {string}
     * @memberof House
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof House
     */
    'url': string | null;
    /**
     * 
     * @type {DadataHouseInfo}
     * @memberof House
     */
    'dadata': DadataHouseInfo | null;
    /**
     * 
     * @type {string}
     * @memberof House
     */
    'parents_tooltip'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof House
     */
    'providers_count'?: number | null;
    /**
     * Показывать дом в сайтмапе/для роботов
     * @type {boolean}
     * @memberof House
     */
    'in_sitemap'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof House
     */
    'autogenerated_url'?: string | null;
}
/**
 * 
 * @export
 * @interface InteractionAddress
 */
export interface InteractionAddress {
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'auto_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'full_address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'city_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'street_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'house'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InteractionAddress
     */
    'house_id'?: number | null;
    /**
     * 
     * @type {DadataHouseInfo}
     * @memberof InteractionAddress
     */
    'house_dadata'?: DadataHouseInfo | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'flat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'entrance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionAddress
     */
    'floor'?: string | null;
    /**
     * 
     * @type {ConnectionHouseType}
     * @memberof InteractionAddress
     */
    'house_type'?: ConnectionHouseType | null;
    /**
     * User informed that he will tell info later
     * @type {boolean}
     * @memberof InteractionAddress
     */
    'rejected'?: boolean;
}


/**
 * 
 * @export
 * @interface InteractionClient
 */
export interface InteractionClient {
    /**
     * 
     * @type {string}
     * @memberof InteractionClient
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionClient
     */
    'additional_phone'?: string | null;
    /**
     * User clicked that he will tell info when called
     * @type {boolean}
     * @memberof InteractionClient
     */
    'rejected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InteractionClient
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionClient
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionClient
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionClient
     */
    'birth_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionClient
     */
    'visit_time_start'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionClient
     */
    'visit_time_end'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionClient
     */
    'visit_as_soon_as_possible'?: boolean | null;
}
/**
 * 
 * @export
 * @interface InteractionCreate
 */
export interface InteractionCreate {
    /**
     * Mark interaction as completed by user. Further updates are forbidden
     * @type {boolean}
     * @memberof InteractionCreate
     */
    'complete'?: boolean;
    /**
     * When true then mark interaction as interrupted by user. Further updates are forbidden.
     * @type {boolean}
     * @memberof InteractionCreate
     */
    'interrupt'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InteractionCreate
     */
    'partner_id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionCreate
     */
    'fake_tariffs'?: boolean | null;
    /**
     * Interaction metadata
     * @type {InteractionMeta}
     * @memberof InteractionCreate
     */
    'meta'?: InteractionMeta;
    /**
     * 
     * @type {InteractionLead}
     * @memberof InteractionCreate
     */
    'lead'?: InteractionLead | null;
    /**
     * 
     * @type {InteractionClient}
     * @memberof InteractionCreate
     */
    'client'?: InteractionClient | null;
    /**
     * 
     * @type {InteractionAddress}
     * @memberof InteractionCreate
     */
    'address'?: InteractionAddress | null;
    /**
     * 
     * @type {InteractionTariff}
     * @memberof InteractionCreate
     */
    'tariff'?: InteractionTariff | null;
    /**
     * 
     * @type {object}
     * @memberof InteractionCreate
     */
    'extra'?: object | null;
}
/**
 * 
 * @export
 * @interface InteractionLead
 */
export interface InteractionLead {
    /**
     * 
     * @type {string}
     * @memberof InteractionLead
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionLead
     */
    'additional_phone'?: string | null;
    /**
     * User clicked that he will tell info when called
     * @type {boolean}
     * @memberof InteractionLead
     */
    'rejected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InteractionLead
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionLead
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionLead
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionLead
     */
    'birth_date'?: string | null;
    /**
     * Fake interaction, should not be send to marketing
     * @type {boolean}
     * @memberof InteractionLead
     */
    'fake'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InteractionLead
     */
    'call_time'?: string | null;
}
/**
 * 
 * @export
 * @interface InteractionMeta
 */
export interface InteractionMeta {
    /**
     * 
     * @type {string}
     * @memberof InteractionMeta
     */
    'request_type'?: string;
    /**
     * Name that describes interation along with request_type
     * @type {string}
     * @memberof InteractionMeta
     */
    'request_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InteractionMeta
     */
    'at_url'?: string | null;
    /**
     * Site type
     * @type {}
     * @memberof InteractionMeta
     */
    'site_type'?:  | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionMeta
     */
    'yandex_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionMeta
     */
    'google_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionMeta
     */
    'optimizer_id'?: string | null;
    /**
     * 
     * @type {Utm}
     * @memberof InteractionMeta
     */
    'utm'?: Utm | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionMeta
     */
    're_captcha_token'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionMeta
     */
    'pass_through_lead'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionMeta
     */
    'complete_order'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionMeta
     */
    'site_city_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionMeta
     */
    'site_street_fias_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InteractionMeta
     */
    'site_house_id'?: number | null;
    /**
     * 
     * @type {DadataHouseInfo}
     * @memberof InteractionMeta
     */
    'site_house_dadata'?: DadataHouseInfo | null;
}
/**
 * 
 * @export
 * @interface InteractionTariff
 */
export interface InteractionTariff {
    /**
     * 
     * @type {number}
     * @memberof InteractionTariff
     */
    'provider_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InteractionTariff
     */
    'tariff_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InteractionTariff
     */
    'tariff_name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionTariff
     */
    'on_address'?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InteractionTariff
     */
    'comparison_tariff_ids'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InteractionTariff
     */
    'opened_tariff_ids'?: Array<number> | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof InteractionTariff
     */
    'device_use'?: Array<object> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InteractionTariff
     */
    'device_payment_ids'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionTariff
     */
    'dont_need_router'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionTariff
     */
    'dont_need_tv_box'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionTariff
     */
    'dont_need_modem'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionTariff
     */
    'fake_mobile'?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InteractionTariff
     */
    'services'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InteractionTariff
     */
    'techs'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InteractionUpdate
 */
export interface InteractionUpdate {
    /**
     * Mark interaction as completed by user. Further updates are forbidden
     * @type {boolean}
     * @memberof InteractionUpdate
     */
    'complete'?: boolean;
    /**
     * When true then mark interaction as interrupted by user. Further updates are forbidden.
     * @type {boolean}
     * @memberof InteractionUpdate
     */
    'interrupt'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InteractionUpdate
     */
    'partner_id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InteractionUpdate
     */
    'fake_tariffs'?: boolean | null;
    /**
     * 
     * @type {InteractionMeta}
     * @memberof InteractionUpdate
     */
    'meta'?: InteractionMeta | null;
    /**
     * 
     * @type {InteractionLead}
     * @memberof InteractionUpdate
     */
    'lead'?: InteractionLead | null;
    /**
     * 
     * @type {InteractionClient}
     * @memberof InteractionUpdate
     */
    'client'?: InteractionClient | null;
    /**
     * 
     * @type {InteractionAddress}
     * @memberof InteractionUpdate
     */
    'address'?: InteractionAddress | null;
    /**
     * 
     * @type {InteractionTariff}
     * @memberof InteractionUpdate
     */
    'tariff'?: InteractionTariff | null;
    /**
     * 
     * @type {object}
     * @memberof InteractionUpdate
     */
    'extra'?: object | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InteractiveBlockType = {
    TARIFFS_TABLE: 'TARIFFS_TABLE',
    TARIFFS_CARDS: 'TARIFFS_CARDS',
    PROVIDERS_CARDS: 'PROVIDERS_CARDS',
    ADDRESS_CHECK: 'ADDRESS_CHECK'
} as const;

export type InteractiveBlockType = typeof InteractiveBlockType[keyof typeof InteractiveBlockType];


/**
 * left_bottom latitude
 * @export
 * @interface LbLat
 */
export interface LbLat {
}
/**
 * left_bottom longitude
 * @export
 * @interface LbLon
 */
export interface LbLon {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LogEntryLevel = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    ERROR: 'ERROR'
} as const;

export type LogEntryLevel = typeof LogEntryLevel[keyof typeof LogEntryLevel];


/**
 * 
 * @export
 * @interface LogEntrySchema
 */
export interface LogEntrySchema {
    /**
     * 
     * @type {string}
     * @memberof LogEntrySchema
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogEntrySchema
     */
    'at_url'?: string | null;
    /**
     * 
     * @type {LogEntryLevel}
     * @memberof LogEntrySchema
     */
    'level'?: LogEntryLevel;
    /**
     * 
     * @type {any}
     * @memberof LogEntrySchema
     */
    'data': any;
}


/**
 * 
 * @export
 * @interface MyReviewAnswers
 */
export interface MyReviewAnswers {
    /**
     * Ответы на шаги
     * @type {Array<QuestionnaireAnswerResult>}
     * @memberof MyReviewAnswers
     */
    'answers': Array<QuestionnaireAnswerResult>;
    /**
     * 
     * @type {string}
     * @memberof MyReviewAnswers
     */
    're_captcha_token'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MyReviewAnswers
     */
    'review_id': number;
    /**
     * 
     * @type {string}
     * @memberof MyReviewAnswers
     */
    'current_questionnaire_slug': string | null;
}
/**
 * 
 * @export
 * @interface MyReviewsResponse
 */
export interface MyReviewsResponse {
    /**
     * 
     * @type {Array<ProviderReview>}
     * @memberof MyReviewsResponse
     */
    'reviews': Array<ProviderReview>;
    /**
     * 
     * @type {Array<MyReviewAnswers>}
     * @memberof MyReviewsResponse
     */
    'review_answers': Array<MyReviewAnswers>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderChoices = {
    PROVIDERS: 'PROVIDERS',
    NUMBER: 'NUMBER',
    ID: 'ID'
} as const;

export type OrderChoices = typeof OrderChoices[keyof typeof OrderChoices];


/**
 * 
 * @export
 * @enum {string}
 */

export const PromotionBlockChoices = {
    NA: 'NA',
    UPPER: 'UPPER',
    UPPER_BONUS: 'UPPER_BONUS',
    UPPER_DEVICES: 'UPPER_DEVICES',
    LOWER: 'LOWER'
} as const;

export type PromotionBlockChoices = typeof PromotionBlockChoices[keyof typeof PromotionBlockChoices];


/**
 * 
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     * 
     * @type {number}
     * @memberof Provider
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof Provider
     */
    'tariffless': boolean;
    /**
     * 
     * @type {Provider}
     * @memberof Provider
     */
    'consumed_by'?: Provider | null;
    /**
     * 
     * @type {number}
     * @memberof Provider
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'card_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'card_description': string | null;
    /**
     * 
     * @type {ProviderPromoDisplayChoices}
     * @memberof Provider
     */
    'promo_display': ProviderPromoDisplayChoices | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'logo': string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'logo_sq': string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'logo_partner': string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'icon_di': string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'icon_large': string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'icon_provodnoy': string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'site_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'lk_url': string | null;
    /**
     * 
     * @type {object}
     * @memberof Provider
     */
    'config': object | null;
    /**
     * 
     * @type {object}
     * @memberof Provider
     */
    'provodnoy_config': object | null;
}


/**
 * 
 * @export
 * @interface ProviderAbout
 */
export interface ProviderAbout {
    /**
     * 
     * @type {number}
     * @memberof ProviderAbout
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderAbout
     */
    'tariffless': boolean;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderAbout
     */
    'consumed_by'?: Provider | null;
    /**
     * 
     * @type {number}
     * @memberof ProviderAbout
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'card_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'card_description': string | null;
    /**
     * 
     * @type {ProviderPromoDisplayChoices}
     * @memberof ProviderAbout
     */
    'promo_display': ProviderPromoDisplayChoices | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'logo': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'logo_sq': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'logo_partner': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'icon_di': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'icon_large': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'icon_provodnoy': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'site_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'lk_url': string | null;
    /**
     * 
     * @type {object}
     * @memberof ProviderAbout
     */
    'config': object | null;
    /**
     * 
     * @type {object}
     * @memberof ProviderAbout
     */
    'provodnoy_config': object | null;
    /**
     * 
     * @type {ProviderContact}
     * @memberof ProviderAbout
     */
    'contact': ProviderContact | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAbout
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface ProviderContact
 */
export interface ProviderContact {
    /**
     * 
     * @type {number}
     * @memberof ProviderContact
     */
    'phone': number | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderContact
     */
    'display_phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderContact
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderContact
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderContact
     */
    'telegram': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderContact
     */
    'whatsapp': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderContact
     */
    'viber': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderContact
     */
    'vk': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderContact
     */
    'ok': string | null;
}
/**
 * 
 * @export
 * @interface ProviderOffice
 */
export interface ProviderOffice {
    /**
     * 
     * @type {number}
     * @memberof ProviderOffice
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProviderOffice
     */
    'provider_id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderOffice
     */
    'full_address': string;
    /**
     * 
     * @type {number}
     * @memberof ProviderOffice
     */
    'geo_lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProviderOffice
     */
    'geo_lon': number | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderOffice
     */
    'description': string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProviderOffice
     */
    'tags': Array<number>;
    /**
     * 
     * @type {Array<BaseProviderContact>}
     * @memberof ProviderOffice
     */
    'phone_numbers': Array<BaseProviderContact>;
    /**
     * 
     * @type {Array<ProviderOfficeWorkingHours>}
     * @memberof ProviderOffice
     */
    'working_hours': Array<ProviderOfficeWorkingHours>;
    /**
     * Выделены, как офисы текущего города на сайте
     * @type {boolean}
     * @memberof ProviderOffice
     */
    'highlighted'?: boolean;
}
/**
 * 
 * @export
 * @interface ProviderOfficeTag
 */
export interface ProviderOfficeTag {
    /**
     * 
     * @type {number}
     * @memberof ProviderOfficeTag
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderOfficeTag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOfficeTag
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderOfficeTag
     */
    'icon': string | null;
}
/**
 * 
 * @export
 * @interface ProviderOfficeWorkingHours
 */
export interface ProviderOfficeWorkingHours {
    /**
     * 
     * @type {string}
     * @memberof ProviderOfficeWorkingHours
     */
    'days_range': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOfficeWorkingHours
     */
    'time_range': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOfficeWorkingHours
     */
    'dinner_range': string | null;
}
/**
 * 
 * @export
 * @interface ProviderOfficesBody
 */
export interface ProviderOfficesBody {
    /**
     * 
     * @type {BBox}
     * @memberof ProviderOfficesBody
     */
    'bbox'?: BBox | null;
    /**
     * 
     * @type {number}
     * @memberof ProviderOfficesBody
     */
    'limit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProviderOfficesBody
     */
    'offset'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProviderOfficesBody
     */
    'tag_ids'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProviderOfficesBody
     */
    'provider_ids'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProviderOfficesBody
     */
    'provider_slugs'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderOfficesBody
     */
    'highlight_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderOfficesBody
     */
    'filter_fias_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderOfficesBody
     */
    'without_shared'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ProviderOfficesResponse
 */
export interface ProviderOfficesResponse {
    /**
     * 
     * @type {number}
     * @memberof ProviderOfficesResponse
     */
    'total_count': number;
    /**
     * 
     * @type {Array<ProviderOfficeTag>}
     * @memberof ProviderOfficesResponse
     */
    'available_tags': Array<ProviderOfficeTag>;
    /**
     * 
     * @type {Array<ProviderSharedOffice>}
     * @memberof ProviderOfficesResponse
     */
    'shared_offices': Array<ProviderSharedOffice>;
    /**
     * 
     * @type {Array<ProviderOffice>}
     * @memberof ProviderOfficesResponse
     */
    'result': Array<ProviderOffice>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProviderPromoDisplayChoices = {
    FULL: 'FULL',
    SHORT: 'SHORT'
} as const;

export type ProviderPromoDisplayChoices = typeof ProviderPromoDisplayChoices[keyof typeof ProviderPromoDisplayChoices];


/**
 * 
 * @export
 * @interface ProviderReview
 */
export interface ProviderReview {
    /**
     * 
     * @type {number}
     * @memberof ProviderReview
     */
    'id': number;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderReview
     */
    'provider': Provider;
    /**
     * 
     * @type {string}
     * @memberof ProviderReview
     */
    'author_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderReview
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof ProviderReview
     */
    'rating_connection': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProviderReview
     */
    'rating_stability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProviderReview
     */
    'rating_speed': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProviderReview
     */
    'rating_service': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProviderReview
     */
    'rating_final': number | null;
    /**
     * Positive review
     * @type {boolean}
     * @memberof ProviderReview
     */
    'positive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderReview
     */
    'current_user_reaction': boolean | null;
    /**
     * Может ли текущий пользователь отредактировать это ревью
     * @type {boolean}
     * @memberof ProviderReview
     */
    'can_edit'?: boolean;
    /**
     * Означает что это ревью оставил текущий пользователь
     * @type {boolean}
     * @memberof ProviderReview
     */
    'from_current_user'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProviderReview
     */
    'likes': number;
    /**
     * 
     * @type {number}
     * @memberof ProviderReview
     */
    'dislikes': number;
    /**
     * 
     * @type {number}
     * @memberof ProviderReview
     */
    'comments_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderReview
     */
    'approved': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderReview
     */
    'connected': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderReview
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderReview
     */
    'location_display': string | null;
}
/**
 * 
 * @export
 * @interface ProviderReviewInput
 */
export interface ProviderReviewInput {
    /**
     * Ответы на шаги
     * @type {Array<QuestionnaireAnswerResult>}
     * @memberof ProviderReviewInput
     */
    'answers': Array<QuestionnaireAnswerResult>;
    /**
     * 
     * @type {string}
     * @memberof ProviderReviewInput
     */
    're_captcha_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderReviewInput
     */
    'questionnaire_slug'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProviderReviewInput
     */
    'provider_id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderReviewInput
     */
    'author_name': string | null;
}
/**
 * 
 * @export
 * @interface ProviderReviewUpdateInput
 */
export interface ProviderReviewUpdateInput {
    /**
     * Ответы на шаги
     * @type {Array<QuestionnaireAnswerResult>}
     * @memberof ProviderReviewUpdateInput
     */
    'answers': Array<QuestionnaireAnswerResult>;
    /**
     * 
     * @type {string}
     * @memberof ProviderReviewUpdateInput
     */
    're_captcha_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderReviewUpdateInput
     */
    'questionnaire_slug'?: string;
}
/**
 * 
 * @export
 * @interface ProviderSharedOffice
 */
export interface ProviderSharedOffice {
    /**
     * 
     * @type {Provider}
     * @memberof ProviderSharedOffice
     */
    'provider': Provider;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderSharedOffice
     */
    'uses_offices_of': Provider;
}
/**
 * 
 * @export
 * @interface QuestionnaireAnswerResult
 */
export interface QuestionnaireAnswerResult {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireAnswerResult
     */
    'step_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireAnswerResult
     */
    'step_key'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireAnswerResult
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireAnswerResult
     */
    'key'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireAnswerResult
     */
    'rating'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireAnswerResult
     */
    'text'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof QuestionnaireAnswerResult
     */
    'extra'?: object | null;
}
/**
 * 
 * @export
 * @interface QuestionnaireSchema
 */
export interface QuestionnaireSchema {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireSchema
     */
    'key': string;
    /**
     * 
     * @type {QuestionnaireType}
     * @memberof QuestionnaireSchema
     */
    'type': QuestionnaireType;
    /**
     * 
     * @type {object}
     * @memberof QuestionnaireSchema
     */
    'extra'?: object | null;
    /**
     * 
     * @type {Array<QuestionnaireStepSchema>}
     * @memberof QuestionnaireSchema
     */
    'steps': Array<QuestionnaireStepSchema>;
}


/**
 * 
 * @export
 * @interface QuestionnaireStepAnswerSchema
 */
export interface QuestionnaireStepAnswerSchema {
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireStepAnswerSchema
     */
    'set_branch'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionnaireStepAnswerSchema
     */
    'for_branches'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireStepAnswerSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireStepAnswerSchema
     */
    'key': string;
    /**
     * Текс варианта ответа. Для RATING - помощь для рейтинга `help_for_rating`
     * @type {string}
     * @memberof QuestionnaireStepAnswerSchema
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireStepAnswerSchema
     */
    'help_text'?: string | null;
    /**
     * Нельзя выбрать. Только для отображения помощи.
     * @type {boolean}
     * @memberof QuestionnaireStepAnswerSchema
     */
    'disabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireStepAnswerSchema
     */
    'help_for_rating'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof QuestionnaireStepAnswerSchema
     */
    'extra'?: object | null;
}
/**
 * 
 * @export
 * @interface QuestionnaireStepSchema
 */
export interface QuestionnaireStepSchema {
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireStepSchema
     */
    'set_branch'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionnaireStepSchema
     */
    'for_branches'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireStepSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireStepSchema
     */
    'key': string;
    /**
     * Что отображать в заголовке шага / в теле для типа шага MESSAGE.
     * @type {string}
     * @memberof QuestionnaireStepSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireStepSchema
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireStepSchema
     */
    'help_text_short'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireStepSchema
     */
    'help_text'?: string | null;
    /**
     * 
     * @type {QuestionnaireStepType}
     * @memberof QuestionnaireStepSchema
     */
    'type': QuestionnaireStepType;
    /**
     * Опциональный шаг. Можно ничего не выбирать/не ставить оценку/не писать текст
     * @type {boolean}
     * @memberof QuestionnaireStepSchema
     */
    'optional': boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireStepSchema
     */
    'optional_text'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof QuestionnaireStepSchema
     */
    'extra'?: object | null;
    /**
     * 
     * @type {Array<QuestionnaireStepAnswerSchema>}
     * @memberof QuestionnaireStepSchema
     */
    'answers': Array<QuestionnaireStepAnswerSchema>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const QuestionnaireStepType = {
    CHECKBOX: 'CHECKBOX',
    RADIO: 'RADIO',
    RATING: 'RATING',
    TEXT: 'TEXT',
    MESSAGE: 'MESSAGE',
    PROVIDER_SELECTION: 'PROVIDER_SELECTION',
    MOBILE_PROVIDER_SELECTION: 'MOBILE_PROVIDER_SELECTION',
    VIDEO_SERVICES_SELECTION: 'VIDEO_SERVICES_SELECTION'
} as const;

export type QuestionnaireStepType = typeof QuestionnaireStepType[keyof typeof QuestionnaireStepType];


/**
 * 
 * @export
 * @enum {string}
 */

export const QuestionnaireType = {
    REVIEW: 'REVIEW',
    SELECT_TARIFF: 'SELECT_TARIFF',
    SELECT_TARIFF_AGGREGATOR: 'SELECT_TARIFF_AGGREGATOR',
    GENERIC: 'GENERIC'
} as const;

export type QuestionnaireType = typeof QuestionnaireType[keyof typeof QuestionnaireType];


/**
 * 
 * @export
 * @interface ReactionInput
 */
export interface ReactionInput {
    /**
     * true - нажат лайк. false - нажат дизлайк
     * @type {boolean}
     * @memberof ReactionInput
     */
    'like': boolean;
    /**
     * 
     * @type {number}
     * @memberof ReactionInput
     */
    'comment_id'?: number | null;
}
/**
 * 
 * @export
 * @interface ReactionResponse
 */
export interface ReactionResponse {
    /**
     * 
     * @type {number}
     * @memberof ReactionResponse
     */
    'like_delta': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReactionResponse
     */
    'liked': boolean;
    /**
     * 
     * @type {number}
     * @memberof ReactionResponse
     */
    'dislike_delta': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReactionResponse
     */
    'disliked': boolean;
}
/**
 * 
 * @export
 * @interface RedirectResponse
 */
export interface RedirectResponse {
    /**
     * 
     * @type {string}
     * @memberof RedirectResponse
     */
    'redirect'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RedirectResponse
     */
    'status_code'?: number | null;
}
/**
 * 
 * @export
 * @interface ReviewComment
 */
export interface ReviewComment {
    /**
     * 
     * @type {number}
     * @memberof ReviewComment
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ReviewComment
     */
    'answer_to_id': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewComment
     */
    'connected': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReviewComment
     */
    'author_name': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewComment
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof ReviewComment
     */
    'likes': number;
    /**
     * 
     * @type {number}
     * @memberof ReviewComment
     */
    'dislikes': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewComment
     */
    'current_user_reaction': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReviewComment
     */
    'created': string;
    /**
     * 
     * @type {Array<ReviewComment>}
     * @memberof ReviewComment
     */
    'answers'?: Array<ReviewComment>;
}
/**
 * 
 * @export
 * @interface ReviewCommentInput
 */
export interface ReviewCommentInput {
    /**
     * 
     * @type {string}
     * @memberof ReviewCommentInput
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewCommentInput
     */
    'author_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReviewCommentInput
     */
    'answer_to'?: number | null;
}
/**
 * right_top latitude
 * @export
 * @interface RtLat
 */
export interface RtLat {
}
/**
 * right_top longitude
 * @export
 * @interface RtLon
 */
export interface RtLon {
}
/**
 * 
 * @export
 * @interface SiteOptionsSchema
 */
export interface SiteOptionsSchema {
    /**
     * 
     * @type {number}
     * @memberof SiteOptionsSchema
     */
    'pk': number;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'domain': string;
    /**
     * 
     * @type {number}
     * @memberof SiteOptionsSchema
     */
    'site_id': number;
    /**
     * 
     * @type {number}
     * @memberof SiteOptionsSchema
     */
    'site_domain_id': number;
    /**
     * 
     * @type {Provider}
     * @memberof SiteOptionsSchema
     */
    'provider'?: Provider | null;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'google_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'yandex_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'optimize_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'facebook_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'mail_ru_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'vk_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'yandex_webmaster_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'sessionid': string;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'redirect_domain'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteOptionsSchema
     */
    'site_version_id'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof SiteOptionsSchema
     */
    'config': object;
}
/**
 * 
 * @export
 * @interface SiteSchema
 */
export interface SiteSchema {
    /**
     * 
     * @type {SiteOptionsSchema}
     * @memberof SiteSchema
     */
    'options': SiteOptionsSchema;
    /**
     * 
     * @type {boolean}
     * @memberof SiteSchema
     */
    'not_modified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SiteSchema
     */
    'last_modified'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteSchema
     */
    'redirect'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiteSchema
     */
    'redirect_code'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SiteType = {
    AGGREGATOR: 'aggregator',
    PROVIDER: 'provider'
} as const;

export type SiteType = typeof SiteType[keyof typeof SiteType];


/**
 * 
 * @export
 * @interface StartAuthByPhoneBody
 */
export interface StartAuthByPhoneBody {
    /**
     * 
     * @type {string}
     * @memberof StartAuthByPhoneBody
     */
    'number': string;
    /**
     * 
     * @type {AuthByPhoneStrategy}
     * @memberof StartAuthByPhoneBody
     */
    'strategy'?: AuthByPhoneStrategy;
}


/**
 * 
 * @export
 * @interface SuggestionsCascade
 */
export interface SuggestionsCascade {
    /**
     * 
     * @type {Array<BaseAddressObject>}
     * @memberof SuggestionsCascade
     */
    'regions'?: Array<BaseAddressObject> | null;
    /**
     * 
     * @type {Array<AddressObjectWithParents>}
     * @memberof SuggestionsCascade
     */
    'cities'?: Array<AddressObjectWithParents> | null;
    /**
     * 
     * @type {Array<AddressObjectWithParents>}
     * @memberof SuggestionsCascade
     */
    'streets'?: Array<AddressObjectWithParents> | null;
    /**
     * 
     * @type {Array<House>}
     * @memberof SuggestionsCascade
     */
    'houses'?: Array<House> | null;
}
/**
 * 
 * @export
 * @interface SuggestionsCascadeInput
 */
export interface SuggestionsCascadeInput {
    /**
     * 
     * @type {number}
     * @memberof SuggestionsCascadeInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsCascadeInput
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsCascadeInput
     */
    'region_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsCascadeInput
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsCascadeInput
     */
    'city_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsCascadeInput
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsCascadeInput
     */
    'street_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsCascadeInput
     */
    'house'?: string | null;
}
/**
 * 
 * @export
 * @interface SuggestionsMulti
 */
export interface SuggestionsMulti {
    /**
     * If `street_fias_id` is not specified, then search for streets in `city_fias_id` or `site_city_fias_id` if no streets are found, then also search for cities in region
     * @type {Array<AddressObjectWithParents>}
     * @memberof SuggestionsMulti
     */
    'address_objects'?: Array<AddressObjectWithParents>;
    /**
     * Houses are given in search result when `street_fias_id` is specified
     * @type {Array<House>}
     * @memberof SuggestionsMulti
     */
    'houses'?: Array<House>;
    /**
     * Can scroll results of this query with offset
     * @type {boolean}
     * @memberof SuggestionsMulti
     */
    'can_scroll'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SuggestionsMulti
     */
    'total_items'?: number | null;
}
/**
 * 
 * @export
 * @interface SuggestionsMultiInput
 */
export interface SuggestionsMultiInput {
    /**
     * 
     * @type {string}
     * @memberof SuggestionsMultiInput
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsMultiInput
     */
    'site_city_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsMultiInput
     */
    'city_fias_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestionsMultiInput
     */
    'street_fias_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SuggestionsMultiInput
     */
    'force_houses'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SuggestionsMultiInput
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuggestionsMultiInput
     */
    'offset'?: number | null;
    /**
     * Always collect parent tooltips
     * @type {boolean}
     * @memberof SuggestionsMultiInput
     */
    'tooltips_always'?: boolean;
    /**
     * Move tooltips of streets into house number from regular tooltip
     * @type {boolean}
     * @memberof SuggestionsMultiInput
     */
    'move_house_street_tooltip'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SuggestionsMultiInput
     */
    'provodnoy'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SuggestionsMultiInput
     */
    'site_id'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TargetChoices = {
    CAPTION: 'CAPTION',
    UPPER_LEFT: 'UPPER_LEFT',
    LOWER_LEFT: 'LOWER_LEFT',
    CENTER: 'CENTER'
} as const;

export type TargetChoices = typeof TargetChoices[keyof typeof TargetChoices];


/**
 * 
 * @export
 * @interface Utm
 */
export interface Utm {
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Auth By Uid
         * @param {AuthByUidInput} authByUidInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authByUid: async (authByUidInput: AuthByUidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authByUidInput' is not null or undefined
            assertParamExists('authByUid', 'authByUidInput', authByUidInput)
            const localVarPath = `/auth/by-uid/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authByUidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Auth By Yandex
         * @param {AuthByYandexToken} authByYandexToken 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        authByYandex: async (authByYandexToken: AuthByYandexToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authByYandexToken' is not null or undefined
            assertParamExists('authByYandex', 'authByYandexToken', authByYandexToken)
            const localVarPath = `/auth/yandex/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authByYandexToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check Auth By Phone
         * @param {CheckAuthByPhoneBody} checkAuthByPhoneBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthByPhone: async (checkAuthByPhoneBody: CheckAuthByPhoneBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkAuthByPhoneBody' is not null or undefined
            assertParamExists('checkAuthByPhone', 'checkAuthByPhoneBody', checkAuthByPhoneBody)
            const localVarPath = `/auth/by-phone/check/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkAuthByPhoneBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Current User Info
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCurrentUserInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/current-user-info/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start Auth By Phone
         * @param {StartAuthByPhoneBody} startAuthByPhoneBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startAuthByPhone: async (startAuthByPhoneBody: StartAuthByPhoneBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startAuthByPhoneBody' is not null or undefined
            assertParamExists('startAuthByPhone', 'startAuthByPhoneBody', startAuthByPhoneBody)
            const localVarPath = `/auth/by-phone/start/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startAuthByPhoneBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Auth By Uid
         * @param {AuthByUidInput} authByUidInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authByUid(authByUidInput: AuthByUidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserInfoResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authByUid(authByUidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authByUid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Auth By Yandex
         * @param {AuthByYandexToken} authByYandexToken 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async authByYandex(authByYandexToken: AuthByYandexToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserInfoResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authByYandex(authByYandexToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authByYandex']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check Auth By Phone
         * @param {CheckAuthByPhoneBody} checkAuthByPhoneBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAuthByPhone(checkAuthByPhoneBody: CheckAuthByPhoneBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthByPhoneResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAuthByPhone(checkAuthByPhoneBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.checkAuthByPhone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Current User Info
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCurrentUserInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserInfoResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getCurrentUserInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Start Auth By Phone
         * @param {StartAuthByPhoneBody} startAuthByPhoneBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startAuthByPhone(startAuthByPhoneBody: StartAuthByPhoneBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthByPhoneResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startAuthByPhone(startAuthByPhoneBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.startAuthByPhone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Auth By Uid
         * @param {AuthApiAuthByUidRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authByUid(requestParameters: AuthApiAuthByUidRequest, options?: RawAxiosRequestConfig): AxiosPromise<CurrentUserInfoResp> {
            return localVarFp.authByUid(requestParameters.authByUidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Auth By Yandex
         * @param {AuthApiAuthByYandexRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        authByYandex(requestParameters: AuthApiAuthByYandexRequest, options?: RawAxiosRequestConfig): AxiosPromise<CurrentUserInfoResp> {
            return localVarFp.authByYandex(requestParameters.authByYandexToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check Auth By Phone
         * @param {AuthApiCheckAuthByPhoneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthByPhone(requestParameters: AuthApiCheckAuthByPhoneRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuthByPhoneResp> {
            return localVarFp.checkAuthByPhone(requestParameters.checkAuthByPhoneBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Current User Info
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCurrentUserInfo(options?: RawAxiosRequestConfig): AxiosPromise<CurrentUserInfoResp> {
            return localVarFp.getCurrentUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start Auth By Phone
         * @param {AuthApiStartAuthByPhoneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startAuthByPhone(requestParameters: AuthApiStartAuthByPhoneRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuthByPhoneResp> {
            return localVarFp.startAuthByPhone(requestParameters.startAuthByPhoneBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authByUid operation in AuthApi.
 * @export
 * @interface AuthApiAuthByUidRequest
 */
export interface AuthApiAuthByUidRequest {
    /**
     * 
     * @type {AuthByUidInput}
     * @memberof AuthApiAuthByUid
     */
    readonly authByUidInput: AuthByUidInput
}

/**
 * Request parameters for authByYandex operation in AuthApi.
 * @export
 * @interface AuthApiAuthByYandexRequest
 */
export interface AuthApiAuthByYandexRequest {
    /**
     * 
     * @type {AuthByYandexToken}
     * @memberof AuthApiAuthByYandex
     */
    readonly authByYandexToken: AuthByYandexToken
}

/**
 * Request parameters for checkAuthByPhone operation in AuthApi.
 * @export
 * @interface AuthApiCheckAuthByPhoneRequest
 */
export interface AuthApiCheckAuthByPhoneRequest {
    /**
     * 
     * @type {CheckAuthByPhoneBody}
     * @memberof AuthApiCheckAuthByPhone
     */
    readonly checkAuthByPhoneBody: CheckAuthByPhoneBody
}

/**
 * Request parameters for startAuthByPhone operation in AuthApi.
 * @export
 * @interface AuthApiStartAuthByPhoneRequest
 */
export interface AuthApiStartAuthByPhoneRequest {
    /**
     * 
     * @type {StartAuthByPhoneBody}
     * @memberof AuthApiStartAuthByPhone
     */
    readonly startAuthByPhoneBody: StartAuthByPhoneBody
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Auth By Uid
     * @param {AuthApiAuthByUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authByUid(requestParameters: AuthApiAuthByUidRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authByUid(requestParameters.authByUidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Auth By Yandex
     * @param {AuthApiAuthByYandexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authByYandex(requestParameters: AuthApiAuthByYandexRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authByYandex(requestParameters.authByYandexToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check Auth By Phone
     * @param {AuthApiCheckAuthByPhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public checkAuthByPhone(requestParameters: AuthApiCheckAuthByPhoneRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).checkAuthByPhone(requestParameters.checkAuthByPhoneBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Current User Info
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getCurrentUserInfo(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getCurrentUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start Auth By Phone
     * @param {AuthApiStartAuthByPhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public startAuthByPhone(requestParameters: AuthApiStartAuthByPhoneRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).startAuthByPhone(requestParameters.startAuthByPhoneBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BlogApi - axios parameter creator
 * @export
 */
export const BlogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Авторы в блоге
         * @summary Get Blog Authors
         * @param {number | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogAuthors: async (id?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/blog/authors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Blog Main Page
         * @param {number | null} [siteId] 
         * @param {number} [popularLimit] 
         * @param {number} [selectionLimit] 
         * @param {number} [firstPageLimit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getBlogMainPage: async (siteId?: number | null, popularLimit?: number, selectionLimit?: number, firstPageLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/blog/main/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (popularLimit !== undefined) {
                localVarQueryParameter['popular_limit'] = popularLimit;
            }

            if (selectionLimit !== undefined) {
                localVarQueryParameter['selection_limit'] = selectionLimit;
            }

            if (firstPageLimit !== undefined) {
                localVarQueryParameter['first_page_limit'] = firstPageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Блоки для замены:<br/>%%address_check%% - блок проверки адреса<br/>%%tariffs_list%% - список тарифов из поля tariffs_list
         * @summary Get Blog Post
         * @param {string} slug 
         * @param {string | null} [fiasId] DEPRECATED
         * @param {number | null} [siteId] 
         * @param {GetBlogPostDeviceEnum} [device] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogPost: async (slug: string, fiasId?: string | null, siteId?: number | null, device?: GetBlogPostDeviceEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getBlogPost', 'slug', slug)
            const localVarPath = `/blog/posts/{slug}/`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fiasId !== undefined) {
                localVarQueryParameter['fias_id'] = fiasId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (device !== undefined) {
                localVarQueryParameter['device'] = device;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Blog Posts Preview
         * @param {number | null} [siteId] 
         * @param {string | null} [tagSlug] Только статьи с этим тэгом
         * @param {number | null} [authorId] Только статьи автора
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {GetBlogPostsPreviewDeviceEnum} [device] 
         * @param {string | null} [forPageUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogPostsPreview: async (siteId?: number | null, tagSlug?: string | null, authorId?: number | null, offset?: number, limit?: number, device?: GetBlogPostsPreviewDeviceEnum, forPageUrl?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/blog/posts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (tagSlug !== undefined) {
                localVarQueryParameter['tag_slug'] = tagSlug;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['author_id'] = authorId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (device !== undefined) {
                localVarQueryParameter['device'] = device;
            }

            if (forPageUrl !== undefined) {
                localVarQueryParameter['for_page_url'] = forPageUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Blog Tags
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogTags: async (siteId?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/blog/tags/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlogApi - functional programming interface
 * @export
 */
export const BlogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlogApiAxiosParamCreator(configuration)
    return {
        /**
         * Авторы в блоге
         * @summary Get Blog Authors
         * @param {number | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlogAuthors(id?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlogAuthor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlogAuthors(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlogApi.getBlogAuthors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Blog Main Page
         * @param {number | null} [siteId] 
         * @param {number} [popularLimit] 
         * @param {number} [selectionLimit] 
         * @param {number} [firstPageLimit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getBlogMainPage(siteId?: number | null, popularLimit?: number, selectionLimit?: number, firstPageLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogMainPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlogMainPage(siteId, popularLimit, selectionLimit, firstPageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlogApi.getBlogMainPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Блоки для замены:<br/>%%address_check%% - блок проверки адреса<br/>%%tariffs_list%% - список тарифов из поля tariffs_list
         * @summary Get Blog Post
         * @param {string} slug 
         * @param {string | null} [fiasId] DEPRECATED
         * @param {number | null} [siteId] 
         * @param {GetBlogPostDeviceEnum} [device] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlogPost(slug: string, fiasId?: string | null, siteId?: number | null, device?: GetBlogPostDeviceEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPost>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlogPost(slug, fiasId, siteId, device, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlogApi.getBlogPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Blog Posts Preview
         * @param {number | null} [siteId] 
         * @param {string | null} [tagSlug] Только статьи с этим тэгом
         * @param {number | null} [authorId] Только статьи автора
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {GetBlogPostsPreviewDeviceEnum} [device] 
         * @param {string | null} [forPageUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlogPostsPreview(siteId?: number | null, tagSlug?: string | null, authorId?: number | null, offset?: number, limit?: number, device?: GetBlogPostsPreviewDeviceEnum, forPageUrl?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostPreviewPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlogPostsPreview(siteId, tagSlug, authorId, offset, limit, device, forPageUrl, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlogApi.getBlogPostsPreview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Blog Tags
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlogTags(siteId?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlogTag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlogTags(siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlogApi.getBlogTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BlogApi - factory interface
 * @export
 */
export const BlogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlogApiFp(configuration)
    return {
        /**
         * Авторы в блоге
         * @summary Get Blog Authors
         * @param {BlogApiGetBlogAuthorsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogAuthors(requestParameters: BlogApiGetBlogAuthorsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<BlogAuthor>> {
            return localVarFp.getBlogAuthors(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Blog Main Page
         * @param {BlogApiGetBlogMainPageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getBlogMainPage(requestParameters: BlogApiGetBlogMainPageRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BlogMainPage> {
            return localVarFp.getBlogMainPage(requestParameters.siteId, requestParameters.popularLimit, requestParameters.selectionLimit, requestParameters.firstPageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * Блоки для замены:<br/>%%address_check%% - блок проверки адреса<br/>%%tariffs_list%% - список тарифов из поля tariffs_list
         * @summary Get Blog Post
         * @param {BlogApiGetBlogPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogPost(requestParameters: BlogApiGetBlogPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<BlogPost> {
            return localVarFp.getBlogPost(requestParameters.slug, requestParameters.fiasId, requestParameters.siteId, requestParameters.device, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Blog Posts Preview
         * @param {BlogApiGetBlogPostsPreviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogPostsPreview(requestParameters: BlogApiGetBlogPostsPreviewRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BlogPostPreviewPaginated> {
            return localVarFp.getBlogPostsPreview(requestParameters.siteId, requestParameters.tagSlug, requestParameters.authorId, requestParameters.offset, requestParameters.limit, requestParameters.device, requestParameters.forPageUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Blog Tags
         * @param {BlogApiGetBlogTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogTags(requestParameters: BlogApiGetBlogTagsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<BlogTag>> {
            return localVarFp.getBlogTags(requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getBlogAuthors operation in BlogApi.
 * @export
 * @interface BlogApiGetBlogAuthorsRequest
 */
export interface BlogApiGetBlogAuthorsRequest {
    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogAuthors
     */
    readonly id?: number | null
}

/**
 * Request parameters for getBlogMainPage operation in BlogApi.
 * @export
 * @interface BlogApiGetBlogMainPageRequest
 */
export interface BlogApiGetBlogMainPageRequest {
    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogMainPage
     */
    readonly siteId?: number | null

    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogMainPage
     */
    readonly popularLimit?: number

    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogMainPage
     */
    readonly selectionLimit?: number

    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogMainPage
     */
    readonly firstPageLimit?: number
}

/**
 * Request parameters for getBlogPost operation in BlogApi.
 * @export
 * @interface BlogApiGetBlogPostRequest
 */
export interface BlogApiGetBlogPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BlogApiGetBlogPost
     */
    readonly slug: string

    /**
     * DEPRECATED
     * @type {string}
     * @memberof BlogApiGetBlogPost
     */
    readonly fiasId?: string | null

    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogPost
     */
    readonly siteId?: number | null

    /**
     * 
     * @type {string}
     * @memberof BlogApiGetBlogPost
     */
    readonly device?: GetBlogPostDeviceEnum
}

/**
 * Request parameters for getBlogPostsPreview operation in BlogApi.
 * @export
 * @interface BlogApiGetBlogPostsPreviewRequest
 */
export interface BlogApiGetBlogPostsPreviewRequest {
    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogPostsPreview
     */
    readonly siteId?: number | null

    /**
     * Только статьи с этим тэгом
     * @type {string}
     * @memberof BlogApiGetBlogPostsPreview
     */
    readonly tagSlug?: string | null

    /**
     * Только статьи автора
     * @type {number}
     * @memberof BlogApiGetBlogPostsPreview
     */
    readonly authorId?: number | null

    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogPostsPreview
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogPostsPreview
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof BlogApiGetBlogPostsPreview
     */
    readonly device?: GetBlogPostsPreviewDeviceEnum

    /**
     * 
     * @type {string}
     * @memberof BlogApiGetBlogPostsPreview
     */
    readonly forPageUrl?: string | null
}

/**
 * Request parameters for getBlogTags operation in BlogApi.
 * @export
 * @interface BlogApiGetBlogTagsRequest
 */
export interface BlogApiGetBlogTagsRequest {
    /**
     * 
     * @type {number}
     * @memberof BlogApiGetBlogTags
     */
    readonly siteId?: number | null
}

/**
 * BlogApi - object-oriented interface
 * @export
 * @class BlogApi
 * @extends {BaseAPI}
 */
export class BlogApi extends BaseAPI {
    /**
     * Авторы в блоге
     * @summary Get Blog Authors
     * @param {BlogApiGetBlogAuthorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogApi
     */
    public getBlogAuthors(requestParameters: BlogApiGetBlogAuthorsRequest = {}, options?: RawAxiosRequestConfig) {
        return BlogApiFp(this.configuration).getBlogAuthors(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Blog Main Page
     * @param {BlogApiGetBlogMainPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BlogApi
     */
    public getBlogMainPage(requestParameters: BlogApiGetBlogMainPageRequest = {}, options?: RawAxiosRequestConfig) {
        return BlogApiFp(this.configuration).getBlogMainPage(requestParameters.siteId, requestParameters.popularLimit, requestParameters.selectionLimit, requestParameters.firstPageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Блоки для замены:<br/>%%address_check%% - блок проверки адреса<br/>%%tariffs_list%% - список тарифов из поля tariffs_list
     * @summary Get Blog Post
     * @param {BlogApiGetBlogPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogApi
     */
    public getBlogPost(requestParameters: BlogApiGetBlogPostRequest, options?: RawAxiosRequestConfig) {
        return BlogApiFp(this.configuration).getBlogPost(requestParameters.slug, requestParameters.fiasId, requestParameters.siteId, requestParameters.device, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Blog Posts Preview
     * @param {BlogApiGetBlogPostsPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogApi
     */
    public getBlogPostsPreview(requestParameters: BlogApiGetBlogPostsPreviewRequest = {}, options?: RawAxiosRequestConfig) {
        return BlogApiFp(this.configuration).getBlogPostsPreview(requestParameters.siteId, requestParameters.tagSlug, requestParameters.authorId, requestParameters.offset, requestParameters.limit, requestParameters.device, requestParameters.forPageUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Blog Tags
     * @param {BlogApiGetBlogTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogApi
     */
    public getBlogTags(requestParameters: BlogApiGetBlogTagsRequest = {}, options?: RawAxiosRequestConfig) {
        return BlogApiFp(this.configuration).getBlogTags(requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetBlogPostDeviceEnum = {
    DESKTOP: 'DESKTOP',
    TABLET: 'TABLET',
    MOBILE: 'MOBILE'
} as const;
export type GetBlogPostDeviceEnum = typeof GetBlogPostDeviceEnum[keyof typeof GetBlogPostDeviceEnum];
/**
 * @export
 */
export const GetBlogPostsPreviewDeviceEnum = {
    DESKTOP: 'DESKTOP',
    TABLET: 'TABLET',
    MOBILE: 'MOBILE'
} as const;
export type GetBlogPostsPreviewDeviceEnum = typeof GetBlogPostsPreviewDeviceEnum[keyof typeof GetBlogPostsPreviewDeviceEnum];


/**
 * CallsApi - axios parameter creator
 * @export
 */
export const CallsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Calls General
         * @param {number | null} [providerId] 
         * @param {string | null} [providerSlug] 
         * @param {string | null} [domain] 
         * @param {string | null} [fiasId] fias_id города, для получения правильного номера тех. поддержки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallsGeneral: async (providerId?: number | null, providerSlug?: string | null, domain?: string | null, fiasId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calls/general/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (providerSlug !== undefined) {
                localVarQueryParameter['provider_slug'] = providerSlug;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }

            if (fiasId !== undefined) {
                localVarQueryParameter['fias_id'] = fiasId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * `availability` controls how we dispaly phone numbers: AVAILABLE - Display phone numbers right away, BUSY - Display phone numbers somewhere at the bottom, CLOSED - Display \'call back\' form instead of phone numbers
         * @summary Get Calls Status
         * @param {string | null} [testCurrentTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallsStatus: async (testCurrentTime?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calls/status/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (testCurrentTime !== undefined) {
                localVarQueryParameter['test_current_time'] = (testCurrentTime as any instanceof Date) ?
                    (testCurrentTime as any).toISOString() :
                    testCurrentTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Time Slots
         * @param {number | null} timezoneOffset &#x60;new Date().getTimezoneOffset()&#x60; For example &#x60;-180&#x60; for MSK
         * @param {string | null} [testCurrentTime] 
         * @param {SiteType | null} [siteType] Site type that asks
         * @param {AvailabilityChoices | null} [availability] Availability of tariff on given by client address
         * @param {FunnelChoices | null} [funnel] Place that slots were asked from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSlots: async (timezoneOffset: number | null, testCurrentTime?: string | null, siteType?: SiteType | null, availability?: AvailabilityChoices | null, funnel?: FunnelChoices | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timezoneOffset' is not null or undefined
            assertParamExists('getTimeSlots', 'timezoneOffset', timezoneOffset)
            const localVarPath = `/calls/time-slots/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timezoneOffset !== undefined) {
                localVarQueryParameter['timezone_offset'] = timezoneOffset;
            }

            if (testCurrentTime !== undefined) {
                localVarQueryParameter['test_current_time'] = (testCurrentTime as any instanceof Date) ?
                    (testCurrentTime as any).toISOString() :
                    testCurrentTime;
            }

            if (siteType !== undefined) {
                localVarQueryParameter['site_type'] = siteType;
            }

            if (availability !== undefined) {
                localVarQueryParameter['availability'] = availability;
            }

            if (funnel !== undefined) {
                localVarQueryParameter['funnel'] = funnel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * в первом запросе допустимо передавать только обязательные параметры, в последующих обязательно google_id
         * @summary Post Call Tracking
         * @param {CallTrackingRequest} callTrackingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCallTracking: async (callTrackingRequest: CallTrackingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callTrackingRequest' is not null or undefined
            assertParamExists('postCallTracking', 'callTrackingRequest', callTrackingRequest)
            const localVarPath = `/calls/tracking/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(callTrackingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CallsApi - functional programming interface
 * @export
 */
export const CallsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CallsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Calls General
         * @param {number | null} [providerId] 
         * @param {string | null} [providerSlug] 
         * @param {string | null} [domain] 
         * @param {string | null} [fiasId] fias_id города, для получения правильного номера тех. поддержки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCallsGeneral(providerId?: number | null, providerSlug?: string | null, domain?: string | null, fiasId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallsPhoneNumbers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCallsGeneral(providerId, providerSlug, domain, fiasId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CallsApi.getCallsGeneral']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * `availability` controls how we dispaly phone numbers: AVAILABLE - Display phone numbers right away, BUSY - Display phone numbers somewhere at the bottom, CLOSED - Display \'call back\' form instead of phone numbers
         * @summary Get Calls Status
         * @param {string | null} [testCurrentTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCallsStatus(testCurrentTime?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallsAvailabilityStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCallsStatus(testCurrentTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CallsApi.getCallsStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Time Slots
         * @param {number | null} timezoneOffset &#x60;new Date().getTimezoneOffset()&#x60; For example &#x60;-180&#x60; for MSK
         * @param {string | null} [testCurrentTime] 
         * @param {SiteType | null} [siteType] Site type that asks
         * @param {AvailabilityChoices | null} [availability] Availability of tariff on given by client address
         * @param {FunnelChoices | null} [funnel] Place that slots were asked from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeSlots(timezoneOffset: number | null, testCurrentTime?: string | null, siteType?: SiteType | null, availability?: AvailabilityChoices | null, funnel?: FunnelChoices | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallsTimeSlots>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeSlots(timezoneOffset, testCurrentTime, siteType, availability, funnel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CallsApi.getTimeSlots']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * в первом запросе допустимо передавать только обязательные параметры, в последующих обязательно google_id
         * @summary Post Call Tracking
         * @param {CallTrackingRequest} callTrackingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCallTracking(callTrackingRequest: CallTrackingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallTracking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCallTracking(callTrackingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CallsApi.postCallTracking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CallsApi - factory interface
 * @export
 */
export const CallsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CallsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Calls General
         * @param {CallsApiGetCallsGeneralRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallsGeneral(requestParameters: CallsApiGetCallsGeneralRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CallsPhoneNumbers> {
            return localVarFp.getCallsGeneral(requestParameters.providerId, requestParameters.providerSlug, requestParameters.domain, requestParameters.fiasId, options).then((request) => request(axios, basePath));
        },
        /**
         * `availability` controls how we dispaly phone numbers: AVAILABLE - Display phone numbers right away, BUSY - Display phone numbers somewhere at the bottom, CLOSED - Display \'call back\' form instead of phone numbers
         * @summary Get Calls Status
         * @param {CallsApiGetCallsStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallsStatus(requestParameters: CallsApiGetCallsStatusRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CallsAvailabilityStatus> {
            return localVarFp.getCallsStatus(requestParameters.testCurrentTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Time Slots
         * @param {CallsApiGetTimeSlotsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSlots(requestParameters: CallsApiGetTimeSlotsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CallsTimeSlots> {
            return localVarFp.getTimeSlots(requestParameters.timezoneOffset, requestParameters.testCurrentTime, requestParameters.siteType, requestParameters.availability, requestParameters.funnel, options).then((request) => request(axios, basePath));
        },
        /**
         * в первом запросе допустимо передавать только обязательные параметры, в последующих обязательно google_id
         * @summary Post Call Tracking
         * @param {CallsApiPostCallTrackingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCallTracking(requestParameters: CallsApiPostCallTrackingRequest, options?: RawAxiosRequestConfig): AxiosPromise<CallTracking> {
            return localVarFp.postCallTracking(requestParameters.callTrackingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCallsGeneral operation in CallsApi.
 * @export
 * @interface CallsApiGetCallsGeneralRequest
 */
export interface CallsApiGetCallsGeneralRequest {
    /**
     * 
     * @type {number}
     * @memberof CallsApiGetCallsGeneral
     */
    readonly providerId?: number | null

    /**
     * 
     * @type {string}
     * @memberof CallsApiGetCallsGeneral
     */
    readonly providerSlug?: string | null

    /**
     * 
     * @type {string}
     * @memberof CallsApiGetCallsGeneral
     */
    readonly domain?: string | null

    /**
     * fias_id города, для получения правильного номера тех. поддержки
     * @type {string}
     * @memberof CallsApiGetCallsGeneral
     */
    readonly fiasId?: string | null
}

/**
 * Request parameters for getCallsStatus operation in CallsApi.
 * @export
 * @interface CallsApiGetCallsStatusRequest
 */
export interface CallsApiGetCallsStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof CallsApiGetCallsStatus
     */
    readonly testCurrentTime?: string | null
}

/**
 * Request parameters for getTimeSlots operation in CallsApi.
 * @export
 * @interface CallsApiGetTimeSlotsRequest
 */
export interface CallsApiGetTimeSlotsRequest {
    /**
     * &#x60;new Date().getTimezoneOffset()&#x60; For example &#x60;-180&#x60; for MSK
     * @type {number}
     * @memberof CallsApiGetTimeSlots
     */
    readonly timezoneOffset: number | null

    /**
     * 
     * @type {string}
     * @memberof CallsApiGetTimeSlots
     */
    readonly testCurrentTime?: string | null

    /**
     * Site type that asks
     * @type {SiteType}
     * @memberof CallsApiGetTimeSlots
     */
    readonly siteType?: SiteType | null

    /**
     * Availability of tariff on given by client address
     * @type {AvailabilityChoices}
     * @memberof CallsApiGetTimeSlots
     */
    readonly availability?: AvailabilityChoices | null

    /**
     * Place that slots were asked from
     * @type {FunnelChoices}
     * @memberof CallsApiGetTimeSlots
     */
    readonly funnel?: FunnelChoices | null
}

/**
 * Request parameters for postCallTracking operation in CallsApi.
 * @export
 * @interface CallsApiPostCallTrackingRequest
 */
export interface CallsApiPostCallTrackingRequest {
    /**
     * 
     * @type {CallTrackingRequest}
     * @memberof CallsApiPostCallTracking
     */
    readonly callTrackingRequest: CallTrackingRequest
}

/**
 * CallsApi - object-oriented interface
 * @export
 * @class CallsApi
 * @extends {BaseAPI}
 */
export class CallsApi extends BaseAPI {
    /**
     * 
     * @summary Get Calls General
     * @param {CallsApiGetCallsGeneralRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallsApi
     */
    public getCallsGeneral(requestParameters: CallsApiGetCallsGeneralRequest = {}, options?: RawAxiosRequestConfig) {
        return CallsApiFp(this.configuration).getCallsGeneral(requestParameters.providerId, requestParameters.providerSlug, requestParameters.domain, requestParameters.fiasId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * `availability` controls how we dispaly phone numbers: AVAILABLE - Display phone numbers right away, BUSY - Display phone numbers somewhere at the bottom, CLOSED - Display \'call back\' form instead of phone numbers
     * @summary Get Calls Status
     * @param {CallsApiGetCallsStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallsApi
     */
    public getCallsStatus(requestParameters: CallsApiGetCallsStatusRequest = {}, options?: RawAxiosRequestConfig) {
        return CallsApiFp(this.configuration).getCallsStatus(requestParameters.testCurrentTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Time Slots
     * @param {CallsApiGetTimeSlotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallsApi
     */
    public getTimeSlots(requestParameters: CallsApiGetTimeSlotsRequest, options?: RawAxiosRequestConfig) {
        return CallsApiFp(this.configuration).getTimeSlots(requestParameters.timezoneOffset, requestParameters.testCurrentTime, requestParameters.siteType, requestParameters.availability, requestParameters.funnel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * в первом запросе допустимо передавать только обязательные параметры, в последующих обязательно google_id
     * @summary Post Call Tracking
     * @param {CallsApiPostCallTrackingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallsApi
     */
    public postCallTracking(requestParameters: CallsApiPostCallTrackingRequest, options?: RawAxiosRequestConfig) {
        return CallsApiFp(this.configuration).postCallTracking(requestParameters.callTrackingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get seo parameters for page at given url. Pass 0 as provider_id for aggregator.
         * @summary Get Provider Page
         * @param {number} providerId 
         * @param {string} url 
         * @param {number | null} [siteId] ID сайта
         * @param {string | null} [domain] Поддомен
         * @param {string | null} [fiasId] fias текущего города
         * @param {string | null} [forProvider] Для спец страниц разделенных по провайдеру. id или slug
         * @param {boolean | null} [randomize] Рандомизировать порядок блоков по сиду fias_id и урла страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPage: async (providerId: number, url: string, siteId?: number | null, domain?: string | null, fiasId?: string | null, forProvider?: string | null, randomize?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderPage', 'providerId', providerId)
            // verify required parameter 'url' is not null or undefined
            assertParamExists('getProviderPage', 'url', url)
            const localVarPath = `/providers/{provider_id}/pages/{url}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"url"}}`, encodeURIComponent(String(url)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }

            if (fiasId !== undefined) {
                localVarQueryParameter['fias_id'] = fiasId;
            }

            if (forProvider !== undefined) {
                localVarQueryParameter['for_provider'] = forProvider;
            }

            if (randomize !== undefined) {
                localVarQueryParameter['randomize'] = randomize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration)
    return {
        /**
         * Get seo parameters for page at given url. Pass 0 as provider_id for aggregator.
         * @summary Get Provider Page
         * @param {number} providerId 
         * @param {string} url 
         * @param {number | null} [siteId] ID сайта
         * @param {string | null} [domain] Поддомен
         * @param {string | null} [fiasId] fias текущего города
         * @param {string | null} [forProvider] Для спец страниц разделенных по провайдеру. id или slug
         * @param {boolean | null} [randomize] Рандомизировать порядок блоков по сиду fias_id и урла страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderPage(providerId: number, url: string, siteId?: number | null, domain?: string | null, fiasId?: string | null, forProvider?: string | null, randomize?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderPage(providerId, url, siteId, domain, fiasId, forProvider, randomize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.getProviderPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentApiFp(configuration)
    return {
        /**
         * Get seo parameters for page at given url. Pass 0 as provider_id for aggregator.
         * @summary Get Provider Page
         * @param {ContentApiGetProviderPageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPage(requestParameters: ContentApiGetProviderPageRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContentPage> {
            return localVarFp.getProviderPage(requestParameters.providerId, requestParameters.url, requestParameters.siteId, requestParameters.domain, requestParameters.fiasId, requestParameters.forProvider, requestParameters.randomize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProviderPage operation in ContentApi.
 * @export
 * @interface ContentApiGetProviderPageRequest
 */
export interface ContentApiGetProviderPageRequest {
    /**
     * 
     * @type {number}
     * @memberof ContentApiGetProviderPage
     */
    readonly providerId: number

    /**
     * 
     * @type {string}
     * @memberof ContentApiGetProviderPage
     */
    readonly url: string

    /**
     * ID сайта
     * @type {number}
     * @memberof ContentApiGetProviderPage
     */
    readonly siteId?: number | null

    /**
     * Поддомен
     * @type {string}
     * @memberof ContentApiGetProviderPage
     */
    readonly domain?: string | null

    /**
     * fias текущего города
     * @type {string}
     * @memberof ContentApiGetProviderPage
     */
    readonly fiasId?: string | null

    /**
     * Для спец страниц разделенных по провайдеру. id или slug
     * @type {string}
     * @memberof ContentApiGetProviderPage
     */
    readonly forProvider?: string | null

    /**
     * Рандомизировать порядок блоков по сиду fias_id и урла страницы
     * @type {boolean}
     * @memberof ContentApiGetProviderPage
     */
    readonly randomize?: boolean | null
}

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * Get seo parameters for page at given url. Pass 0 as provider_id for aggregator.
     * @summary Get Provider Page
     * @param {ContentApiGetProviderPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getProviderPage(requestParameters: ContentApiGetProviderPageRequest, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).getProviderPage(requestParameters.providerId, requestParameters.url, requestParameters.siteId, requestParameters.domain, requestParameters.fiasId, requestParameters.forProvider, requestParameters.randomize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CoreApi - axios parameter creator
 * @export
 */
export const CoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Redirect Url
         * @param {string} url Url to be tested for redirect. May contain anything, including query params, etc. Preferably with domain, because of redirects for specific domain.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectUrl: async (url: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('getRedirectUrl', 'url', url)
            const localVarPath = `/core/redirect/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Meta Debug
         * @param {boolean | null} [exc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaDebug: async (exc?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/_meta_debug/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (exc !== undefined) {
                localVarQueryParameter['exc'] = exc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Log Entry
         * @param {LogEntrySchema} logEntrySchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogEntry: async (logEntrySchema: LogEntrySchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logEntrySchema' is not null or undefined
            assertParamExists('postLogEntry', 'logEntrySchema', logEntrySchema)
            const localVarPath = `/core/logs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logEntrySchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Don\'t use, used to include types in schema
         * @summary  Schema Type Includes
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        schemaTypeIncludes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/_include/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoreApi - functional programming interface
 * @export
 */
export const CoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Redirect Url
         * @param {string} url Url to be tested for redirect. May contain anything, including query params, etc. Preferably with domain, because of redirects for specific domain.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedirectUrl(url: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedirectUrl(url, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getRedirectUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Meta Debug
         * @param {boolean | null} [exc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaDebug(exc?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaDebug(exc, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.metaDebug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post Log Entry
         * @param {LogEntrySchema} logEntrySchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogEntry(logEntrySchema: LogEntrySchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogEntry(logEntrySchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.postLogEntry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Don\'t use, used to include types in schema
         * @summary  Schema Type Includes
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async schemaTypeIncludes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schemaTypeIncludes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.schemaTypeIncludes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CoreApi - factory interface
 * @export
 */
export const CoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoreApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Redirect Url
         * @param {CoreApiGetRedirectUrlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectUrl(requestParameters: CoreApiGetRedirectUrlRequest, options?: RawAxiosRequestConfig): AxiosPromise<RedirectResponse> {
            return localVarFp.getRedirectUrl(requestParameters.url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Meta Debug
         * @param {CoreApiMetaDebugRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaDebug(requestParameters: CoreApiMetaDebugRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.metaDebug(requestParameters.exc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Log Entry
         * @param {CoreApiPostLogEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogEntry(requestParameters: CoreApiPostLogEntryRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.postLogEntry(requestParameters.logEntrySchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Don\'t use, used to include types in schema
         * @summary  Schema Type Includes
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        schemaTypeIncludes(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.schemaTypeIncludes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRedirectUrl operation in CoreApi.
 * @export
 * @interface CoreApiGetRedirectUrlRequest
 */
export interface CoreApiGetRedirectUrlRequest {
    /**
     * Url to be tested for redirect. May contain anything, including query params, etc. Preferably with domain, because of redirects for specific domain.
     * @type {string}
     * @memberof CoreApiGetRedirectUrl
     */
    readonly url: string
}

/**
 * Request parameters for metaDebug operation in CoreApi.
 * @export
 * @interface CoreApiMetaDebugRequest
 */
export interface CoreApiMetaDebugRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CoreApiMetaDebug
     */
    readonly exc?: boolean | null
}

/**
 * Request parameters for postLogEntry operation in CoreApi.
 * @export
 * @interface CoreApiPostLogEntryRequest
 */
export interface CoreApiPostLogEntryRequest {
    /**
     * 
     * @type {LogEntrySchema}
     * @memberof CoreApiPostLogEntry
     */
    readonly logEntrySchema: LogEntrySchema
}

/**
 * CoreApi - object-oriented interface
 * @export
 * @class CoreApi
 * @extends {BaseAPI}
 */
export class CoreApi extends BaseAPI {
    /**
     * 
     * @summary Get Redirect Url
     * @param {CoreApiGetRedirectUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getRedirectUrl(requestParameters: CoreApiGetRedirectUrlRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getRedirectUrl(requestParameters.url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Meta Debug
     * @param {CoreApiMetaDebugRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public metaDebug(requestParameters: CoreApiMetaDebugRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).metaDebug(requestParameters.exc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Log Entry
     * @param {CoreApiPostLogEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public postLogEntry(requestParameters: CoreApiPostLogEntryRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).postLogEntry(requestParameters.logEntrySchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Don\'t use, used to include types in schema
     * @summary  Schema Type Includes
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public schemaTypeIncludes(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).schemaTypeIncludes(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InteractionsApi - axios parameter creator
 * @export
 */
export const InteractionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Start user interaction to be updated later with stages
         * @summary Create interaction, response is interaction id to update
         * @param {InteractionCreate} interactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInteraction: async (interactionCreate: InteractionCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interactionCreate' is not null or undefined
            assertParamExists('createInteraction', 'interactionCreate', interactionCreate)
            const localVarPath = `/interactions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interactionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate report by provided token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInteractionReport: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('generateInteractionReport', 'token', token)
            const localVarPath = `/interactions/report/{token}/`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Career Form
         * @param {CareerForm} body 
         * @param {File | null} [resume] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCareerForm: async (body: CareerForm, resume?: File | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postCareerForm', 'body', body)
            const localVarPath = `/interactions/career_form/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (resume !== undefined) { 
                localVarFormParams.append('resume', resume as any);
            }
    
            if (body !== undefined) { 
                localVarFormParams.append('body', new Blob([JSON.stringify(body)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Should be sent separately at each stage to allow recording escaped users
         * @summary Update interaction with new stages
         * @param {number} interactionId 
         * @param {InteractionUpdate} interactionUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInteraction: async (interactionId: number, interactionUpdate: InteractionUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interactionId' is not null or undefined
            assertParamExists('updateInteraction', 'interactionId', interactionId)
            // verify required parameter 'interactionUpdate' is not null or undefined
            assertParamExists('updateInteraction', 'interactionUpdate', interactionUpdate)
            const localVarPath = `/interactions/{interaction_id}/`
                .replace(`{${"interaction_id"}}`, encodeURIComponent(String(interactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interactionUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InteractionsApi - functional programming interface
 * @export
 */
export const InteractionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InteractionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Start user interaction to be updated later with stages
         * @summary Create interaction, response is interaction id to update
         * @param {InteractionCreate} interactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInteraction(interactionCreate: InteractionCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInteraction(interactionCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InteractionsApi.createInteraction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generate report by provided token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateInteractionReport(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateInteractionReport(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InteractionsApi.generateInteractionReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post Career Form
         * @param {CareerForm} body 
         * @param {File | null} [resume] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCareerForm(body: CareerForm, resume?: File | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCareerForm(body, resume, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InteractionsApi.postCareerForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Should be sent separately at each stage to allow recording escaped users
         * @summary Update interaction with new stages
         * @param {number} interactionId 
         * @param {InteractionUpdate} interactionUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInteraction(interactionId: number, interactionUpdate: InteractionUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInteraction(interactionId, interactionUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InteractionsApi.updateInteraction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InteractionsApi - factory interface
 * @export
 */
export const InteractionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InteractionsApiFp(configuration)
    return {
        /**
         * Start user interaction to be updated later with stages
         * @summary Create interaction, response is interaction id to update
         * @param {InteractionsApiCreateInteractionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInteraction(requestParameters: InteractionsApiCreateInteractionRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createInteraction(requestParameters.interactionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate report by provided token
         * @param {InteractionsApiGenerateInteractionReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInteractionReport(requestParameters: InteractionsApiGenerateInteractionReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.generateInteractionReport(requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Career Form
         * @param {InteractionsApiPostCareerFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCareerForm(requestParameters: InteractionsApiPostCareerFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postCareerForm(requestParameters.body, requestParameters.resume, options).then((request) => request(axios, basePath));
        },
        /**
         * Should be sent separately at each stage to allow recording escaped users
         * @summary Update interaction with new stages
         * @param {InteractionsApiUpdateInteractionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInteraction(requestParameters: InteractionsApiUpdateInteractionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateInteraction(requestParameters.interactionId, requestParameters.interactionUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInteraction operation in InteractionsApi.
 * @export
 * @interface InteractionsApiCreateInteractionRequest
 */
export interface InteractionsApiCreateInteractionRequest {
    /**
     * 
     * @type {InteractionCreate}
     * @memberof InteractionsApiCreateInteraction
     */
    readonly interactionCreate: InteractionCreate
}

/**
 * Request parameters for generateInteractionReport operation in InteractionsApi.
 * @export
 * @interface InteractionsApiGenerateInteractionReportRequest
 */
export interface InteractionsApiGenerateInteractionReportRequest {
    /**
     * 
     * @type {string}
     * @memberof InteractionsApiGenerateInteractionReport
     */
    readonly token: string
}

/**
 * Request parameters for postCareerForm operation in InteractionsApi.
 * @export
 * @interface InteractionsApiPostCareerFormRequest
 */
export interface InteractionsApiPostCareerFormRequest {
    /**
     * 
     * @type {CareerForm}
     * @memberof InteractionsApiPostCareerForm
     */
    readonly body: CareerForm

    /**
     * 
     * @type {File}
     * @memberof InteractionsApiPostCareerForm
     */
    readonly resume?: File | null
}

/**
 * Request parameters for updateInteraction operation in InteractionsApi.
 * @export
 * @interface InteractionsApiUpdateInteractionRequest
 */
export interface InteractionsApiUpdateInteractionRequest {
    /**
     * 
     * @type {number}
     * @memberof InteractionsApiUpdateInteraction
     */
    readonly interactionId: number

    /**
     * 
     * @type {InteractionUpdate}
     * @memberof InteractionsApiUpdateInteraction
     */
    readonly interactionUpdate: InteractionUpdate
}

/**
 * InteractionsApi - object-oriented interface
 * @export
 * @class InteractionsApi
 * @extends {BaseAPI}
 */
export class InteractionsApi extends BaseAPI {
    /**
     * Start user interaction to be updated later with stages
     * @summary Create interaction, response is interaction id to update
     * @param {InteractionsApiCreateInteractionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InteractionsApi
     */
    public createInteraction(requestParameters: InteractionsApiCreateInteractionRequest, options?: RawAxiosRequestConfig) {
        return InteractionsApiFp(this.configuration).createInteraction(requestParameters.interactionCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate report by provided token
     * @param {InteractionsApiGenerateInteractionReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InteractionsApi
     */
    public generateInteractionReport(requestParameters: InteractionsApiGenerateInteractionReportRequest, options?: RawAxiosRequestConfig) {
        return InteractionsApiFp(this.configuration).generateInteractionReport(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Career Form
     * @param {InteractionsApiPostCareerFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InteractionsApi
     */
    public postCareerForm(requestParameters: InteractionsApiPostCareerFormRequest, options?: RawAxiosRequestConfig) {
        return InteractionsApiFp(this.configuration).postCareerForm(requestParameters.body, requestParameters.resume, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Should be sent separately at each stage to allow recording escaped users
     * @summary Update interaction with new stages
     * @param {InteractionsApiUpdateInteractionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InteractionsApi
     */
    public updateInteraction(requestParameters: InteractionsApiUpdateInteractionRequest, options?: RawAxiosRequestConfig) {
        return InteractionsApiFp(this.configuration).updateInteraction(requestParameters.interactionId, requestParameters.interactionUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Level <b>area</b> level is a little bit bugged in DB, so it\'s useless for now.
         * @summary Get all child objects with given level
         * @param {GetAddressChildrenLevelEnum} [level] What we expect to get
         * @param {CityTypeChoices | null} [cityType] For &lt;b&gt;city&lt;/b&gt; level. What kind of cities to get.
         * @param {string | null} [fiasId] Limit search children by parent &#x60;fias_id&#x60;. E.g. if this is fias_id of city and &#x60;level&#x60; is &lt;b&gt;street&lt;/b&gt;, then result is streets in given city. Ignored for &lt;b&gt;region&lt;/b&gt; &#x60;level&#x60;
         * @param {string | null} [mixInForFiasId] From which fias_id page this were queried. Needed to mix-in random address objects for robots
         * @param {string | null} [firstLetters] Фильтровать по первой букве объекта. Используются все буквы в строке.
         * @param {number | null} [minProviders] Минимальное кол-во провайдеров
         * @param {Array<number> | null} [providers] Фильтрация городов/поселений по провайдерам
         * @param {Array<string> | null} [providerSlugs] Фильтрация городов/поселений по slug провайдера
         * @param {boolean | null} [onlyWithHouses] Если true, то отдавать только объекты в которых есть хотя бы 1 дом
         * @param {Array<string> | null} [onlyHouseTypes] Только с этими типами домов
         * @param {boolean | null} [inSitemap] Только адреса с домами в сайтмапе/для роботов
         * @param {boolean | null} [optimized] Включает оптимизации для больших запросов. Некоторые поля могут пропускаться. Выключает тултипы.
         * @param {boolean | null} [withRegionFiasIds] Подтягивать fias_id региона в поле region_fias_id
         * @param {AddressChildrenSortChoices | null} [sort] Сортировка
         * @param {string | null} [query] Текстовый запрос для фильтрации
         * @param {string | null} [provodnoySubdomain] Filter by provodnoy subdomain
         * @param {boolean | null} [onlyProvodnoySubdomains] Get only addresses that are provodnoy subdomains
         * @param {number | null} [siteId] 
         * @param {boolean | null} [joinRegionCities] Объединить МСК-МО, СПБ-ЛО
         * @param {number | null} [limit] 
         * @param {number} [offset] 
         * @param {string | null} [cursor] Вместо offset для пагинации по курсору. sort должен быть ID. Передавать последний fias_id предыдущей страницы или пустую строку/0 для первой страницы
         * @param {boolean | null} [provodnoy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressChildren: async (level?: GetAddressChildrenLevelEnum, cityType?: CityTypeChoices | null, fiasId?: string | null, mixInForFiasId?: string | null, firstLetters?: string | null, minProviders?: number | null, providers?: Array<number> | null, providerSlugs?: Array<string> | null, onlyWithHouses?: boolean | null, onlyHouseTypes?: Array<string> | null, inSitemap?: boolean | null, optimized?: boolean | null, withRegionFiasIds?: boolean | null, sort?: AddressChildrenSortChoices | null, query?: string | null, provodnoySubdomain?: string | null, onlyProvodnoySubdomains?: boolean | null, siteId?: number | null, joinRegionCities?: boolean | null, limit?: number | null, offset?: number, cursor?: string | null, provodnoy?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/locations/address-objects/children/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (cityType !== undefined) {
                localVarQueryParameter['city_type'] = cityType;
            }

            if (fiasId !== undefined) {
                localVarQueryParameter['fias_id'] = fiasId;
            }

            if (mixInForFiasId !== undefined) {
                localVarQueryParameter['mix_in_for_fias_id'] = mixInForFiasId;
            }

            if (firstLetters !== undefined) {
                localVarQueryParameter['first_letters'] = firstLetters;
            }

            if (minProviders !== undefined) {
                localVarQueryParameter['min_providers'] = minProviders;
            }

            if (providers) {
                localVarQueryParameter['providers'] = providers;
            }

            if (providerSlugs) {
                localVarQueryParameter['provider_slugs'] = providerSlugs;
            }

            if (onlyWithHouses !== undefined) {
                localVarQueryParameter['only_with_houses'] = onlyWithHouses;
            }

            if (onlyHouseTypes) {
                localVarQueryParameter['only_house_types'] = onlyHouseTypes;
            }

            if (inSitemap !== undefined) {
                localVarQueryParameter['in_sitemap'] = inSitemap;
            }

            if (optimized !== undefined) {
                localVarQueryParameter['optimized'] = optimized;
            }

            if (withRegionFiasIds !== undefined) {
                localVarQueryParameter['with_region_fias_ids'] = withRegionFiasIds;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (provodnoySubdomain !== undefined) {
                localVarQueryParameter['provodnoy_subdomain'] = provodnoySubdomain;
            }

            if (onlyProvodnoySubdomains !== undefined) {
                localVarQueryParameter['only_provodnoy_subdomains'] = onlyProvodnoySubdomains;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (joinRegionCities !== undefined) {
                localVarQueryParameter['join_region_cities'] = joinRegionCities;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить все дома. Опционально ограниченные по любому родителю. Опционально получение только нужных полей.
         * @summary Get All Houses
         * @param {AllHousesBody} allHousesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHouses: async (allHousesBody: AllHousesBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allHousesBody' is not null or undefined
            assertParamExists('getAllHouses', 'allHousesBody', allHousesBody)
            const localVarPath = `/locations/houses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allHousesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ancestors Chain By Geo
         * @param {string | null} [urlGeo] If passed then searches exclusively by this url, returning 404 if not found
         * @param {string | null} [provider] If passed, then locations are filtered for those available for given provider
         * @param {number | null} [yandexGeo] 
         * @param {number | null} [googleGeo] 
         * @param {string | null} [clientIp] 
         * @param {boolean} [isBot] 
         * @param {boolean} [includeSelf] 
         * @param {boolean} [morph] 
         * @param {number | null} [siteId] 
         * @param {boolean} [provodnoy] Предпочитать site_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsChainByGeo: async (urlGeo?: string | null, provider?: string | null, yandexGeo?: number | null, googleGeo?: number | null, clientIp?: string | null, isBot?: boolean, includeSelf?: boolean, morph?: boolean, siteId?: number | null, provodnoy?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/locations/address-objects/chain-by-geo/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (urlGeo !== undefined) {
                localVarQueryParameter['url_geo'] = urlGeo;
            }

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            if (yandexGeo !== undefined) {
                localVarQueryParameter['yandex_geo'] = yandexGeo;
            }

            if (googleGeo !== undefined) {
                localVarQueryParameter['google_geo'] = googleGeo;
            }

            if (clientIp !== undefined) {
                localVarQueryParameter['client_ip'] = clientIp;
            }

            if (isBot !== undefined) {
                localVarQueryParameter['is_bot'] = isBot;
            }

            if (includeSelf !== undefined) {
                localVarQueryParameter['include_self'] = includeSelf;
            }

            if (morph !== undefined) {
                localVarQueryParameter['morph'] = morph;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ancestors Chain For Address
         * @param {string} fiasId 
         * @param {boolean} [includeSelf] 
         * @param {boolean} [morph] 
         * @param {boolean} [provodnoy] Предпочитать site_id
         * @param {boolean} [gar] 
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsChainForAddress: async (fiasId: string, includeSelf?: boolean, morph?: boolean, provodnoy?: boolean, gar?: boolean, siteId?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiasId' is not null or undefined
            assertParamExists('getAncestorsChainForAddress', 'fiasId', fiasId)
            const localVarPath = `/locations/address-objects/{fias_id}/ancestors/`
                .replace(`{${"fias_id"}}`, encodeURIComponent(String(fiasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeSelf !== undefined) {
                localVarQueryParameter['include_self'] = includeSelf;
            }

            if (morph !== undefined) {
                localVarQueryParameter['morph'] = morph;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }

            if (gar !== undefined) {
                localVarQueryParameter['gar'] = gar;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ancestors Chain For House
         * @param {number} houseId 
         * @param {boolean} [includeSelf] 
         * @param {boolean} [morph] 
         * @param {boolean} [provodnoy] 
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAncestorsChainForHouse: async (houseId: number, includeSelf?: boolean, morph?: boolean, provodnoy?: boolean, siteId?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'houseId' is not null or undefined
            assertParamExists('getAncestorsChainForHouse', 'houseId', houseId)
            const localVarPath = `/locations/houses/{house_id}/ancestors/`
                .replace(`{${"house_id"}}`, encodeURIComponent(String(houseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeSelf !== undefined) {
                localVarQueryParameter['include_self'] = includeSelf;
            }

            if (morph !== undefined) {
                localVarQueryParameter['morph'] = morph;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ancestors Chain For Url
         * @param {string} url 
         * @param {boolean} [includeSelf] 
         * @param {boolean} [morph] 
         * @param {boolean} [provodnoy] Предпочитать site_id
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsChainForUrl: async (url: string, includeSelf?: boolean, morph?: boolean, provodnoy?: boolean, siteId?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('getAncestorsChainForUrl', 'url', url)
            const localVarPath = `/locations/address-objects/chain-by-url/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (includeSelf !== undefined) {
                localVarQueryParameter['include_self'] = includeSelf;
            }

            if (morph !== undefined) {
                localVarQueryParameter['morph'] = morph;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available houses for given street
         * @param {string} fiasId Parent &lt;b&gt;street&lt;/b&gt; fias_id
         * @param {string | null} [query] Filter for autocomplete
         * @param {OrderChoices | null} [sort] 
         * @param {number | null} [minProviders] Minimum available providers for house to get into results
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHousesForStreet: async (fiasId: string, query?: string | null, sort?: OrderChoices | null, minProviders?: number | null, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiasId' is not null or undefined
            assertParamExists('getHousesForStreet', 'fiasId', fiasId)
            const localVarPath = `/locations/streets/{fias_id}/houses/`
                .replace(`{${"fias_id"}}`, encodeURIComponent(String(fiasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (minProviders !== undefined) {
                localVarQueryParameter['min_providers'] = minProviders;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Level <b>area</b> level is a little bit bugged in DB, so it\'s useless for now.
         * @summary Get all child objects with given level
         * @param {GetAddressChildrenLevelEnum} [level] What we expect to get
         * @param {CityTypeChoices | null} [cityType] For &lt;b&gt;city&lt;/b&gt; level. What kind of cities to get.
         * @param {string | null} [fiasId] Limit search children by parent &#x60;fias_id&#x60;. E.g. if this is fias_id of city and &#x60;level&#x60; is &lt;b&gt;street&lt;/b&gt;, then result is streets in given city. Ignored for &lt;b&gt;region&lt;/b&gt; &#x60;level&#x60;
         * @param {string | null} [mixInForFiasId] From which fias_id page this were queried. Needed to mix-in random address objects for robots
         * @param {string | null} [firstLetters] Фильтровать по первой букве объекта. Используются все буквы в строке.
         * @param {number | null} [minProviders] Минимальное кол-во провайдеров
         * @param {Array<number> | null} [providers] Фильтрация городов/поселений по провайдерам
         * @param {Array<string> | null} [providerSlugs] Фильтрация городов/поселений по slug провайдера
         * @param {boolean | null} [onlyWithHouses] Если true, то отдавать только объекты в которых есть хотя бы 1 дом
         * @param {Array<string> | null} [onlyHouseTypes] Только с этими типами домов
         * @param {boolean | null} [inSitemap] Только адреса с домами в сайтмапе/для роботов
         * @param {boolean | null} [optimized] Включает оптимизации для больших запросов. Некоторые поля могут пропускаться. Выключает тултипы.
         * @param {boolean | null} [withRegionFiasIds] Подтягивать fias_id региона в поле region_fias_id
         * @param {AddressChildrenSortChoices | null} [sort] Сортировка
         * @param {string | null} [query] Текстовый запрос для фильтрации
         * @param {string | null} [provodnoySubdomain] Filter by provodnoy subdomain
         * @param {boolean | null} [onlyProvodnoySubdomains] Get only addresses that are provodnoy subdomains
         * @param {number | null} [siteId] 
         * @param {boolean | null} [joinRegionCities] Объединить МСК-МО, СПБ-ЛО
         * @param {number | null} [limit] 
         * @param {number} [offset] 
         * @param {string | null} [cursor] Вместо offset для пагинации по курсору. sort должен быть ID. Передавать последний fias_id предыдущей страницы или пустую строку/0 для первой страницы
         * @param {boolean | null} [provodnoy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressChildren(level?: GetAddressChildrenLevelEnum, cityType?: CityTypeChoices | null, fiasId?: string | null, mixInForFiasId?: string | null, firstLetters?: string | null, minProviders?: number | null, providers?: Array<number> | null, providerSlugs?: Array<string> | null, onlyWithHouses?: boolean | null, onlyHouseTypes?: Array<string> | null, inSitemap?: boolean | null, optimized?: boolean | null, withRegionFiasIds?: boolean | null, sort?: AddressChildrenSortChoices | null, query?: string | null, provodnoySubdomain?: string | null, onlyProvodnoySubdomains?: boolean | null, siteId?: number | null, joinRegionCities?: boolean | null, limit?: number | null, offset?: number, cursor?: string | null, provodnoy?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressObjectWithParents>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressChildren(level, cityType, fiasId, mixInForFiasId, firstLetters, minProviders, providers, providerSlugs, onlyWithHouses, onlyHouseTypes, inSitemap, optimized, withRegionFiasIds, sort, query, provodnoySubdomain, onlyProvodnoySubdomains, siteId, joinRegionCities, limit, offset, cursor, provodnoy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.getAddressChildren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Получить все дома. Опционально ограниченные по любому родителю. Опционально получение только нужных полей.
         * @summary Get All Houses
         * @param {AllHousesBody} allHousesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHouses(allHousesBody: AllHousesBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllHousesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHouses(allHousesBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.getAllHouses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Ancestors Chain By Geo
         * @param {string | null} [urlGeo] If passed then searches exclusively by this url, returning 404 if not found
         * @param {string | null} [provider] If passed, then locations are filtered for those available for given provider
         * @param {number | null} [yandexGeo] 
         * @param {number | null} [googleGeo] 
         * @param {string | null} [clientIp] 
         * @param {boolean} [isBot] 
         * @param {boolean} [includeSelf] 
         * @param {boolean} [morph] 
         * @param {number | null} [siteId] 
         * @param {boolean} [provodnoy] Предпочитать site_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAncestorsChainByGeo(urlGeo?: string | null, provider?: string | null, yandexGeo?: number | null, googleGeo?: number | null, clientIp?: string | null, isBot?: boolean, includeSelf?: boolean, morph?: boolean, siteId?: number | null, provodnoy?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressChain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAncestorsChainByGeo(urlGeo, provider, yandexGeo, googleGeo, clientIp, isBot, includeSelf, morph, siteId, provodnoy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.getAncestorsChainByGeo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Ancestors Chain For Address
         * @param {string} fiasId 
         * @param {boolean} [includeSelf] 
         * @param {boolean} [morph] 
         * @param {boolean} [provodnoy] Предпочитать site_id
         * @param {boolean} [gar] 
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAncestorsChainForAddress(fiasId: string, includeSelf?: boolean, morph?: boolean, provodnoy?: boolean, gar?: boolean, siteId?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressChain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAncestorsChainForAddress(fiasId, includeSelf, morph, provodnoy, gar, siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.getAncestorsChainForAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Ancestors Chain For House
         * @param {number} houseId 
         * @param {boolean} [includeSelf] 
         * @param {boolean} [morph] 
         * @param {boolean} [provodnoy] 
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAncestorsChainForHouse(houseId: number, includeSelf?: boolean, morph?: boolean, provodnoy?: boolean, siteId?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressChain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAncestorsChainForHouse(houseId, includeSelf, morph, provodnoy, siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.getAncestorsChainForHouse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Ancestors Chain For Url
         * @param {string} url 
         * @param {boolean} [includeSelf] 
         * @param {boolean} [morph] 
         * @param {boolean} [provodnoy] Предпочитать site_id
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAncestorsChainForUrl(url: string, includeSelf?: boolean, morph?: boolean, provodnoy?: boolean, siteId?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressChain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAncestorsChainForUrl(url, includeSelf, morph, provodnoy, siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.getAncestorsChainForUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get available houses for given street
         * @param {string} fiasId Parent &lt;b&gt;street&lt;/b&gt; fias_id
         * @param {string | null} [query] Filter for autocomplete
         * @param {OrderChoices | null} [sort] 
         * @param {number | null} [minProviders] Minimum available providers for house to get into results
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHousesForStreet(fiasId: string, query?: string | null, sort?: OrderChoices | null, minProviders?: number | null, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<House>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHousesForStreet(fiasId, query, sort, minProviders, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.getHousesForStreet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationsApiFp(configuration)
    return {
        /**
         * Level <b>area</b> level is a little bit bugged in DB, so it\'s useless for now.
         * @summary Get all child objects with given level
         * @param {LocationsApiGetAddressChildrenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressChildren(requestParameters: LocationsApiGetAddressChildrenRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<AddressObjectWithParents>> {
            return localVarFp.getAddressChildren(requestParameters.level, requestParameters.cityType, requestParameters.fiasId, requestParameters.mixInForFiasId, requestParameters.firstLetters, requestParameters.minProviders, requestParameters.providers, requestParameters.providerSlugs, requestParameters.onlyWithHouses, requestParameters.onlyHouseTypes, requestParameters.inSitemap, requestParameters.optimized, requestParameters.withRegionFiasIds, requestParameters.sort, requestParameters.query, requestParameters.provodnoySubdomain, requestParameters.onlyProvodnoySubdomains, requestParameters.siteId, requestParameters.joinRegionCities, requestParameters.limit, requestParameters.offset, requestParameters.cursor, requestParameters.provodnoy, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить все дома. Опционально ограниченные по любому родителю. Опционально получение только нужных полей.
         * @summary Get All Houses
         * @param {LocationsApiGetAllHousesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHouses(requestParameters: LocationsApiGetAllHousesRequest, options?: RawAxiosRequestConfig): AxiosPromise<AllHousesResult> {
            return localVarFp.getAllHouses(requestParameters.allHousesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ancestors Chain By Geo
         * @param {LocationsApiGetAncestorsChainByGeoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsChainByGeo(requestParameters: LocationsApiGetAncestorsChainByGeoRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AddressChain> {
            return localVarFp.getAncestorsChainByGeo(requestParameters.urlGeo, requestParameters.provider, requestParameters.yandexGeo, requestParameters.googleGeo, requestParameters.clientIp, requestParameters.isBot, requestParameters.includeSelf, requestParameters.morph, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ancestors Chain For Address
         * @param {LocationsApiGetAncestorsChainForAddressRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsChainForAddress(requestParameters: LocationsApiGetAncestorsChainForAddressRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddressChain> {
            return localVarFp.getAncestorsChainForAddress(requestParameters.fiasId, requestParameters.includeSelf, requestParameters.morph, requestParameters.provodnoy, requestParameters.gar, requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ancestors Chain For House
         * @param {LocationsApiGetAncestorsChainForHouseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAncestorsChainForHouse(requestParameters: LocationsApiGetAncestorsChainForHouseRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddressChain> {
            return localVarFp.getAncestorsChainForHouse(requestParameters.houseId, requestParameters.includeSelf, requestParameters.morph, requestParameters.provodnoy, requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ancestors Chain For Url
         * @param {LocationsApiGetAncestorsChainForUrlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsChainForUrl(requestParameters: LocationsApiGetAncestorsChainForUrlRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddressChain> {
            return localVarFp.getAncestorsChainForUrl(requestParameters.url, requestParameters.includeSelf, requestParameters.morph, requestParameters.provodnoy, requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available houses for given street
         * @param {LocationsApiGetHousesForStreetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHousesForStreet(requestParameters: LocationsApiGetHousesForStreetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<House>> {
            return localVarFp.getHousesForStreet(requestParameters.fiasId, requestParameters.query, requestParameters.sort, requestParameters.minProviders, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAddressChildren operation in LocationsApi.
 * @export
 * @interface LocationsApiGetAddressChildrenRequest
 */
export interface LocationsApiGetAddressChildrenRequest {
    /**
     * What we expect to get
     * @type {string}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly level?: GetAddressChildrenLevelEnum

    /**
     * For &lt;b&gt;city&lt;/b&gt; level. What kind of cities to get.
     * @type {CityTypeChoices}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly cityType?: CityTypeChoices | null

    /**
     * Limit search children by parent &#x60;fias_id&#x60;. E.g. if this is fias_id of city and &#x60;level&#x60; is &lt;b&gt;street&lt;/b&gt;, then result is streets in given city. Ignored for &lt;b&gt;region&lt;/b&gt; &#x60;level&#x60;
     * @type {string}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly fiasId?: string | null

    /**
     * From which fias_id page this were queried. Needed to mix-in random address objects for robots
     * @type {string}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly mixInForFiasId?: string | null

    /**
     * Фильтровать по первой букве объекта. Используются все буквы в строке.
     * @type {string}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly firstLetters?: string | null

    /**
     * Минимальное кол-во провайдеров
     * @type {number}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly minProviders?: number | null

    /**
     * Фильтрация городов/поселений по провайдерам
     * @type {Array<number>}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly providers?: Array<number> | null

    /**
     * Фильтрация городов/поселений по slug провайдера
     * @type {Array<string>}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly providerSlugs?: Array<string> | null

    /**
     * Если true, то отдавать только объекты в которых есть хотя бы 1 дом
     * @type {boolean}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly onlyWithHouses?: boolean | null

    /**
     * Только с этими типами домов
     * @type {Array<string>}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly onlyHouseTypes?: Array<string> | null

    /**
     * Только адреса с домами в сайтмапе/для роботов
     * @type {boolean}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly inSitemap?: boolean | null

    /**
     * Включает оптимизации для больших запросов. Некоторые поля могут пропускаться. Выключает тултипы.
     * @type {boolean}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly optimized?: boolean | null

    /**
     * Подтягивать fias_id региона в поле region_fias_id
     * @type {boolean}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly withRegionFiasIds?: boolean | null

    /**
     * Сортировка
     * @type {AddressChildrenSortChoices}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly sort?: AddressChildrenSortChoices | null

    /**
     * Текстовый запрос для фильтрации
     * @type {string}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly query?: string | null

    /**
     * Filter by provodnoy subdomain
     * @type {string}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly provodnoySubdomain?: string | null

    /**
     * Get only addresses that are provodnoy subdomains
     * @type {boolean}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly onlyProvodnoySubdomains?: boolean | null

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly siteId?: number | null

    /**
     * Объединить МСК-МО, СПБ-ЛО
     * @type {boolean}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly joinRegionCities?: boolean | null

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly offset?: number

    /**
     * Вместо offset для пагинации по курсору. sort должен быть ID. Передавать последний fias_id предыдущей страницы или пустую строку/0 для первой страницы
     * @type {string}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly cursor?: string | null

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAddressChildren
     */
    readonly provodnoy?: boolean | null
}

/**
 * Request parameters for getAllHouses operation in LocationsApi.
 * @export
 * @interface LocationsApiGetAllHousesRequest
 */
export interface LocationsApiGetAllHousesRequest {
    /**
     * 
     * @type {AllHousesBody}
     * @memberof LocationsApiGetAllHouses
     */
    readonly allHousesBody: AllHousesBody
}

/**
 * Request parameters for getAncestorsChainByGeo operation in LocationsApi.
 * @export
 * @interface LocationsApiGetAncestorsChainByGeoRequest
 */
export interface LocationsApiGetAncestorsChainByGeoRequest {
    /**
     * If passed then searches exclusively by this url, returning 404 if not found
     * @type {string}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly urlGeo?: string | null

    /**
     * If passed, then locations are filtered for those available for given provider
     * @type {string}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly provider?: string | null

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly yandexGeo?: number | null

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly googleGeo?: number | null

    /**
     * 
     * @type {string}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly clientIp?: string | null

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly isBot?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly includeSelf?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly morph?: boolean

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly siteId?: number | null

    /**
     * Предпочитать site_id
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainByGeo
     */
    readonly provodnoy?: boolean
}

/**
 * Request parameters for getAncestorsChainForAddress operation in LocationsApi.
 * @export
 * @interface LocationsApiGetAncestorsChainForAddressRequest
 */
export interface LocationsApiGetAncestorsChainForAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsApiGetAncestorsChainForAddress
     */
    readonly fiasId: string

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForAddress
     */
    readonly includeSelf?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForAddress
     */
    readonly morph?: boolean

    /**
     * Предпочитать site_id
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForAddress
     */
    readonly provodnoy?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForAddress
     */
    readonly gar?: boolean

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAncestorsChainForAddress
     */
    readonly siteId?: number | null
}

/**
 * Request parameters for getAncestorsChainForHouse operation in LocationsApi.
 * @export
 * @interface LocationsApiGetAncestorsChainForHouseRequest
 */
export interface LocationsApiGetAncestorsChainForHouseRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAncestorsChainForHouse
     */
    readonly houseId: number

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForHouse
     */
    readonly includeSelf?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForHouse
     */
    readonly morph?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForHouse
     */
    readonly provodnoy?: boolean

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAncestorsChainForHouse
     */
    readonly siteId?: number | null
}

/**
 * Request parameters for getAncestorsChainForUrl operation in LocationsApi.
 * @export
 * @interface LocationsApiGetAncestorsChainForUrlRequest
 */
export interface LocationsApiGetAncestorsChainForUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsApiGetAncestorsChainForUrl
     */
    readonly url: string

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForUrl
     */
    readonly includeSelf?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForUrl
     */
    readonly morph?: boolean

    /**
     * Предпочитать site_id
     * @type {boolean}
     * @memberof LocationsApiGetAncestorsChainForUrl
     */
    readonly provodnoy?: boolean

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetAncestorsChainForUrl
     */
    readonly siteId?: number | null
}

/**
 * Request parameters for getHousesForStreet operation in LocationsApi.
 * @export
 * @interface LocationsApiGetHousesForStreetRequest
 */
export interface LocationsApiGetHousesForStreetRequest {
    /**
     * Parent &lt;b&gt;street&lt;/b&gt; fias_id
     * @type {string}
     * @memberof LocationsApiGetHousesForStreet
     */
    readonly fiasId: string

    /**
     * Filter for autocomplete
     * @type {string}
     * @memberof LocationsApiGetHousesForStreet
     */
    readonly query?: string | null

    /**
     * 
     * @type {OrderChoices}
     * @memberof LocationsApiGetHousesForStreet
     */
    readonly sort?: OrderChoices | null

    /**
     * Minimum available providers for house to get into results
     * @type {number}
     * @memberof LocationsApiGetHousesForStreet
     */
    readonly minProviders?: number | null

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetHousesForStreet
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof LocationsApiGetHousesForStreet
     */
    readonly offset?: number
}

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * Level <b>area</b> level is a little bit bugged in DB, so it\'s useless for now.
     * @summary Get all child objects with given level
     * @param {LocationsApiGetAddressChildrenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getAddressChildren(requestParameters: LocationsApiGetAddressChildrenRequest = {}, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).getAddressChildren(requestParameters.level, requestParameters.cityType, requestParameters.fiasId, requestParameters.mixInForFiasId, requestParameters.firstLetters, requestParameters.minProviders, requestParameters.providers, requestParameters.providerSlugs, requestParameters.onlyWithHouses, requestParameters.onlyHouseTypes, requestParameters.inSitemap, requestParameters.optimized, requestParameters.withRegionFiasIds, requestParameters.sort, requestParameters.query, requestParameters.provodnoySubdomain, requestParameters.onlyProvodnoySubdomains, requestParameters.siteId, requestParameters.joinRegionCities, requestParameters.limit, requestParameters.offset, requestParameters.cursor, requestParameters.provodnoy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить все дома. Опционально ограниченные по любому родителю. Опционально получение только нужных полей.
     * @summary Get All Houses
     * @param {LocationsApiGetAllHousesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getAllHouses(requestParameters: LocationsApiGetAllHousesRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).getAllHouses(requestParameters.allHousesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ancestors Chain By Geo
     * @param {LocationsApiGetAncestorsChainByGeoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getAncestorsChainByGeo(requestParameters: LocationsApiGetAncestorsChainByGeoRequest = {}, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).getAncestorsChainByGeo(requestParameters.urlGeo, requestParameters.provider, requestParameters.yandexGeo, requestParameters.googleGeo, requestParameters.clientIp, requestParameters.isBot, requestParameters.includeSelf, requestParameters.morph, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ancestors Chain For Address
     * @param {LocationsApiGetAncestorsChainForAddressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getAncestorsChainForAddress(requestParameters: LocationsApiGetAncestorsChainForAddressRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).getAncestorsChainForAddress(requestParameters.fiasId, requestParameters.includeSelf, requestParameters.morph, requestParameters.provodnoy, requestParameters.gar, requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ancestors Chain For House
     * @param {LocationsApiGetAncestorsChainForHouseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getAncestorsChainForHouse(requestParameters: LocationsApiGetAncestorsChainForHouseRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).getAncestorsChainForHouse(requestParameters.houseId, requestParameters.includeSelf, requestParameters.morph, requestParameters.provodnoy, requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ancestors Chain For Url
     * @param {LocationsApiGetAncestorsChainForUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getAncestorsChainForUrl(requestParameters: LocationsApiGetAncestorsChainForUrlRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).getAncestorsChainForUrl(requestParameters.url, requestParameters.includeSelf, requestParameters.morph, requestParameters.provodnoy, requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available houses for given street
     * @param {LocationsApiGetHousesForStreetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public getHousesForStreet(requestParameters: LocationsApiGetHousesForStreetRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).getHousesForStreet(requestParameters.fiasId, requestParameters.query, requestParameters.sort, requestParameters.minProviders, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAddressChildrenLevelEnum = {
    REGION: 'region',
    AREA: 'area',
    CITY: 'city',
    STREET: 'street'
} as const;
export type GetAddressChildrenLevelEnum = typeof GetAddressChildrenLevelEnum[keyof typeof GetAddressChildrenLevelEnum];


/**
 * ProvidersApi - axios parameter creator
 * @export
 */
export const ProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Provider About
         * @param {string} slug id или slug
         * @param {string | null} [fiasId] FIAS id населенного пункта для получения контактов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAbout: async (slug: string, fiasId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getProviderAbout', 'slug', slug)
            const localVarPath = `/providers/{slug}/about/`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fiasId !== undefined) {
                localVarQueryParameter['fias_id'] = fiasId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Offices
         * @param {ProviderOfficesBody} providerOfficesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderOffices: async (providerOfficesBody: ProviderOfficesBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerOfficesBody' is not null or undefined
            assertParamExists('getProviderOffices', 'providerOfficesBody', providerOfficesBody)
            const localVarPath = `/providers/offices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerOfficesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Провайдеры которые отображаются как наши партнеры
         * @summary Get Providers List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/partners/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProvidersApi - functional programming interface
 * @export
 */
export const ProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Provider About
         * @param {string} slug id или slug
         * @param {string | null} [fiasId] FIAS id населенного пункта для получения контактов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAbout(slug: string, fiasId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAbout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAbout(slug, fiasId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getProviderAbout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Offices
         * @param {ProviderOfficesBody} providerOfficesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderOffices(providerOfficesBody: ProviderOfficesBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderOfficesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderOffices(providerOfficesBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getProviderOffices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Провайдеры которые отображаются как наши партнеры
         * @summary Get Providers List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidersList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Provider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidersList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getProvidersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProvidersApi - factory interface
 * @export
 */
export const ProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProvidersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Provider About
         * @param {ProvidersApiGetProviderAboutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAbout(requestParameters: ProvidersApiGetProviderAboutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProviderAbout> {
            return localVarFp.getProviderAbout(requestParameters.slug, requestParameters.fiasId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Offices
         * @param {ProvidersApiGetProviderOfficesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderOffices(requestParameters: ProvidersApiGetProviderOfficesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProviderOfficesResponse> {
            return localVarFp.getProviderOffices(requestParameters.providerOfficesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Провайдеры которые отображаются как наши партнеры
         * @summary Get Providers List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersList(options?: RawAxiosRequestConfig): AxiosPromise<Array<Provider>> {
            return localVarFp.getProvidersList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProviderAbout operation in ProvidersApi.
 * @export
 * @interface ProvidersApiGetProviderAboutRequest
 */
export interface ProvidersApiGetProviderAboutRequest {
    /**
     * id или slug
     * @type {string}
     * @memberof ProvidersApiGetProviderAbout
     */
    readonly slug: string

    /**
     * FIAS id населенного пункта для получения контактов
     * @type {string}
     * @memberof ProvidersApiGetProviderAbout
     */
    readonly fiasId?: string | null
}

/**
 * Request parameters for getProviderOffices operation in ProvidersApi.
 * @export
 * @interface ProvidersApiGetProviderOfficesRequest
 */
export interface ProvidersApiGetProviderOfficesRequest {
    /**
     * 
     * @type {ProviderOfficesBody}
     * @memberof ProvidersApiGetProviderOffices
     */
    readonly providerOfficesBody: ProviderOfficesBody
}

/**
 * ProvidersApi - object-oriented interface
 * @export
 * @class ProvidersApi
 * @extends {BaseAPI}
 */
export class ProvidersApi extends BaseAPI {
    /**
     * 
     * @summary Get Provider About
     * @param {ProvidersApiGetProviderAboutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProviderAbout(requestParameters: ProvidersApiGetProviderAboutRequest, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getProviderAbout(requestParameters.slug, requestParameters.fiasId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Offices
     * @param {ProvidersApiGetProviderOfficesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProviderOffices(requestParameters: ProvidersApiGetProviderOfficesRequest, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getProviderOffices(requestParameters.providerOfficesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Провайдеры которые отображаются как наши партнеры
     * @summary Get Providers List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProvidersList(options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getProvidersList(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReviewsApi - axios parameter creator
 * @export
 */
export const ReviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update review. Updated ProviderReview object should have boolean can_edit=true, or else 404 will be returned
         * @summary Change Provider Review
         * @param {number} reviewId 
         * @param {ProviderReviewUpdateInput} providerReviewUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeProviderReview: async (reviewId: number, providerReviewUpdateInput: ProviderReviewUpdateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewId' is not null or undefined
            assertParamExists('changeProviderReview', 'reviewId', reviewId)
            // verify required parameter 'providerReviewUpdateInput' is not null or undefined
            assertParamExists('changeProviderReview', 'providerReviewUpdateInput', providerReviewUpdateInput)
            const localVarPath = `/locations/providers/reviews/{review_id}/`
                .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerReviewUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create review for provider. fias_id should be most granular that we have, so house or street is perfect.
         * @summary Create Provider Review
         * @param {string} fiasId 
         * @param {ProviderReviewInput} providerReviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderReview: async (fiasId: string, providerReviewInput: ProviderReviewInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiasId' is not null or undefined
            assertParamExists('createProviderReview', 'fiasId', fiasId)
            // verify required parameter 'providerReviewInput' is not null or undefined
            assertParamExists('createProviderReview', 'providerReviewInput', providerReviewInput)
            const localVarPath = `/locations/{fias_id}/providers/reviews/create/`
                .replace(`{${"fias_id"}}`, encodeURIComponent(String(fiasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerReviewInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить ревью текущего пользователя
         * @summary Get My Reviews
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMyReviews: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/locations/providers/reviews/my-reviews/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Review Comments
         * @param {number} reviewId id отзыва
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewComments: async (reviewId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewId' is not null or undefined
            assertParamExists('getReviewComments', 'reviewId', reviewId)
            const localVarPath = `/reviews/{review_id}/comments/`
                .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Данные для опросника при оставлении отзыва
         * @summary Get Review Questionnaire
         * @param {string} [slug] Какой опросник запросить
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewQuestionnaire: async (slug?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reviews/questionnaire/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Leave Review Comment
         * @param {number} reviewId id отзыва
         * @param {ReviewCommentInput} reviewCommentInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveReviewComment: async (reviewId: number, reviewCommentInput: ReviewCommentInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewId' is not null or undefined
            assertParamExists('leaveReviewComment', 'reviewId', reviewId)
            // verify required parameter 'reviewCommentInput' is not null or undefined
            assertParamExists('leaveReviewComment', 'reviewCommentInput', reviewCommentInput)
            const localVarPath = `/reviews/{review_id}/comments/`
                .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewCommentInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Place/remove likes/dislikes of reviews or its comments
         * @summary React To Review
         * @param {number} reviewId id отзыва
         * @param {ReactionInput} reactionInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactToReview: async (reviewId: number, reactionInput: ReactionInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewId' is not null or undefined
            assertParamExists('reactToReview', 'reviewId', reviewId)
            // verify required parameter 'reactionInput' is not null or undefined
            assertParamExists('reactToReview', 'reactionInput', reactionInput)
            const localVarPath = `/reviews/{review_id}/react/`
                .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reactionInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update or extend review partly from given questionnaire_slug. ProviderReview object should have boolean can_edit=true, or else 404 will be returned
         * @summary Update Provider Review
         * @param {number} reviewId 
         * @param {ProviderReviewUpdateInput} providerReviewUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderReview: async (reviewId: number, providerReviewUpdateInput: ProviderReviewUpdateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewId' is not null or undefined
            assertParamExists('updateProviderReview', 'reviewId', reviewId)
            // verify required parameter 'providerReviewUpdateInput' is not null or undefined
            assertParamExists('updateProviderReview', 'providerReviewUpdateInput', providerReviewUpdateInput)
            const localVarPath = `/locations/providers/reviews/{review_id}/`
                .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerReviewUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewsApi - functional programming interface
 * @export
 */
export const ReviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * Update review. Updated ProviderReview object should have boolean can_edit=true, or else 404 will be returned
         * @summary Change Provider Review
         * @param {number} reviewId 
         * @param {ProviderReviewUpdateInput} providerReviewUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeProviderReview(reviewId: number, providerReviewUpdateInput: ProviderReviewUpdateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeProviderReview(reviewId, providerReviewUpdateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.changeProviderReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create review for provider. fias_id should be most granular that we have, so house or street is perfect.
         * @summary Create Provider Review
         * @param {string} fiasId 
         * @param {ProviderReviewInput} providerReviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProviderReview(fiasId: string, providerReviewInput: ProviderReviewInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProviderReview(fiasId, providerReviewInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.createProviderReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Получить ревью текущего пользователя
         * @summary Get My Reviews
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMyReviews(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyReviewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyReviews(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.getMyReviews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Review Comments
         * @param {number} reviewId id отзыва
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewComments(reviewId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewComment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewComments(reviewId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.getReviewComments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Данные для опросника при оставлении отзыва
         * @summary Get Review Questionnaire
         * @param {string} [slug] Какой опросник запросить
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewQuestionnaire(slug?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewQuestionnaire(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.getReviewQuestionnaire']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Leave Review Comment
         * @param {number} reviewId id отзыва
         * @param {ReviewCommentInput} reviewCommentInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaveReviewComment(reviewId: number, reviewCommentInput: ReviewCommentInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leaveReviewComment(reviewId, reviewCommentInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.leaveReviewComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Place/remove likes/dislikes of reviews or its comments
         * @summary React To Review
         * @param {number} reviewId id отзыва
         * @param {ReactionInput} reactionInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactToReview(reviewId: number, reactionInput: ReactionInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactToReview(reviewId, reactionInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.reactToReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update or extend review partly from given questionnaire_slug. ProviderReview object should have boolean can_edit=true, or else 404 will be returned
         * @summary Update Provider Review
         * @param {number} reviewId 
         * @param {ProviderReviewUpdateInput} providerReviewUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProviderReview(reviewId: number, providerReviewUpdateInput: ProviderReviewUpdateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProviderReview(reviewId, providerReviewUpdateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.updateProviderReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReviewsApi - factory interface
 * @export
 */
export const ReviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewsApiFp(configuration)
    return {
        /**
         * Update review. Updated ProviderReview object should have boolean can_edit=true, or else 404 will be returned
         * @summary Change Provider Review
         * @param {ReviewsApiChangeProviderReviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeProviderReview(requestParameters: ReviewsApiChangeProviderReviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProviderReview> {
            return localVarFp.changeProviderReview(requestParameters.reviewId, requestParameters.providerReviewUpdateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create review for provider. fias_id should be most granular that we have, so house or street is perfect.
         * @summary Create Provider Review
         * @param {ReviewsApiCreateProviderReviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderReview(requestParameters: ReviewsApiCreateProviderReviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProviderReview> {
            return localVarFp.createProviderReview(requestParameters.fiasId, requestParameters.providerReviewInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить ревью текущего пользователя
         * @summary Get My Reviews
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMyReviews(options?: RawAxiosRequestConfig): AxiosPromise<MyReviewsResponse> {
            return localVarFp.getMyReviews(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Review Comments
         * @param {ReviewsApiGetReviewCommentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewComments(requestParameters: ReviewsApiGetReviewCommentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ReviewComment>> {
            return localVarFp.getReviewComments(requestParameters.reviewId, options).then((request) => request(axios, basePath));
        },
        /**
         * Данные для опросника при оставлении отзыва
         * @summary Get Review Questionnaire
         * @param {ReviewsApiGetReviewQuestionnaireRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewQuestionnaire(requestParameters: ReviewsApiGetReviewQuestionnaireRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<QuestionnaireSchema> {
            return localVarFp.getReviewQuestionnaire(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Leave Review Comment
         * @param {ReviewsApiLeaveReviewCommentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveReviewComment(requestParameters: ReviewsApiLeaveReviewCommentRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReviewComment> {
            return localVarFp.leaveReviewComment(requestParameters.reviewId, requestParameters.reviewCommentInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Place/remove likes/dislikes of reviews or its comments
         * @summary React To Review
         * @param {ReviewsApiReactToReviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactToReview(requestParameters: ReviewsApiReactToReviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReactionResponse> {
            return localVarFp.reactToReview(requestParameters.reviewId, requestParameters.reactionInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Update or extend review partly from given questionnaire_slug. ProviderReview object should have boolean can_edit=true, or else 404 will be returned
         * @summary Update Provider Review
         * @param {ReviewsApiUpdateProviderReviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderReview(requestParameters: ReviewsApiUpdateProviderReviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProviderReview> {
            return localVarFp.updateProviderReview(requestParameters.reviewId, requestParameters.providerReviewUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changeProviderReview operation in ReviewsApi.
 * @export
 * @interface ReviewsApiChangeProviderReviewRequest
 */
export interface ReviewsApiChangeProviderReviewRequest {
    /**
     * 
     * @type {number}
     * @memberof ReviewsApiChangeProviderReview
     */
    readonly reviewId: number

    /**
     * 
     * @type {ProviderReviewUpdateInput}
     * @memberof ReviewsApiChangeProviderReview
     */
    readonly providerReviewUpdateInput: ProviderReviewUpdateInput
}

/**
 * Request parameters for createProviderReview operation in ReviewsApi.
 * @export
 * @interface ReviewsApiCreateProviderReviewRequest
 */
export interface ReviewsApiCreateProviderReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof ReviewsApiCreateProviderReview
     */
    readonly fiasId: string

    /**
     * 
     * @type {ProviderReviewInput}
     * @memberof ReviewsApiCreateProviderReview
     */
    readonly providerReviewInput: ProviderReviewInput
}

/**
 * Request parameters for getReviewComments operation in ReviewsApi.
 * @export
 * @interface ReviewsApiGetReviewCommentsRequest
 */
export interface ReviewsApiGetReviewCommentsRequest {
    /**
     * id отзыва
     * @type {number}
     * @memberof ReviewsApiGetReviewComments
     */
    readonly reviewId: number
}

/**
 * Request parameters for getReviewQuestionnaire operation in ReviewsApi.
 * @export
 * @interface ReviewsApiGetReviewQuestionnaireRequest
 */
export interface ReviewsApiGetReviewQuestionnaireRequest {
    /**
     * Какой опросник запросить
     * @type {string}
     * @memberof ReviewsApiGetReviewQuestionnaire
     */
    readonly slug?: string
}

/**
 * Request parameters for leaveReviewComment operation in ReviewsApi.
 * @export
 * @interface ReviewsApiLeaveReviewCommentRequest
 */
export interface ReviewsApiLeaveReviewCommentRequest {
    /**
     * id отзыва
     * @type {number}
     * @memberof ReviewsApiLeaveReviewComment
     */
    readonly reviewId: number

    /**
     * 
     * @type {ReviewCommentInput}
     * @memberof ReviewsApiLeaveReviewComment
     */
    readonly reviewCommentInput: ReviewCommentInput
}

/**
 * Request parameters for reactToReview operation in ReviewsApi.
 * @export
 * @interface ReviewsApiReactToReviewRequest
 */
export interface ReviewsApiReactToReviewRequest {
    /**
     * id отзыва
     * @type {number}
     * @memberof ReviewsApiReactToReview
     */
    readonly reviewId: number

    /**
     * 
     * @type {ReactionInput}
     * @memberof ReviewsApiReactToReview
     */
    readonly reactionInput: ReactionInput
}

/**
 * Request parameters for updateProviderReview operation in ReviewsApi.
 * @export
 * @interface ReviewsApiUpdateProviderReviewRequest
 */
export interface ReviewsApiUpdateProviderReviewRequest {
    /**
     * 
     * @type {number}
     * @memberof ReviewsApiUpdateProviderReview
     */
    readonly reviewId: number

    /**
     * 
     * @type {ProviderReviewUpdateInput}
     * @memberof ReviewsApiUpdateProviderReview
     */
    readonly providerReviewUpdateInput: ProviderReviewUpdateInput
}

/**
 * ReviewsApi - object-oriented interface
 * @export
 * @class ReviewsApi
 * @extends {BaseAPI}
 */
export class ReviewsApi extends BaseAPI {
    /**
     * Update review. Updated ProviderReview object should have boolean can_edit=true, or else 404 will be returned
     * @summary Change Provider Review
     * @param {ReviewsApiChangeProviderReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public changeProviderReview(requestParameters: ReviewsApiChangeProviderReviewRequest, options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).changeProviderReview(requestParameters.reviewId, requestParameters.providerReviewUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create review for provider. fias_id should be most granular that we have, so house or street is perfect.
     * @summary Create Provider Review
     * @param {ReviewsApiCreateProviderReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public createProviderReview(requestParameters: ReviewsApiCreateProviderReviewRequest, options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).createProviderReview(requestParameters.fiasId, requestParameters.providerReviewInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить ревью текущего пользователя
     * @summary Get My Reviews
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public getMyReviews(options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).getMyReviews(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Review Comments
     * @param {ReviewsApiGetReviewCommentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public getReviewComments(requestParameters: ReviewsApiGetReviewCommentsRequest, options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).getReviewComments(requestParameters.reviewId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Данные для опросника при оставлении отзыва
     * @summary Get Review Questionnaire
     * @param {ReviewsApiGetReviewQuestionnaireRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public getReviewQuestionnaire(requestParameters: ReviewsApiGetReviewQuestionnaireRequest = {}, options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).getReviewQuestionnaire(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Leave Review Comment
     * @param {ReviewsApiLeaveReviewCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public leaveReviewComment(requestParameters: ReviewsApiLeaveReviewCommentRequest, options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).leaveReviewComment(requestParameters.reviewId, requestParameters.reviewCommentInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Place/remove likes/dislikes of reviews or its comments
     * @summary React To Review
     * @param {ReviewsApiReactToReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public reactToReview(requestParameters: ReviewsApiReactToReviewRequest, options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).reactToReview(requestParameters.reviewId, requestParameters.reactionInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update or extend review partly from given questionnaire_slug. ProviderReview object should have boolean can_edit=true, or else 404 will be returned
     * @summary Update Provider Review
     * @param {ReviewsApiUpdateProviderReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public updateProviderReview(requestParameters: ReviewsApiUpdateProviderReviewRequest, options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).updateProviderReview(requestParameters.reviewId, requestParameters.providerReviewUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SiteOptionsApi - axios parameter creator
 * @export
 */
export const SiteOptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Site Options
         * @param {string | null} [domain] Перезапись домена из url
         * @param {string | null} [url] Полный урл страницы. Включая все квери параметры! (utm метки и т.д.)
         * @param {string | null} [siteVersion] Получить версию по ключу
         * @param {string | null} [branch] Ветка для получения версии. DEPRECATED
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteOptions: async (domain?: string | null, url?: string | null, siteVersion?: string | null, branch?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/site-options/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (siteVersion !== undefined) {
                localVarQueryParameter['site_version'] = siteVersion;
            }

            if (branch !== undefined) {
                localVarQueryParameter['branch'] = branch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteOptionsApi - functional programming interface
 * @export
 */
export const SiteOptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteOptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Site Options
         * @param {string | null} [domain] Перезапись домена из url
         * @param {string | null} [url] Полный урл страницы. Включая все квери параметры! (utm метки и т.д.)
         * @param {string | null} [siteVersion] Получить версию по ключу
         * @param {string | null} [branch] Ветка для получения версии. DEPRECATED
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteOptions(domain?: string | null, url?: string | null, siteVersion?: string | null, branch?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteOptions(domain, url, siteVersion, branch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SiteOptionsApi.getSiteOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SiteOptionsApi - factory interface
 * @export
 */
export const SiteOptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteOptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Site Options
         * @param {SiteOptionsApiGetSiteOptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteOptions(requestParameters: SiteOptionsApiGetSiteOptionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SiteSchema> {
            return localVarFp.getSiteOptions(requestParameters.domain, requestParameters.url, requestParameters.siteVersion, requestParameters.branch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSiteOptions operation in SiteOptionsApi.
 * @export
 * @interface SiteOptionsApiGetSiteOptionsRequest
 */
export interface SiteOptionsApiGetSiteOptionsRequest {
    /**
     * Перезапись домена из url
     * @type {string}
     * @memberof SiteOptionsApiGetSiteOptions
     */
    readonly domain?: string | null

    /**
     * Полный урл страницы. Включая все квери параметры! (utm метки и т.д.)
     * @type {string}
     * @memberof SiteOptionsApiGetSiteOptions
     */
    readonly url?: string | null

    /**
     * Получить версию по ключу
     * @type {string}
     * @memberof SiteOptionsApiGetSiteOptions
     */
    readonly siteVersion?: string | null

    /**
     * Ветка для получения версии. DEPRECATED
     * @type {string}
     * @memberof SiteOptionsApiGetSiteOptions
     */
    readonly branch?: string | null
}

/**
 * SiteOptionsApi - object-oriented interface
 * @export
 * @class SiteOptionsApi
 * @extends {BaseAPI}
 */
export class SiteOptionsApi extends BaseAPI {
    /**
     * 
     * @summary Get Site Options
     * @param {SiteOptionsApiGetSiteOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteOptionsApi
     */
    public getSiteOptions(requestParameters: SiteOptionsApiGetSiteOptionsRequest = {}, options?: RawAxiosRequestConfig) {
        return SiteOptionsApiFp(this.configuration).getSiteOptions(requestParameters.domain, requestParameters.url, requestParameters.siteVersion, requestParameters.branch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SitemapApi - axios parameter creator
 * @export
 */
export const SitemapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Main Sitemap
         * @param {string} domain 
         * @param {string | null} [forwardedDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainSitemap: async (domain: string, forwardedDomain?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('mainSitemap', 'domain', domain)
            const localVarPath = `/sitemap/main/{domain}/`
                .replace(`{${"domain"}}`, encodeURIComponent(String(domain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forwardedDomain !== undefined) {
                localVarQueryParameter['forwarded_domain'] = forwardedDomain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Robots Template
         * @param {string | null} domain 
         * @param {string | null} [forwardedDomain] 
         * @param {boolean | null} [isCdn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotsTemplate: async (domain: string | null, forwardedDomain?: string | null, isCdn?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('robotsTemplate', 'domain', domain)
            const localVarPath = `/sitemap/robots/{domain}`
                .replace(`{${"domain"}}`, encodeURIComponent(String(domain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forwardedDomain !== undefined) {
                localVarQueryParameter['forwarded_domain'] = forwardedDomain;
            }

            if (isCdn !== undefined) {
                localVarQueryParameter['is_cdn'] = isCdn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sitemap File
         * @param {string} domain 
         * @param {string} filename 
         * @param {string | null} [forwardedDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapFile: async (domain: string, filename: string, forwardedDomain?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('sitemapFile', 'domain', domain)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('sitemapFile', 'filename', filename)
            const localVarPath = `/sitemap/file/{domain}/{filename}`
                .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forwardedDomain !== undefined) {
                localVarQueryParameter['forwarded_domain'] = forwardedDomain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitemapApi - functional programming interface
 * @export
 */
export const SitemapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SitemapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Main Sitemap
         * @param {string} domain 
         * @param {string | null} [forwardedDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainSitemap(domain: string, forwardedDomain?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainSitemap(domain, forwardedDomain, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitemapApi.mainSitemap']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Robots Template
         * @param {string | null} domain 
         * @param {string | null} [forwardedDomain] 
         * @param {boolean | null} [isCdn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotsTemplate(domain: string | null, forwardedDomain?: string | null, isCdn?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotsTemplate(domain, forwardedDomain, isCdn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitemapApi.robotsTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sitemap File
         * @param {string} domain 
         * @param {string} filename 
         * @param {string | null} [forwardedDomain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sitemapFile(domain: string, filename: string, forwardedDomain?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sitemapFile(domain, filename, forwardedDomain, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitemapApi.sitemapFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SitemapApi - factory interface
 * @export
 */
export const SitemapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SitemapApiFp(configuration)
    return {
        /**
         * 
         * @summary Main Sitemap
         * @param {SitemapApiMainSitemapRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainSitemap(requestParameters: SitemapApiMainSitemapRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.mainSitemap(requestParameters.domain, requestParameters.forwardedDomain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Robots Template
         * @param {SitemapApiRobotsTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotsTemplate(requestParameters: SitemapApiRobotsTemplateRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.robotsTemplate(requestParameters.domain, requestParameters.forwardedDomain, requestParameters.isCdn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sitemap File
         * @param {SitemapApiSitemapFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitemapFile(requestParameters: SitemapApiSitemapFileRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sitemapFile(requestParameters.domain, requestParameters.filename, requestParameters.forwardedDomain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mainSitemap operation in SitemapApi.
 * @export
 * @interface SitemapApiMainSitemapRequest
 */
export interface SitemapApiMainSitemapRequest {
    /**
     * 
     * @type {string}
     * @memberof SitemapApiMainSitemap
     */
    readonly domain: string

    /**
     * 
     * @type {string}
     * @memberof SitemapApiMainSitemap
     */
    readonly forwardedDomain?: string | null
}

/**
 * Request parameters for robotsTemplate operation in SitemapApi.
 * @export
 * @interface SitemapApiRobotsTemplateRequest
 */
export interface SitemapApiRobotsTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof SitemapApiRobotsTemplate
     */
    readonly domain: string | null

    /**
     * 
     * @type {string}
     * @memberof SitemapApiRobotsTemplate
     */
    readonly forwardedDomain?: string | null

    /**
     * 
     * @type {boolean}
     * @memberof SitemapApiRobotsTemplate
     */
    readonly isCdn?: boolean | null
}

/**
 * Request parameters for sitemapFile operation in SitemapApi.
 * @export
 * @interface SitemapApiSitemapFileRequest
 */
export interface SitemapApiSitemapFileRequest {
    /**
     * 
     * @type {string}
     * @memberof SitemapApiSitemapFile
     */
    readonly domain: string

    /**
     * 
     * @type {string}
     * @memberof SitemapApiSitemapFile
     */
    readonly filename: string

    /**
     * 
     * @type {string}
     * @memberof SitemapApiSitemapFile
     */
    readonly forwardedDomain?: string | null
}

/**
 * SitemapApi - object-oriented interface
 * @export
 * @class SitemapApi
 * @extends {BaseAPI}
 */
export class SitemapApi extends BaseAPI {
    /**
     * 
     * @summary Main Sitemap
     * @param {SitemapApiMainSitemapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapApi
     */
    public mainSitemap(requestParameters: SitemapApiMainSitemapRequest, options?: RawAxiosRequestConfig) {
        return SitemapApiFp(this.configuration).mainSitemap(requestParameters.domain, requestParameters.forwardedDomain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Robots Template
     * @param {SitemapApiRobotsTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapApi
     */
    public robotsTemplate(requestParameters: SitemapApiRobotsTemplateRequest, options?: RawAxiosRequestConfig) {
        return SitemapApiFp(this.configuration).robotsTemplate(requestParameters.domain, requestParameters.forwardedDomain, requestParameters.isCdn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sitemap File
     * @param {SitemapApiSitemapFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapApi
     */
    public sitemapFile(requestParameters: SitemapApiSitemapFileRequest, options?: RawAxiosRequestConfig) {
        return SitemapApiFp(this.configuration).sitemapFile(requestParameters.domain, requestParameters.filename, requestParameters.forwardedDomain, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SuggestionsApi - axios parameter creator
 * @export
 */
export const SuggestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary опционально в fias_id задается ограничение по региону
         * @param {string} query Text search by name
         * @param {string | null} [fiasId] Filter by parent fias_id
         * @param {Array<number> | null} [providerIds] Filter by presence of providers
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [tooltipsAlways] Всегда выдавать тултипы, даже если родитель совпадает
         * @param {number | null} [siteId] 
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitySuggestions: async (query: string, fiasId?: string | null, providerIds?: Array<number> | null, limit?: number, offset?: number, tooltipsAlways?: boolean, siteId?: number | null, provodnoy?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getCitySuggestions', 'query', query)
            const localVarPath = `/suggestions/cities/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (fiasId !== undefined) {
                localVarQueryParameter['fias_id'] = fiasId;
            }

            if (providerIds) {
                localVarQueryParameter['provider_ids'] = providerIds;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (tooltipsAlways !== undefined) {
                localVarQueryParameter['tooltips_always'] = tooltipsAlways;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary в fias_id задается ограничение по улице
         * @param {string} query Text search by name
         * @param {string} fiasId 
         * @param {Array<number> | null} [providerIds] Filter by presence of providers
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [tooltipsAlways] Всегда выдавать тултипы, даже если родитель совпадает
         * @param {number | null} [siteId] 
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getHouseSuggestions: async (query: string, fiasId: string, providerIds?: Array<number> | null, limit?: number, offset?: number, tooltipsAlways?: boolean, siteId?: number | null, provodnoy?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getHouseSuggestions', 'query', query)
            // verify required parameter 'fiasId' is not null or undefined
            assertParamExists('getHouseSuggestions', 'fiasId', fiasId)
            const localVarPath = `/suggestions/houses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (fiasId !== undefined) {
                localVarQueryParameter['fias_id'] = fiasId;
            }

            if (providerIds) {
                localVarQueryParameter['provider_ids'] = providerIds;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (tooltipsAlways !== undefined) {
                localVarQueryParameter['tooltips_always'] = tooltipsAlways;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary подсказки для региона
         * @param {string} query Text search by name
         * @param {string | null} [fiasId] Filter by parent fias_id
         * @param {Array<number> | null} [providerIds] Filter by presence of providers
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [tooltipsAlways] Всегда выдавать тултипы, даже если родитель совпадает
         * @param {number | null} [siteId] 
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRegionSuggestions: async (query: string, fiasId?: string | null, providerIds?: Array<number> | null, limit?: number, offset?: number, tooltipsAlways?: boolean, siteId?: number | null, provodnoy?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getRegionSuggestions', 'query', query)
            const localVarPath = `/suggestions/regions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (fiasId !== undefined) {
                localVarQueryParameter['fias_id'] = fiasId;
            }

            if (providerIds) {
                localVarQueryParameter['provider_ids'] = providerIds;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (tooltipsAlways !== undefined) {
                localVarQueryParameter['tooltips_always'] = tooltipsAlways;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary в fias_id задается ограничение по городу
         * @param {string} query Text search by name
         * @param {string} fiasId 
         * @param {Array<number> | null} [providerIds] Filter by presence of providers
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [tooltipsAlways] Всегда выдавать тултипы, даже если родитель совпадает
         * @param {number | null} [siteId] 
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStreetSuggestions: async (query: string, fiasId: string, providerIds?: Array<number> | null, limit?: number, offset?: number, tooltipsAlways?: boolean, siteId?: number | null, provodnoy?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getStreetSuggestions', 'query', query)
            // verify required parameter 'fiasId' is not null or undefined
            assertParamExists('getStreetSuggestions', 'fiasId', fiasId)
            const localVarPath = `/suggestions/streets/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (fiasId !== undefined) {
                localVarQueryParameter['fias_id'] = fiasId;
            }

            if (providerIds) {
                localVarQueryParameter['provider_ids'] = providerIds;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (tooltipsAlways !== undefined) {
                localVarQueryParameter['tooltips_always'] = tooltipsAlways;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tries to suggest upwards by fias id, with optional region limitation for cities. For example if `street_fias_id` is set, then search `house`, if `city_fias_id` is set, then search `street`, if `region_fias_id` is set, then search `city` in this region, if `region_fias_id` is not set and `city` present, then search all cities, else search `region`
         * @summary Joined method for suggestions
         * @param {SuggestionsCascadeInput} suggestionsCascadeInput 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSuggestionsCascade: async (suggestionsCascadeInput: SuggestionsCascadeInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'suggestionsCascadeInput' is not null or undefined
            assertParamExists('getSuggestionsCascade', 'suggestionsCascadeInput', suggestionsCascadeInput)
            const localVarPath = `/suggestions/cascade/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suggestionsCascadeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Also gets suggestions for other relevant cities if not enough is found for `site_city_fias_id`
         * @summary Method for single input suggestions
         * @param {string} query 
         * @param {string | null} [siteCityFiasId] Selected city on site (approximate city)(if streets are not enough in result, then searches for other relevant cities)
         * @param {string | null} [cityFiasId] Selected city in input (city that we searching for sure)
         * @param {string | null} [streetFiasId] Selected street in input. Houses are given if specified.
         * @param {boolean | null} [forceHouses] Force houses search in dadata
         * @param {number} [limit] 
         * @param {number | null} [offset] Offset into search by our DB. May not be applied when fetching from other sources
         * @param {boolean} [tooltipsAlways] Always collect parent tooltips
         * @param {boolean} [moveHouseStreetTooltip] Move tooltips of streets into house number from regular tooltip
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestionsMulti: async (query: string, siteCityFiasId?: string | null, cityFiasId?: string | null, streetFiasId?: string | null, forceHouses?: boolean | null, limit?: number, offset?: number | null, tooltipsAlways?: boolean, moveHouseStreetTooltip?: boolean, provodnoy?: boolean | null, siteId?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getSuggestionsMulti', 'query', query)
            const localVarPath = `/suggestions/multi/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (siteCityFiasId !== undefined) {
                localVarQueryParameter['site_city_fias_id'] = siteCityFiasId;
            }

            if (cityFiasId !== undefined) {
                localVarQueryParameter['city_fias_id'] = cityFiasId;
            }

            if (streetFiasId !== undefined) {
                localVarQueryParameter['street_fias_id'] = streetFiasId;
            }

            if (forceHouses !== undefined) {
                localVarQueryParameter['force_houses'] = forceHouses;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (tooltipsAlways !== undefined) {
                localVarQueryParameter['tooltips_always'] = tooltipsAlways;
            }

            if (moveHouseStreetTooltip !== undefined) {
                localVarQueryParameter['move_house_street_tooltip'] = moveHouseStreetTooltip;
            }

            if (provodnoy !== undefined) {
                localVarQueryParameter['provodnoy'] = provodnoy;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuggestionsApi - functional programming interface
 * @export
 */
export const SuggestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuggestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary опционально в fias_id задается ограничение по региону
         * @param {string} query Text search by name
         * @param {string | null} [fiasId] Filter by parent fias_id
         * @param {Array<number> | null} [providerIds] Filter by presence of providers
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [tooltipsAlways] Всегда выдавать тултипы, даже если родитель совпадает
         * @param {number | null} [siteId] 
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitySuggestions(query: string, fiasId?: string | null, providerIds?: Array<number> | null, limit?: number, offset?: number, tooltipsAlways?: boolean, siteId?: number | null, provodnoy?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressObjectWithParents>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitySuggestions(query, fiasId, providerIds, limit, offset, tooltipsAlways, siteId, provodnoy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuggestionsApi.getCitySuggestions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary в fias_id задается ограничение по улице
         * @param {string} query Text search by name
         * @param {string} fiasId 
         * @param {Array<number> | null} [providerIds] Filter by presence of providers
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [tooltipsAlways] Всегда выдавать тултипы, даже если родитель совпадает
         * @param {number | null} [siteId] 
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getHouseSuggestions(query: string, fiasId: string, providerIds?: Array<number> | null, limit?: number, offset?: number, tooltipsAlways?: boolean, siteId?: number | null, provodnoy?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<House>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHouseSuggestions(query, fiasId, providerIds, limit, offset, tooltipsAlways, siteId, provodnoy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuggestionsApi.getHouseSuggestions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary подсказки для региона
         * @param {string} query Text search by name
         * @param {string | null} [fiasId] Filter by parent fias_id
         * @param {Array<number> | null} [providerIds] Filter by presence of providers
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [tooltipsAlways] Всегда выдавать тултипы, даже если родитель совпадает
         * @param {number | null} [siteId] 
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getRegionSuggestions(query: string, fiasId?: string | null, providerIds?: Array<number> | null, limit?: number, offset?: number, tooltipsAlways?: boolean, siteId?: number | null, provodnoy?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseAddressObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionSuggestions(query, fiasId, providerIds, limit, offset, tooltipsAlways, siteId, provodnoy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuggestionsApi.getRegionSuggestions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary в fias_id задается ограничение по городу
         * @param {string} query Text search by name
         * @param {string} fiasId 
         * @param {Array<number> | null} [providerIds] Filter by presence of providers
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [tooltipsAlways] Всегда выдавать тултипы, даже если родитель совпадает
         * @param {number | null} [siteId] 
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getStreetSuggestions(query: string, fiasId: string, providerIds?: Array<number> | null, limit?: number, offset?: number, tooltipsAlways?: boolean, siteId?: number | null, provodnoy?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressObjectWithParents>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStreetSuggestions(query, fiasId, providerIds, limit, offset, tooltipsAlways, siteId, provodnoy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuggestionsApi.getStreetSuggestions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Tries to suggest upwards by fias id, with optional region limitation for cities. For example if `street_fias_id` is set, then search `house`, if `city_fias_id` is set, then search `street`, if `region_fias_id` is set, then search `city` in this region, if `region_fias_id` is not set and `city` present, then search all cities, else search `region`
         * @summary Joined method for suggestions
         * @param {SuggestionsCascadeInput} suggestionsCascadeInput 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getSuggestionsCascade(suggestionsCascadeInput: SuggestionsCascadeInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestionsCascade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuggestionsCascade(suggestionsCascadeInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuggestionsApi.getSuggestionsCascade']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Also gets suggestions for other relevant cities if not enough is found for `site_city_fias_id`
         * @summary Method for single input suggestions
         * @param {string} query 
         * @param {string | null} [siteCityFiasId] Selected city on site (approximate city)(if streets are not enough in result, then searches for other relevant cities)
         * @param {string | null} [cityFiasId] Selected city in input (city that we searching for sure)
         * @param {string | null} [streetFiasId] Selected street in input. Houses are given if specified.
         * @param {boolean | null} [forceHouses] Force houses search in dadata
         * @param {number} [limit] 
         * @param {number | null} [offset] Offset into search by our DB. May not be applied when fetching from other sources
         * @param {boolean} [tooltipsAlways] Always collect parent tooltips
         * @param {boolean} [moveHouseStreetTooltip] Move tooltips of streets into house number from regular tooltip
         * @param {boolean | null} [provodnoy] Use site_id
         * @param {number | null} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuggestionsMulti(query: string, siteCityFiasId?: string | null, cityFiasId?: string | null, streetFiasId?: string | null, forceHouses?: boolean | null, limit?: number, offset?: number | null, tooltipsAlways?: boolean, moveHouseStreetTooltip?: boolean, provodnoy?: boolean | null, siteId?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestionsMulti>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuggestionsMulti(query, siteCityFiasId, cityFiasId, streetFiasId, forceHouses, limit, offset, tooltipsAlways, moveHouseStreetTooltip, provodnoy, siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuggestionsApi.getSuggestionsMulti']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SuggestionsApi - factory interface
 * @export
 */
export const SuggestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuggestionsApiFp(configuration)
    return {
        /**
         * 
         * @summary опционально в fias_id задается ограничение по региону
         * @param {SuggestionsApiGetCitySuggestionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitySuggestions(requestParameters: SuggestionsApiGetCitySuggestionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AddressObjectWithParents>> {
            return localVarFp.getCitySuggestions(requestParameters.query, requestParameters.fiasId, requestParameters.providerIds, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary в fias_id задается ограничение по улице
         * @param {SuggestionsApiGetHouseSuggestionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getHouseSuggestions(requestParameters: SuggestionsApiGetHouseSuggestionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<House>> {
            return localVarFp.getHouseSuggestions(requestParameters.query, requestParameters.fiasId, requestParameters.providerIds, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary подсказки для региона
         * @param {SuggestionsApiGetRegionSuggestionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRegionSuggestions(requestParameters: SuggestionsApiGetRegionSuggestionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<BaseAddressObject>> {
            return localVarFp.getRegionSuggestions(requestParameters.query, requestParameters.fiasId, requestParameters.providerIds, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary в fias_id задается ограничение по городу
         * @param {SuggestionsApiGetStreetSuggestionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStreetSuggestions(requestParameters: SuggestionsApiGetStreetSuggestionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AddressObjectWithParents>> {
            return localVarFp.getStreetSuggestions(requestParameters.query, requestParameters.fiasId, requestParameters.providerIds, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(axios, basePath));
        },
        /**
         * Tries to suggest upwards by fias id, with optional region limitation for cities. For example if `street_fias_id` is set, then search `house`, if `city_fias_id` is set, then search `street`, if `region_fias_id` is set, then search `city` in this region, if `region_fias_id` is not set and `city` present, then search all cities, else search `region`
         * @summary Joined method for suggestions
         * @param {SuggestionsApiGetSuggestionsCascadeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSuggestionsCascade(requestParameters: SuggestionsApiGetSuggestionsCascadeRequest, options?: RawAxiosRequestConfig): AxiosPromise<SuggestionsCascade> {
            return localVarFp.getSuggestionsCascade(requestParameters.suggestionsCascadeInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Also gets suggestions for other relevant cities if not enough is found for `site_city_fias_id`
         * @summary Method for single input suggestions
         * @param {SuggestionsApiGetSuggestionsMultiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestionsMulti(requestParameters: SuggestionsApiGetSuggestionsMultiRequest, options?: RawAxiosRequestConfig): AxiosPromise<SuggestionsMulti> {
            return localVarFp.getSuggestionsMulti(requestParameters.query, requestParameters.siteCityFiasId, requestParameters.cityFiasId, requestParameters.streetFiasId, requestParameters.forceHouses, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.moveHouseStreetTooltip, requestParameters.provodnoy, requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCitySuggestions operation in SuggestionsApi.
 * @export
 * @interface SuggestionsApiGetCitySuggestionsRequest
 */
export interface SuggestionsApiGetCitySuggestionsRequest {
    /**
     * Text search by name
     * @type {string}
     * @memberof SuggestionsApiGetCitySuggestions
     */
    readonly query: string

    /**
     * Filter by parent fias_id
     * @type {string}
     * @memberof SuggestionsApiGetCitySuggestions
     */
    readonly fiasId?: string | null

    /**
     * Filter by presence of providers
     * @type {Array<number>}
     * @memberof SuggestionsApiGetCitySuggestions
     */
    readonly providerIds?: Array<number> | null

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetCitySuggestions
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetCitySuggestions
     */
    readonly offset?: number

    /**
     * Всегда выдавать тултипы, даже если родитель совпадает
     * @type {boolean}
     * @memberof SuggestionsApiGetCitySuggestions
     */
    readonly tooltipsAlways?: boolean

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetCitySuggestions
     */
    readonly siteId?: number | null

    /**
     * Use site_id
     * @type {boolean}
     * @memberof SuggestionsApiGetCitySuggestions
     */
    readonly provodnoy?: boolean | null
}

/**
 * Request parameters for getHouseSuggestions operation in SuggestionsApi.
 * @export
 * @interface SuggestionsApiGetHouseSuggestionsRequest
 */
export interface SuggestionsApiGetHouseSuggestionsRequest {
    /**
     * Text search by name
     * @type {string}
     * @memberof SuggestionsApiGetHouseSuggestions
     */
    readonly query: string

    /**
     * 
     * @type {string}
     * @memberof SuggestionsApiGetHouseSuggestions
     */
    readonly fiasId: string

    /**
     * Filter by presence of providers
     * @type {Array<number>}
     * @memberof SuggestionsApiGetHouseSuggestions
     */
    readonly providerIds?: Array<number> | null

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetHouseSuggestions
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetHouseSuggestions
     */
    readonly offset?: number

    /**
     * Всегда выдавать тултипы, даже если родитель совпадает
     * @type {boolean}
     * @memberof SuggestionsApiGetHouseSuggestions
     */
    readonly tooltipsAlways?: boolean

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetHouseSuggestions
     */
    readonly siteId?: number | null

    /**
     * Use site_id
     * @type {boolean}
     * @memberof SuggestionsApiGetHouseSuggestions
     */
    readonly provodnoy?: boolean | null
}

/**
 * Request parameters for getRegionSuggestions operation in SuggestionsApi.
 * @export
 * @interface SuggestionsApiGetRegionSuggestionsRequest
 */
export interface SuggestionsApiGetRegionSuggestionsRequest {
    /**
     * Text search by name
     * @type {string}
     * @memberof SuggestionsApiGetRegionSuggestions
     */
    readonly query: string

    /**
     * Filter by parent fias_id
     * @type {string}
     * @memberof SuggestionsApiGetRegionSuggestions
     */
    readonly fiasId?: string | null

    /**
     * Filter by presence of providers
     * @type {Array<number>}
     * @memberof SuggestionsApiGetRegionSuggestions
     */
    readonly providerIds?: Array<number> | null

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetRegionSuggestions
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetRegionSuggestions
     */
    readonly offset?: number

    /**
     * Всегда выдавать тултипы, даже если родитель совпадает
     * @type {boolean}
     * @memberof SuggestionsApiGetRegionSuggestions
     */
    readonly tooltipsAlways?: boolean

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetRegionSuggestions
     */
    readonly siteId?: number | null

    /**
     * Use site_id
     * @type {boolean}
     * @memberof SuggestionsApiGetRegionSuggestions
     */
    readonly provodnoy?: boolean | null
}

/**
 * Request parameters for getStreetSuggestions operation in SuggestionsApi.
 * @export
 * @interface SuggestionsApiGetStreetSuggestionsRequest
 */
export interface SuggestionsApiGetStreetSuggestionsRequest {
    /**
     * Text search by name
     * @type {string}
     * @memberof SuggestionsApiGetStreetSuggestions
     */
    readonly query: string

    /**
     * 
     * @type {string}
     * @memberof SuggestionsApiGetStreetSuggestions
     */
    readonly fiasId: string

    /**
     * Filter by presence of providers
     * @type {Array<number>}
     * @memberof SuggestionsApiGetStreetSuggestions
     */
    readonly providerIds?: Array<number> | null

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetStreetSuggestions
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetStreetSuggestions
     */
    readonly offset?: number

    /**
     * Всегда выдавать тултипы, даже если родитель совпадает
     * @type {boolean}
     * @memberof SuggestionsApiGetStreetSuggestions
     */
    readonly tooltipsAlways?: boolean

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetStreetSuggestions
     */
    readonly siteId?: number | null

    /**
     * Use site_id
     * @type {boolean}
     * @memberof SuggestionsApiGetStreetSuggestions
     */
    readonly provodnoy?: boolean | null
}

/**
 * Request parameters for getSuggestionsCascade operation in SuggestionsApi.
 * @export
 * @interface SuggestionsApiGetSuggestionsCascadeRequest
 */
export interface SuggestionsApiGetSuggestionsCascadeRequest {
    /**
     * 
     * @type {SuggestionsCascadeInput}
     * @memberof SuggestionsApiGetSuggestionsCascade
     */
    readonly suggestionsCascadeInput: SuggestionsCascadeInput
}

/**
 * Request parameters for getSuggestionsMulti operation in SuggestionsApi.
 * @export
 * @interface SuggestionsApiGetSuggestionsMultiRequest
 */
export interface SuggestionsApiGetSuggestionsMultiRequest {
    /**
     * 
     * @type {string}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly query: string

    /**
     * Selected city on site (approximate city)(if streets are not enough in result, then searches for other relevant cities)
     * @type {string}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly siteCityFiasId?: string | null

    /**
     * Selected city in input (city that we searching for sure)
     * @type {string}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly cityFiasId?: string | null

    /**
     * Selected street in input. Houses are given if specified.
     * @type {string}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly streetFiasId?: string | null

    /**
     * Force houses search in dadata
     * @type {boolean}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly forceHouses?: boolean | null

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly limit?: number

    /**
     * Offset into search by our DB. May not be applied when fetching from other sources
     * @type {number}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly offset?: number | null

    /**
     * Always collect parent tooltips
     * @type {boolean}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly tooltipsAlways?: boolean

    /**
     * Move tooltips of streets into house number from regular tooltip
     * @type {boolean}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly moveHouseStreetTooltip?: boolean

    /**
     * Use site_id
     * @type {boolean}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly provodnoy?: boolean | null

    /**
     * 
     * @type {number}
     * @memberof SuggestionsApiGetSuggestionsMulti
     */
    readonly siteId?: number | null
}

/**
 * SuggestionsApi - object-oriented interface
 * @export
 * @class SuggestionsApi
 * @extends {BaseAPI}
 */
export class SuggestionsApi extends BaseAPI {
    /**
     * 
     * @summary опционально в fias_id задается ограничение по региону
     * @param {SuggestionsApiGetCitySuggestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public getCitySuggestions(requestParameters: SuggestionsApiGetCitySuggestionsRequest, options?: RawAxiosRequestConfig) {
        return SuggestionsApiFp(this.configuration).getCitySuggestions(requestParameters.query, requestParameters.fiasId, requestParameters.providerIds, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary в fias_id задается ограничение по улице
     * @param {SuggestionsApiGetHouseSuggestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public getHouseSuggestions(requestParameters: SuggestionsApiGetHouseSuggestionsRequest, options?: RawAxiosRequestConfig) {
        return SuggestionsApiFp(this.configuration).getHouseSuggestions(requestParameters.query, requestParameters.fiasId, requestParameters.providerIds, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary подсказки для региона
     * @param {SuggestionsApiGetRegionSuggestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public getRegionSuggestions(requestParameters: SuggestionsApiGetRegionSuggestionsRequest, options?: RawAxiosRequestConfig) {
        return SuggestionsApiFp(this.configuration).getRegionSuggestions(requestParameters.query, requestParameters.fiasId, requestParameters.providerIds, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary в fias_id задается ограничение по городу
     * @param {SuggestionsApiGetStreetSuggestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public getStreetSuggestions(requestParameters: SuggestionsApiGetStreetSuggestionsRequest, options?: RawAxiosRequestConfig) {
        return SuggestionsApiFp(this.configuration).getStreetSuggestions(requestParameters.query, requestParameters.fiasId, requestParameters.providerIds, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.siteId, requestParameters.provodnoy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tries to suggest upwards by fias id, with optional region limitation for cities. For example if `street_fias_id` is set, then search `house`, if `city_fias_id` is set, then search `street`, if `region_fias_id` is set, then search `city` in this region, if `region_fias_id` is not set and `city` present, then search all cities, else search `region`
     * @summary Joined method for suggestions
     * @param {SuggestionsApiGetSuggestionsCascadeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public getSuggestionsCascade(requestParameters: SuggestionsApiGetSuggestionsCascadeRequest, options?: RawAxiosRequestConfig) {
        return SuggestionsApiFp(this.configuration).getSuggestionsCascade(requestParameters.suggestionsCascadeInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Also gets suggestions for other relevant cities if not enough is found for `site_city_fias_id`
     * @summary Method for single input suggestions
     * @param {SuggestionsApiGetSuggestionsMultiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public getSuggestionsMulti(requestParameters: SuggestionsApiGetSuggestionsMultiRequest, options?: RawAxiosRequestConfig) {
        return SuggestionsApiFp(this.configuration).getSuggestionsMulti(requestParameters.query, requestParameters.siteCityFiasId, requestParameters.cityFiasId, requestParameters.streetFiasId, requestParameters.forceHouses, requestParameters.limit, requestParameters.offset, requestParameters.tooltipsAlways, requestParameters.moveHouseStreetTooltip, requestParameters.provodnoy, requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }
}



